export class EventSubmission {
    public constructor(init?: Partial<EventSubmission>) {
        Object.assign(this, init);
    }
    id: string = '';
    eventId:string=null;
    nome:string=null;
    cognome:string=null;
    email:string=null;
    company:string=null;
}