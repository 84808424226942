<div class="modal-header">
    <h4 class="modal-title" id="modal-title">{{title}}</h4>
    <button type="button" class="buttonInnoHub_inverse" aria-label="Close button"
        aria-describedby="modal-title" (click)="modal.dismiss('Cross click')">
        <span aria-hidden="true">&times;</span>
    </button>
</div>
<div class="modal-body" [innerHtml]="message"></div>
<div class="modal-footer">
    <button type="button" ngbAutofocus class="buttonInnoHub_inverse"
        (click)="modal.dismiss('Cancel click')" [translate]="'modal.actions.cancel'"></button>
    <button type="button" class="buttonInnoHub" (click)="modal.close('Ok click')"
        [translate]="'modal.actions.confirm'"></button>
</div>