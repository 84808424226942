// *** class name is PitchService
// *** entity name is Pitch
// *** TODO: please check that everything is correct

import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { EventsService } from '../global/events.service';

@Injectable({
    providedIn: 'root'
})
export class PitchService {

    constructor(
        private httpClient: HttpClient,
        private eventService: EventsService
    ) {

    }

    pitchUploadAction(uploadedImage: File): Observable<any> {
        const privacyFormData = new FormData();
        privacyFormData.append('pitch', uploadedImage, uploadedImage?.name);


        return this.httpClient.post(environment.backendURL + 'api/pitch/', privacyFormData, { observe: 'response' })
            .pipe(map(res => {
                return res.body;
            }));
    }



    public download(privacyid: string): Observable<any> {
        let params: HttpParams = new HttpParams();
        return this.httpClient.get(environment.backendURL + 'api/pitch/' + privacyid + '/download', { params, responseType: 'blob' });

    }

}