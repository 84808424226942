<nav *ngIf="!isHandset" class="navbar navbar-expand-lg navbar-light mb-1 px-3">
  <div style="cursor: pointer;" [routerLink]="['/']" class="desfa-logo-grid md"></div>
  <span class="divider ms-3"></span>
  <button class="navbar-toggler hidden-sm-up" type="button" (click)="isNavbarCollapsed = !isNavbarCollapsed"
    data-target="#navbarsDefault" aria-controls="navbarsDefault" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>
  <div [ngbCollapse]="isNavbarCollapsed" class="collapse navbar-collapse" id="navbarsDefault">
    <span class="ms-auto">
    </span>
    <ul class="navbar-nav" *ngIf="!authService.isLogged()">
      <a [ngClass]="isHandset? 'menu-option-mobile' : 'login-option pointer'" (click)="login()">
        <span *ngIf="isHandset" class="appkiticon a-btn-icon icon-login-outline"></span> Log in</a>
      <a (click)="register()" [ngClass]="isHandset? 'menu-option-mobile ' : 'btn_contact'">
        <span *ngIf="isHandset" class="appkiticon a-btn-icon icon-login-outline"></span> Sign up</a>
      <a (click)="publiccontact()" [ngClass]="isHandset? 'menu-option-mobile' : 'btn_contact'">
        <span *ngIf="isHandset" class="appkiticon a-btn-icon icon-help-question-outline"></span> Contact Us</a>
    </ul>
    <ul class="navbar-nav" *ngIf="authService.isLogged()" style="font-size:14px">
      <a (click)="mysubmission()" [ngClass]="isHandset? 'menu-option-mobile' : 'menu-option right-border'">
        <span *ngIf="isHandset" class="appkiticon a-btn-icon icon-survey-outline"></span> My Submissions</a>
      <a (click)="logout()" [ngClass]="isHandset? 'menu-option-mobile' : 'menu-option'">
        <span *ngIf="isHandset" class="appkiticon a-btn-icon icon-log-out-outline"></span> Log out</a>
      <a (click)="securecontact()" [ngClass]="isHandset? 'menu-option-mobile' : 'btn_contact'">
        <span *ngIf="isHandset" class="appkiticon a-btn-icon icon-help-question-outline"></span> Contact Us</a>
      <a [routerLink]="['../profile/my-profile']" *ngIf="isHandset && authService.isLogged()"
        class="menu-option-mobile">
        <span class="appkiticon a-btn-icon icon-person-outline"></span> Profile</a>
    </ul>


    <ul class="navbar-nav" *ngIf="!isHandset && authService.isLogged()">
      <li class="nav-item" ngbDropdown>
        <a class="nav-link"
          style="cursor:pointer; font-size: 14px;
                padding-left: 6px;
                padding-top: 3px;margin-left:5px;width:30px;height:30px;border-radius: 15px;background-color: #2BABE2;color:white"
          ngbDropdownToggle>
          {{authService.getAuthContext().user.username.substring(0,2).toUpperCase()}}
        </a>
        <div class="dropdown-menu dropdown-menu-right text-right" ngbDropdownMenu style="left:-125px">
          <a class="dropdown-item" [routerLink]="['../profile/my-profile']">
            <span class="appkiticon a-btn-icon icon-person-outline"></span> Profile</a>
        </div>
      </li>
    </ul>

  </div>
</nav>


<div *ngIf="eventDetail" [ngClass]="isHandset? 'image-class-mobile background-image':'image-class'">
  <img class="center-cropped-detail" src="{{'data:image/jpg;base64,' + eventDetail.image.imagebyte}}">
  <div style="color:white;margin-top:-220px;margin-left:20px;">
    <span type="button" (click)="goBack()" style="font-size: large;">
      <span class="appkiticon a-btn-icon icon-left-chevron-outline"></span>
      All the Initiatives</span><br><br>
    <div class="text-align">Event<br>
      <span style="font-size: xx-large; font-weight: bold;">{{eventDetail.titolo}} </span>
    </div>
  </div>
</div>
<div *ngIf="eventDetail" [ngClass]="isHandset? 'background-class-mobile':'background-class'">
  <div class="row">
    <div class="col-7" style="color:#FFFFFF">
      <span [ngStyle]="{'font-size' : isHandset ? '20px' : '10px'}">Date </span><br *ngIf="!isHandset">
      <span [ngStyle]="{'font-size' : isHandset ? '20px' : '30px'}">{{eventDetail.dataScadenza | date:'d MMMM'}}</span>
    </div>
  </div>
  <div class="row">
    <div class="col-7" style="color:#FFFFFF">
      <span [ngStyle]="{'font-size' : isHandset ? '15px' : '10px'}">Location</span><br>
      <span style="font-size: 15px;">{{eventDetail.location}}</span>
    </div>
  </div>
  <div class="row mt-4">
    <div class="col-7">
      <div (click)="downloadPrivacy(eventDetail.privacy)" [ngStyle]="{'font-size' : isHandset ? '15px' : '10px'}"
        style="color:#FFFFFF;text-decoration: underline;cursor:pointer">Privacy Policy
      </div>
    </div>
  </div>
</div>

<div *ngIf="eventDetail" class="row" style="padding-top:40px;margin-bottom: 40px;"
  [ngStyle]="!isHandset ? {} : { 'padding-left' : '20px'}">
  <div *ngIf="!isHandset" class="col-1">
  </div>
  <div *ngIf="!isHandset" class="col-1">
    <img src="../../../../assets/images/path248.png">
  </div>
  <div *ngIf="!isHandset" class="col-1">

  </div>
  <div [ngClass]="isHandset? 'col-12':'col-5'">
    <span style="color:#20376C;font-weight: bold;">Description</span>
    <br *ngIf="!isHandset"><br>
    {{eventDetail.breveDescrizione}}
  </div>
  <div class="col-3">

  </div>
  <div class="col-1">

  </div>
</div>


<div id="submission" *ngIf="eventDetail" style="background-color: #E8E8E8;width:100%;padding-bottom: 60px;">
  <div class="row" [ngStyle]="!isHandset ? {'padding-top' : '100px'} : { 'padding-top' : '30px' }">
    <div class="col-1"></div>
    <div class="col-10">
      <span style="font-size: 12px;color:#20376C" class="primary">Attendance</span>
    </div>
    <div class="col-1"></div>
  </div>
  <div class="row">
    <div class="col-1"></div>
    <div class="col-10">
      <span style="font-size: 25px;color:#20376C" class="primary">{{eventDetail.titolo}}</span>
    </div>
    <div class="col-1"></div>
  </div>

  <div class="row" [ngStyle]="!isHandset ? { 'padding-top' : '60px' } : { 'padding-top' : '30px' }">
    <div class="col-1"></div>
    <div class="col-10">


      <form [formGroup]="resourceForm" (ngSubmit)="onSubmit()">

        <div class="row mb-2">
          <!-- *** TODO: Insert fields here *** -->
          <!-- *** FIELD titolo STARTS *** -->
          <div class="col-lg-3 col-md-3 col-sm-12 mb-2">
            <!-- *** TODO: For required fields, please add .required class to the label *** -->
            <div class="tondogrigio" style="position:absolute">1</div>
            <label style="margin-left:25px" id="nome_label" for="nome" class="a-form-label text-uppercase required"
              [ngClass]="{ 'mb-0' : crudMode == crudModeType.READ }">
              Name</label>
            <input required id="nome" type="text" formControlName="nome" class="a-text-input" autocomplete="off"
              placeholder="{{ crudMode == crudModeType.READ ? '' : ('general.inserttext' | translate )}}" [ngClass]="{ 'a-input-error': (f.nome.dirty || f.nome.touched) && f.nome.errors && crudMode != crudModeType.READ,
         'form-control-plaintext pl-0 pt-0 no-box-shadow' : crudMode == crudModeType.READ }"
              [readonly]="crudMode == crudModeType.READ" />
            <div *ngIf="(f.nome.dirty || f.nome.touched) && f.nome.errors && crudMode != crudModeType.READ"
              class="error-message">
              <!-- *** TODO: Add or remove validators if needed *** -->
              <div *ngIf="f.nome.errors.required" translate>Obligatory field
              </div>
            </div>
          </div>

          <div class="col-lg-3 col-md-3 col-sm-12 mb-2">
            <!-- *** TODO: For required fields, please add .required class to the label *** -->
            <div class="tondogrigio" style="position:absolute">2</div>
            <label style="margin-left:25px" id="cognome_label" for="cognome"
              class="a-form-label text-uppercase required"
              [ngClass]="{ 'mb-0' : crudMode == crudModeType.READ }">Surname</label>
            <input required id="cognome" type="text" formControlName="cognome" class="a-text-input" autocomplete="off"
              placeholder="{{ crudMode == crudModeType.READ ? '' : ('general.inserttext' | translate )}}" [ngClass]="{ 'a-input-error': (f.cognome.dirty || f.cognome.touched) && f.cognome.errors && crudMode != crudModeType.READ,
         'form-control-plaintext pl-0 pt-0 no-box-shadow' : crudMode == crudModeType.READ }"
              [readonly]="crudMode == crudModeType.READ" />
            <div *ngIf="(f.cognome.dirty || f.cognome.touched) && f.cognome.errors && crudMode != crudModeType.READ"
              class="error-message">
              <!-- *** TODO: Add or remove validators if needed *** -->
              <div *ngIf="f.cognome.errors.required" translate>Obligatory field
              </div>
            </div>
          </div>




          <div class="col-lg-3 col-md-3 col-sm-12 mb-2">
            <!-- *** TODO: For required fields, please add .required class to the label *** -->
            <div class="tondogrigio" style="position:absolute">3</div>
            <label style="margin-left:25px" id="email_label" for="email" class="a-form-label text-uppercase required"
              [ngClass]="{ 'mb-0' : crudMode == crudModeType.READ }">Email</label>
            <input required id="email" type="email" formControlName="email" class="a-text-input" autocomplete="off"
              placeholder="{{ crudMode == crudModeType.READ ? '' : ('general.inserttext' | translate )}}" [ngClass]="{ 'a-input-error': (f.email.dirty || f.email.touched) && f.email.errors && crudMode != crudModeType.READ,
         'form-control-plaintext pl-0 pt-0 no-box-shadow' : crudMode == crudModeType.READ }"
              [readonly]="crudMode == crudModeType.READ" />
            <div *ngIf="(f.email.dirty || f.email.touched) && f.email.errors && crudMode != crudModeType.READ"
              class="error-message">
              <!-- *** TODO: Add or remove validators if needed *** -->
              <div *ngIf="f.email.errors.required" translate>Obligatory field
              </div>
            </div>
          </div>

          <div class="col-lg-3 col-md-3 col-sm-12 mb-2">
            <!-- *** TODO: For required fields, please add .required class to the label *** -->
            <div class="tondogrigio" style="position:absolute">4</div>
            <label style="margin-left:25px" id="company_label" for="company"
              class="a-form-label text-uppercase required"
              [ngClass]="{ 'mb-0' : crudMode == crudModeType.READ }">Company</label>
            <input required id="company" type="text" formControlName="company" class="a-text-input" autocomplete="off"
              placeholder="{{ crudMode == crudModeType.READ ? '' : ('general.inserttext' | translate )}}" [ngClass]="{ 'a-input-error': (f.company.dirty || f.company.touched) && f.company.errors && crudMode != crudModeType.READ,
         'form-control-plaintext pl-0 pt-0 no-box-shadow' : crudMode == crudModeType.READ }"
              [readonly]="crudMode == crudModeType.READ" />
            <div *ngIf="(f.company.dirty || f.company.touched) && f.company.errors && crudMode != crudModeType.READ"
              class="error-message">
              <!-- *** TODO: Add or remove validators if needed *** -->
              <div *ngIf="f.company.errors.required" translate>Obligatory field
              </div>
            </div>
          </div>
        </div>
        <div *ngIf="f.email.errors?.email" class="error-message">Email address is not valid.</div>
        <div class="row mb-2 mt-3">
          <div class="col-12">
            By clicking submit you accept the EU regulation 2016/679
            (articles from 15 to 23) gives the concerned the exercise of specific rights. In particular, in
            relation to the treatment of your personal data, you have the right to ask, access,
            rectification, limitation, opposition and portability;
            also can propose compliant, against the Supervisory Authority, which in Italy is the Guarantor
            for the protection of Personal Data.
          </div>
        </div>
        <div class="row" style="margin-left:-10px;margin-top:10px">
          <div class="col-md-12">
            <input type="radio" [(ngModel)]="disclaimer" name="disclaimerradio" value="si"
              [ngModelOptions]="{standalone: true}"> <b> YES</b>
            <input type="radio" [(ngModel)]="disclaimer" name="disclaimerradio" value="no" style="margin-left: 20px;"
              [ngModelOptions]="{standalone: true}"><b>
              NO</b>
          </div>
        </div>


        <div class="row mb-2 mt-5">

          <div class="col-6">
            <button type="button" ngbAutofocus style="background-color: transparent;color:#203570;" (click)="cancel()"
              [translate]="'Cancel'"></button>
          </div>

          <div class="col-6" style="text-align: right;">
            <button class="buttonInnoHub" type="submit"
              [disabled]="!resourceForm.valid || !resourceForm.dirty || disclaimer!='si'" translate>Partecipate</button>
          </div>
        </div>
      </form>

    </div>
    <div class="col-1"></div>
  </div>
</div>
