

import { Component} from '@angular/core';


@Component({
  selector: 'app-home',
  templateUrl: './eventdetail.component.html',
  styleUrls: ['./eventdetail.component.scss']
})
export class PublicEventDetailComponent {


}
