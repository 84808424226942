import { ActivatedRoute, Router, Resolve, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import { ProfileService } from '../services/crud/profile.service';
import { Profile } from '../models/profile';
import { Injectable } from '@angular/core';
import { NVPathVariableResourceResolver } from '@nv/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, of, from } from 'rxjs';
import { mergeMap } from 'rxjs/operators';
@Injectable({ providedIn: 'root' })
export class ProfileResolver extends NVPathVariableResourceResolver<Profile> {

    constructor(
        private profileService: ProfileService,
        private router: Router,
        private route: ActivatedRoute
    ) {
        super(
            profileService,
            router,
            route
        );
    }

}

@Injectable({ providedIn: 'root' })
export class ProfileBreadcrumbResolver implements Resolve<string> {
    constructor(protected profileResolver: ProfileResolver, protected translateService: TranslateService) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<string> {
        const solvedMessage = this.profileResolver.resolve(route) as unknown as Observable<Profile>;
        const label = route.data.breadcrumbLabel;

        return solvedMessage.pipe(
            mergeMap(
                value => {
                    return of(this.translateService.instant(label, { profile: value }));
                })
        );
    }
}
