
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, HostListener, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { mainConfigs } from 'src/app/configs/main-configs';
import { AuthService } from 'src/app/services/auth.service';
import { NVAuthService } from '@nv/auth';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { InternationalizationService } from '@nv/internationalization';
import { CRUDMode, NVFormController, NVListController, NVNotificationService, NVPreferencesService, NVUtilsService } from '@nv/core';
import { Call } from 'src/app/models/call';
import { CallService } from 'src/app/services/crud/call.service';
import { DatePipe, Location, ViewportScroller } from '@angular/common';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalComponent } from 'src/app/components/modal/modal.component';
import { Observable } from 'rxjs';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { PitchService } from 'src/app/services/crud/pitch.service';
import { EventSubmission } from 'src/app/models/eventSubmission';
import { EventSubmissionService } from 'src/app/services/crud/eventSubmission.service';
import { Evento } from 'src/app/models/evento';
import { EventService } from 'src/app/services/crud/evento.service';
import { PrivacyService } from 'src/app/services/crud/privacy.service';


@Component({
  selector: 'app-eventdetailcomponent',
  templateUrl: './event-detail.component.html',
  styleUrls: ['./event-detail.component.scss']
})
export class EventDetailComponent extends NVFormController<EventSubmission> implements OnInit {

  isHandsetLandscape: boolean;
  isHandset: boolean;
  public formSubmitted: boolean = false;
  redirectInProgress = false;
  customRedirect: string = undefined;


  public isNavbarCollapsed = true;
  public numeroFasi = 0;
  public disclaimer: String;

  eventDetail: Evento;
  public eventid;


  constructor(
    private crudService: EventSubmissionService,
    private eventService: EventService,
    private privacyService:PrivacyService,
    private router: Router,
    public breakpointObserver: BreakpointObserver,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private internationalizationService: InternationalizationService,
    private callService: CallService,
    private fb: FormBuilder,
    private notificationService: NVNotificationService,
    private datePipe: DatePipe,
    public authService: AuthService,
    private ngbModalService: NgbModal,
    private ngxloader: NgxUiLoaderService,
    private pitchService: PitchService,
    private location: Location,
  ) {

    super(
      route,
      EventSubmission,
      'id',
      crudService,
      fb,
      notificationService,
      router,
      translate,
      datePipe,
      null,
      true
    );

    breakpointObserver.observe([
      Breakpoints.Handset,
    ]).subscribe(result => {
      this.isHandset = result.matches;
    });
  }

  ngOnInit() {

    this.ngxloader.start();
    this.eventid = this.route.snapshot.paramMap.get('eventid');
    this.eventService.getEventDetailFromPublic(this.eventid).subscribe(data => {
      this.eventDetail = data;
      this.ngxloader.stop();
    })

    this.resourceForm = this.fb.group({
      nome: ['', Validators.required],
      cognome: ['', Validators.required],
      email: ['', Validators.email],
      company: ['', Validators.required],
    });
  }

  cancel()
  {
    this.resourceForm.patchValue({
      nome: null,
      cognome: null,
      email:null,
      company:null
    });
  }

  availableLanguages(): string[] {
    return this.translate.getLangs();
  }

  changeLanguage(language: string) {
    this.internationalizationService.changeLanguage(language);
    window.location.reload();
  }

  currentLanguage(): string {
    return this.translate.currentLang;
  }

  login() {
    this.router.navigate(['/login']);
  }

  logout(): void {
    const modalRef = this.ngbModalService.open(ModalComponent);
    modalRef.componentInstance.message = this.translate.instant('logout.modal.message');
    modalRef.componentInstance.title = this.translate.instant('logout.modal.title');
    modalRef.result.then(result => {
      if (environment.enableOpenAM) {
        this.authService.openAMLogout();
      } else {
        this.authService.logout().subscribe((response) => {
          this.router.navigateByUrl(mainConfigs.loginURL);
        });
      }
    }).catch(() => {

    });
  }


  onSubmit(onlyValidation: boolean = false, isModal: boolean = false) {
    this.ngxloader.start();
    const that = this;
    // stop here if form is invalid
    if (!onlyValidation && (that.resourceForm.invalid || that.crudMode === CRUDMode.READ)) {
      return;
    }


    this.formSubmitted = true;
    let formDataEntity = new EventSubmission(that.resourceForm.getRawValue());



    // if the form based entity is different than the entity, save the entity
    if (!NVUtilsService.compareValues(that.datePipe, that.getResource(), formDataEntity)) {
      if (that.crudMode === CRUDMode.CREATE) {


        formDataEntity.eventId = this.eventid;


        // it is a new entity, create it
        that.crudService.create(that.pathVariables, formDataEntity, onlyValidation)
          .subscribe(id => {

            this.ngxloader.stop();
            if (!onlyValidation) {

              //crea le erogazioni
              that.notificationService.showSuccess(
                that.translate.instant(
                  'resource.' + that.resourceNameLabel + '.modal.create.successMessage',
                  { resourceId: id }
                )
              );
              if (isModal) {
                this.modal.close(id);
              } else {
                this.router.navigate(['../'])
              }
            }
          },
            errorResponse => { this.ngxloader.stop(); that.validationError(errorResponse, onlyValidation); }
          );
      }
    }
  }
  onSubmitRedirect(crudMode: CRUDMode) {
    if (crudMode === CRUDMode.CREATE) {
      this.router.navigate(['../'], { relativeTo: this.route });
    } else if (crudMode === CRUDMode.UPDATE) {
      this.router.navigate(['../../'], { relativeTo: this.route });
    }
  }

  @HostListener('window:beforeunload', ['$event'])
  onWindowClose(event: any): void {
    const formDataEntity = new Call(this.resourceForm.getRawValue());
    if (!this.formSubmitted && !NVUtilsService.compareValues(this.datePipe, this.getResource(), formDataEntity)) {
      event.preventDefault();
      event.returnValue = false;
    }
  }

  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    return true;
  }

  downloadPrivacy(privacy:any) {
    console.log(privacy)

    this.ngxloader.start();

    this.privacyService.download(privacy.id).subscribe(res => {
        this.ngxloader.stop();
        let url = window.URL.createObjectURL(res);
        let a = document.createElement("a");
        document.body.appendChild(a);
        a.setAttribute("style", "display: none");
        a.href = url;
        a.download = privacy.nome
        a.click();
        window.URL.revokeObjectURL(url);
        a.remove();
    },
    error=>
    {
      this.ngxloader.stop();
      this.notificationService.showError("Error downloading file! Contact the help desk")
    });
  }

  mysubmission()
  {
    this.router.navigate(['/secure/mysubmission']);
  }
  securecontact()
  {
    this.router.navigate(['/secure/contact']);
  }

  register() {
    this.router.navigate(['/register']);
  }
  publiccontact() {
    this.router.navigate(['/contact']);
  }

  goBack() {
    this.location.back();
  }
}
