<nav class="navbar navbar-expand-lg navbar-light mb-1 px-3">
    <div class="desfa-logo-grid"></div>
    <span class="divider mx-3"></span>
    <span class="divider ms-3"></span>
    <button class="navbar-toggler hidden-sm-up" type="button" (click)="isNavbarCollapsed = !isNavbarCollapsed"
        data-target="#navbarsDefault" aria-controls="navbarsDefault" aria-expanded="false"
        aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
    </button>
    <div [ngbCollapse]="isNavbarCollapsed" class="collapse navbar-collapse" id="navbarsDefault">
        <span class="ms-auto">
        </span>
    </div>
</nav>

<div class="row" style="margin-top:50px">
    <div class="col-1"></div>
    <div class="col-10">
        <span style="font-size: 60px;font-weight: 400;">Privacy Policy</span><br>
        <div class="col-1"></div>
    </div>

    <div class="row" style="margin-top:50px">
        <div class="col-1"></div>
        <div class="col-10">
            DESFA, in its capacity as Data Controller as per the EU Regulation 2016/679 (General
Data Protection Regulation, hereafter GDPR), shall process your personal data, in
particular categories of data referring to identification, to eligibility criteria, to selection
criteria, as these are detailed in the Acceleration Program Rules and Regulations, to
enable participation in the Program, and for purposes connected with legal obligations.
The above data will be processed according to the principles and rules of the GDPR and
the regulations related thereto. The data will be processed by the personnel necessary
for the management of the Program and, in that context, may be transferred to DESFA’s
vendors that support its organisation and execution, such as IT service providers,
consultants etc. DESFA shall retain and process the personal data only for the period
required to pursue the purposes described above and at most for two (2) years after the
end of the year of the closure of the call.<br>
Additionally, by submitting this application, you are providing your consent as data
subjects and your authorisation to DESFA, without any remuneration being due, to
further use your name, surname and any description of your project for purposes
relating to promotional campaigns, internal and external advertising relating to the
program by any means, including but not limited to DESFA's website, through press
releases and any other means of communication to the public, for an unlimited number
of reproductions and distribution worldwide.<br>
Consent to data processing is a prerequisite for participation in the program; in case of
absence of consent, you will not be able to participate in the call for entries to the
Acceleration Program. You always have the right to withdraw your consent at any time
by contacting us either in writing or by electronic means. Withdrawing your consent will
not affect the lawfulness of processing based on your consent prior to its withdrawal,
but will mean that you no longer wish to participate in the Acceleration Program,
therefore you will be eliminated.<br>
As data subjects, you may exercise your rights under Articles 15 and following of the
GDPR at any time. For more information regarding the processing of personal data as
well as to exercise such rights, you may contact our Data Protection Officer (DPO) via
the following email dpo@desfa.gr.
        </div>
    </div>
</div>