import { Component, OnInit } from '@angular/core';
import { ActionLinkType } from 'src/app/enums/action-link-type.enum';
import { ActionHeader } from 'src/app/interfaces/action';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'src/app/services/auth.service';
import { Company } from 'src/app/models/company';
import { NVFormController, NVNotificationService } from '@nv/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CompanyService } from 'src/app/services/crud/company.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { ProfileService } from 'src/app/services/crud/profile.service';
import { Profile } from 'src/app/models/profile';
import { mainConfigs } from 'src/app/configs/main-configs';
import { GoodStatusService } from 'src/app/services/crud/goodStatus.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalComponent } from '../modal/modal.component';
import { environment } from 'src/environments/environment';
import { Contact } from 'src/app/models/contact';
import { ContactService } from 'src/app/services/crud/contact.service';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {


  public contact: Contact = new Contact();
  submitted = false;
  resourceForm: FormGroup;
  public fullname: string;
  public email: string;
  public environment: string;
  public role: string;
  public message: string;
  public contactFile: File;
  public disclaimerPrivacy: string = null;




  isHandsetLandscape: boolean;
  isHandset: boolean;
  public isNavbarCollapsed = true;


  constructor(
    private route: ActivatedRoute,
    private contactService: ContactService,
    public authService: AuthService,
    private translateService: TranslateService,
    private fb: FormBuilder,
    private notificationService: NVNotificationService,
    private router: Router,
    private datePipe: DatePipe,
    private formBuilder: FormBuilder,
    private ngbModalService: NgbModal,
    private translate: TranslateService,
    private breakpointObserver: BreakpointObserver

  ) {
    breakpointObserver.observe([
      Breakpoints.Handset,
    ]).subscribe(result => {
      this.isHandset = result.matches;
    });
  }

  ngOnInit() {

    this.resourceForm = this.formBuilder.group({
      fullname: ['', Validators.required],
      email: ['', Validators.required],
      environment: ['', Validators.required],
      role: ['', Validators.required],
      message: ['', Validators.required],

    });

  }

  onSubmit() {
    this.contact.fullname = this.fullname;
    this.contact.email = this.email;
    this.contact.environment = this.environment;
    this.contact.role = this.role;
    this.contact.message = this.message;



    this.submitted = true;
    if (this.resourceForm.invalid) {
      return;
    }


    this.contactService.createAndUploadFile(null, this.contact, this.contactFile)
      .subscribe((response) => {
        this.notificationService.showSuccess("Contact successfully created!")
        history.back();
      });

    // // UPLOAD business
    // if (this.contactFile) {
    //   this.contactService.contactUploadAction(this.contactFile).subscribe(contactId => {
    //     this.contact.contactFileID = contactId;


    //     this.contactService.create(null,this.contact)
    //       .subscribe((response) => {
    //         this.notificationService.showSuccess("Contact successfully created!")
    //         history.back();
    //       });
    //   })
    // }
    // else
    // {
    //   this.contactService.create(null,this.contact)
    //   .subscribe((response) => {
    //     this.notificationService.showSuccess("Contact successfully created!")
    //     history.back();
    //   });
    // }
  }



  public onContactpload(event) {
    this.contactFile = event.target.files[0];
  }

  mysubmission() {
    this.router.navigate(['/secure/mysubmission']);
  }

  logout(): void {
    const modalRef = this.ngbModalService.open(ModalComponent);
    modalRef.componentInstance.message = this.translate.instant('logout.modal.message');
    modalRef.componentInstance.title = this.translate.instant('logout.modal.title');
    modalRef.result.then(result => {
      if (environment.enableOpenAM) {
        this.authService.openAMLogout();
      } else {
        this.authService.logout().subscribe((response) => {
          this.router.navigateByUrl(mainConfigs.loginURL);
        });
      }
    }).catch(() => {

    });
  }

  login() {
    this.router.navigate(['/login']);
  }

  register() {
    this.router.navigate(['/register']);
  }

}
