<div fxLayout="column" [fxLayoutAlign]="isHandsetLandscape ? 'center start' : 'center center'"
    style="background-color: #F3F3F3;">
    <div class="login-wrapper" fxFlex.lt-sm="90%" style="width: 400px;">
        <div class="login" id="resetPasswordRequest">
            <div class="row">
                <div class="col-12" style="text-align: left;">
                    <span class="desfa-logo-grid a-lg"></span>
                </div>
                <div class="application-name" [translate]="'resetPasswordConfirm.form.title'"></div>
                <div class="application-name" [translate]="'resetPasswordConfirm.form.subtitle'"></div>
                <div class="application-text" [translate]="'resetPasswordConfirm.form.subtitle1'"></div>
                <div class="application-text" [translate]="'resetPasswordConfirm.form.subtitle2'"></div>
            </div>
            <form [formGroup]="resetPasswordConfirmForm" (ngSubmit)="onSubmit()" class="form-signin">
                <div class="user-fields">
                    <div class="a-d-flex a-flex-column a-textarea-box">
                        <label class="a-form-label" for="newPassword" id="newPassword_label"
                            [translate]="'resetPasswordConfirm.form.newpassword.label'"></label>
                        <input type="password" formControlName="newPassword" id="newPassword" class="a-text-input"
                            placeholder="{{ 'resetPasswordConfirm.form.newpassword.placeholder' | translate }}"
                            [ngClass]="{ 'a-input-error': submitted && f.newPassword.errors }" />

                        <div *ngIf="submitted && f.newPassword.errors" class="error-message">
                            <div *ngIf="f.newPassword.errors.required"
                                [translate]="'resetPasswordConfirm.form.newpassword.required'"></div>
                        </div>
                    </div>
                    <div class="a-d-flex a-flex-column a-textarea-box">
                        <label class="a-form-label" for="newPasswordConfirm" id="newPasswordConfirm_label"
                            [translate]="'resetPasswordConfirm.form.newpasswordconfirm.label'"></label>
                        <input type="password" formControlName="newPasswordConfirm" id="newPasswordConfirm"
                            class="a-text-input"
                            placeholder="{{ 'resetPasswordConfirm.form.newpasswordconfirm.placeholder' | translate }}"
                            [ngClass]="{ 'a-input-error': submitted && f.newPasswordConfirm.errors }" />

                        <div *ngIf="submitted && f.newPasswordConfirm.errors" class="error-message">
                            <div *ngIf="f.newPasswordConfirm.errors.required"
                                [translate]="'resetPasswordConfirm.form.newpasswordconfirm.required'"></div>
                        </div>
                    </div>
                    <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-between center"
                        fxLayoutAlign.lt-sm="space-between center" fxLayoutGap="8px" class="a-textarea-footer">
                        <div class="login-operation">
                            <a [routerLink]="['../../../login']" class=" forgot-password-btn"
                                [translate]="'resetPasswordConfirm.form.back.button.label'">
                            </a>
                        </div>
                        <button class="buttonInnoHub" [ngClass]="isHandset && !isHandsetLandscape ? 'btn-block' : ''"
                            [translate]="'resetPasswordConfirm.form.request.button.label'"></button>
                    </div>
                    <div class="row">
                        <div class="col-lg-12" style="line-height: 20px;">
                            <span style="font-weight: 300;" translate>password.rules</span>:<br>
                            <ul>
                                <li style="list-style:circle;" translate>password.length</li>
                                <li style="list-style:circle;" translate>password.number</li>
                                <li style="list-style:circle;" translate>password.lowercase</li>
                                <li style="list-style:circle;" translate>password.uppercase</li>
                                <li style="list-style:circle;" translate>password.alfa</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </form>
        </div>
    </div>
    <div fxFlex="" style="padding: 20px; 
        background-color: #696969;
        color: white;
        width: 400px;
        font-size: 12px;
        line-height: 12px;border-bottom-left-radius: 10px;border-bottom-right-radius: 10px;">
        Do you need information on the protection of personal data?<br>
        Download Terms and Conditions
    </div>
</div>