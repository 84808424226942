import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Profile } from 'src/app/models/profile';
import { environment } from 'src/environments/environment';
import { EventsService } from '../global/events.service';
import { NVCrudService } from '@nv/core';
import { mainConfigs } from 'src/app/configs/main-configs';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ResetPasswordConfirm } from 'src/app/models/reset-password-confirm';

@Injectable({
    providedIn: 'root'
})
export class ProfileService extends NVCrudService<Profile> {

    constructor(
        private http: HttpClient,
        private eventsService: EventsService
    ) {
        super(http, environment.backendURL + 'api/', 'profile', eventsService, mainConfigs.auditProperties);
    }

    // send a vote
    resetPassword(
        pathVariables: Map<string, string>,
        profileID: string,
    ): Observable<void> {
        const extraParams: HttpParams = new HttpParams();
        return this.http
            .put<any>(
                super.getResourceURI(pathVariables, profileID) + 'resetPassword',
                { params: extraParams })
            .pipe(map(res => {
                return res;
            }));
    }
    // create a reset password request
    resetPasswordRequest(
        username: string,
    ): Observable<void> {
        const extraParams: HttpParams = new HttpParams();
        return this.http
            .put<any>(
                environment.backendURL + 'api/' + username + '/resetPasswordRequest',
                { params: extraParams })
            .pipe(map(res => {
                return res;
            }));
    }
    // confirm a reset Password
    resetPasswordConfirm(
        resetPasswordConfirm: ResetPasswordConfirm,
    ): Observable<void> {
        const extraParams: HttpParams = new HttpParams();
        return this.http
            .put<any>(
                environment.backendURL + 'api/' + resetPasswordConfirm.username + '/resetPasswordConfirm',
                resetPasswordConfirm,
                { params: extraParams })
            .pipe(map(res => {
                return res;
            }));
    }


    createProfile(profile:Profile):Observable<Profile>
    {
        return this.http.post<Profile>(environment.backendURL + 'api/profile/', profile)
        .pipe(map(res => {
            return res;
        })); 
    }

    createProfilePublic(profile:Profile):Observable<Profile>
    {
        return this.http.post<Profile>(environment.backendURL + 'api/profilepublic/', profile)
        .pipe(map(res => {
            return res;
        })); 
    }

    myProfile():Observable<Profile>
    {
        return this.http.get<Profile>(environment.backendURL + 'api/profile/me/')
        .pipe(map(res => {
            return res;
        })); 
    }

    public download(): Observable<any> {
        let params: HttpParams = new HttpParams();  
            return this.http.get(environment.backendURL + 'api/profile/download', { params, responseType: 'blob' });
     
    }
    

}
