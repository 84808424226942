import { Injectable } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { NVAuthInterceptor, NVAuthService } from '@nv/auth';


@Injectable()
export class HttpRequestInterceptorService extends NVAuthInterceptor {
  constructor(
    public authService: NVAuthService
  ) {
    super(authService);
  }
}
