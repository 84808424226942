import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { mainConfigs } from 'src/app/configs/main-configs';
import { AuthService } from 'src/app/services/auth.service';
import { ProfileService } from 'src/app/services/crud/profile.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  isHandsetLandscape: boolean;
  isHandset: boolean;

  submitted = false;

  redirectInProgress = false;

  customRedirect: string = undefined;


  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private profileService: ProfileService,
    private router: Router,
    public breakpointObserver: BreakpointObserver,
    private route: ActivatedRoute,
  ) {
    breakpointObserver.observe([
      Breakpoints.HandsetLandscape,
    ]).subscribe(result => {
      this.isHandsetLandscape = result.matches;
    });
    breakpointObserver.observe([
      Breakpoints.Handset,
    ]).subscribe(result => {
      this.isHandset = result.matches;
    });
  }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      username: ['', Validators.required], // value TO BE REMOVED, for testing purposes only
      password: ['', Validators.required], // value TO BE REMOVED, for testing purposes only
    });

    let error = undefined;
    this.route.queryParams.forEach(
      el => {
        if (el) {
          if (el['error']) {
            error = el['error'];
          }
          if (el['redirect']) {
            this.customRedirect = el['redirect'];
          }
        }
      });

    if (this.isEnabledOpenAMLogin()) {
      this.redirectInProgress = true;
      if (this.authService.getAccessToken()) {
        this.openAMSSO();
      } else {
        this.authService.openAMInit();
      }
    }
  }

  openAMSSO(): void {

    if (!this.authService.isLogged()) {
      // console.log(this.authService.getApplicationToken());
      if (this.authService.getAccessToken() === null ||
        this.authService.getAccessToken() === undefined ||
        this.authService.getAccessToken() === ''
      ) {
        this.authService.openAMInit();
      } else {
        // this.authService.openAMGetAuthToken(this.authService.getToken());
        this.redirectInProgress = true;
        this.authService.openAMGetAuthToken(this.authService.getToken()).subscribe(
          response => {
            this.login(response);
          },
          error => {
            console.log(error);
            this.authService.openAMInit();
          });
      }
    }
  }

  login(token): void {
    this.authService.loadContextWithToken(token).subscribe(() => {
      this.router.navigateByUrl(mainConfigs.loginSuccessURL);
    });
  }


  onSubmit() {
    this.submitted = true;
    if (this.loginForm.invalid) {
      return;
    }
    this.authService
      .login(encodeURIComponent(this.f.username.value), this.f.password.value)
      .subscribe((response) => {
        if (this.authService.getAuthContext().user.roles.indexOf("ROLE_ADMIN") != -1)
          this.router.navigateByUrl(mainConfigs.loginSuccessURL);
        else {
          this.profileService.myProfile().subscribe(profile => {
            if (profile.company != null)
              this.router.navigateByUrl(mainConfigs.loginPublicSuccessURL);
            else
              this.router.navigateByUrl('/secure/profile');
          })


        }
      });
  }
  // convenience getter for easy access to form fields
  get f() { return this.loginForm.controls; }

  isEnabledOpenAMLogin() {
    return environment.enableOpenAM;
  }

  oauth2Login() {
    this.authService.openAMInit();
  }
}
