// *** class name is CurriculumService
// *** entity name is Curriculum
// *** TODO: please check that everything is correct

import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { NVCrudService } from '@nv/core';
import { EventsService } from '../global/events.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
        providedIn: 'root'
})
export class CurriculumService {
    
    constructor(
        private httpClient: HttpClient,
        private eventService: EventsService
    ) {
       
    }
    
    cvUploadAction(uploadedImage: File[]): Observable<any> {
        const privacyFormData = new FormData();


        for(let i=0;i<uploadedImage.length;i++){
            privacyFormData.append('curriculum', uploadedImage[i], uploadedImage[i].name);
        }

    
        return this.httpClient.post(environment.backendURL + 'api/curriculum/', privacyFormData, { observe: 'response' })
            .pipe(map(res => {
                return res.body;
            }));
    }

    

    public download(privacyid: string): Observable<any> {
        let params: HttpParams = new HttpParams();  
            return this.httpClient.get(environment.backendURL + 'api/curriculum/'+privacyid+'/download', { params, responseType: 'blob' });
     
    }
    
}