import { CommonModule, DatePipe, DecimalPipe, registerLocaleData } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule, LOCALE_ID } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ChartsModule } from 'ng2-charts';
import { SlimLoadingBarModule } from 'ng2-slim-loading-bar';
import { CookieService } from 'ngx-cookie-service';
import { PpBreadcrumbsModule } from 'pp-breadcrumbs';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NotificationContainerComponent } from './components/notification-container/notification-container.component';
import { NotificationComponent } from './components/notification/notification.component';
import { HttpRequestInterceptorService } from './interceptors/http-request-interceptor';
import { HttpResponseInterceptorService } from './interceptors/http-response-interceptor';
import { MainComponent } from './main/main.component';
import { LoginComponent } from './main/public/login/login.component';
import { BreadcrumbsComponent } from './main/secure/common/breadcrumbs/breadcrumbs.component';
import { FooterComponent } from './main/secure/common/footer/footer.component';
import { HeaderComponent } from './main/secure/common/header/header.component';
import { RoutingStateService } from './services/routing-state.service';
import { SharedModule } from './shared/shared.module';
import { InternationalizationModule, InternationalizationService } from '@nv/internationalization';
import { environment } from 'src/environments/environment';
import { EventsService } from './services/global/events.service';
import { NVAuthConfigurations, NVAuthService } from '@nv/auth';
import { mainConfigs } from './configs/main-configs';
import { AuthService } from './services/auth.service';
import { ProfileResolver } from './resolvers/profile.resolver';
import { NVCoreModule } from '@nv/core';
import localeEN from '@angular/common/locales/en';
import localeIT from '@angular/common/locales/it';
import { NgxWebstorageModule } from 'ngx-webstorage';
import { ResetPasswordRequestComponent } from './main/public/resetPasswordRequest/reset-password-request.component';
import { ResetPasswordConfirmComponent } from './main/public/resetPasswordConfirm/reset-password-confirm.component';
import { PublicHomeComponent } from './main/public/home/home.component';
import { RegisterComponent } from './main/public/register/register.component';
import { PublicCallDetailComponent } from './main/public/calldetail/calldetail.component';
import { NgxUiLoaderConfig, NgxUiLoaderModule, NgxUiLoaderService } from 'ngx-ui-loader';
import { Config } from 'ngx-ui-loader/lib/utils/interfaces';
import { PublicEventDetailComponent } from './main/public/eventdetail/eventdetail.component';
import { CallResolver } from './resolvers/call.resolver';
import { PrivacyComponent } from './main/public/privacypolicy/privacy.component';
import { RegulationComponent } from './main/public/regulation/regulation.component';
import { ContactPublicComponent } from './main/public/contact/contact.component';


const ngxUiLoaderConfig: NgxUiLoaderConfig = {
  bgsColor: 'red',
  bgsOpacity: 0.5,
  bgsPosition: 'bottom-right',
  bgsSize: 40,
  bgsType: 'ball-spin-clockwise',
  blur: 5,
  delay: 0,
  fgsColor: '#f50505',
  fgsPosition: 'center-center',
  fgsSize: 60,
  fgsType: 'ball-spin-clockwise',
  gap: 24,
  logoPosition: 'center-center',
  logoSize: 120,
  logoUrl: '',
  masterLoaderId: 'master',
  overlayBorderRadius: '0',
  overlayColor: 'rgba(154,154,154,0.69)',
  pbColor: 'red',
  pbDirection: 'ltr',
  pbThickness: 3,
  hasProgressBar: true,
  text: '',
  textColor: '#FFFFFF',
  textPosition: 'center-center',
  maxTime: -1,
  minTime: 500,
};

const ngxModuleConfig: Config = {
  showForeground: true
};


registerLocaleData(localeIT, 'it_IT');
registerLocaleData(localeEN, 'en_US');
@NgModule({
  declarations: [
    AppComponent,
    MainComponent,
    HeaderComponent,
    FooterComponent,
    PublicHomeComponent,
    PublicEventDetailComponent,
    LoginComponent,
    PublicCallDetailComponent,
    RegisterComponent,
    ResetPasswordRequestComponent,
    BreadcrumbsComponent,
    NotificationComponent,
    NotificationContainerComponent,
    ResetPasswordConfirmComponent,
    PrivacyComponent,
    RegulationComponent,
    ContactPublicComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    NgxWebstorageModule.forRoot(),
    NVCoreModule.forRoot(),
    HttpClientModule,
    SlimLoadingBarModule,
    ChartsModule,
    PpBreadcrumbsModule,
    AppRoutingModule,
    CommonModule,
    SharedModule,
    InternationalizationModule.forRoot(environment.internationalizationUrl, environment.defLang),
    NVCoreModule,
    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig)
  ],
  providers: [
    NgxUiLoaderService,
    RoutingStateService,
    EventsService,
    ProfileResolver,
    CallResolver,
    DecimalPipe,
    DatePipe,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpRequestInterceptorService,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpResponseInterceptorService,
      multi: true
    },
    CookieService,
    {
      provide: LOCALE_ID,
      useFactory: (internationalizationService) => internationalizationService.getCurrentLanguage(),
      deps: [InternationalizationService]
    },
    { provide: NVAuthConfigurations, useFactory: () => new NVAuthConfigurations(mainConfigs.authConfiguration) },
    {
      provide: NVAuthService,
      useClass: AuthService,
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
