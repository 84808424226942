import { LayoutModule } from '@angular/cdk/layout';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgbModule, NgbDateAdapter, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ProfileResolver } from '../resolvers/profile.resolver';
import { MessageResolver } from '../resolvers/message.resolver';
import { NgbStringAdapter } from '../filters/ngbStringAdapter';
import { NgbDateCustomParserFormatter } from '../filters/ngbDateCustomParserFormatter';
import { ModalComponent } from 'src/app/components/modal/modal.component';
import { ActionsComponent } from '../components/actions/actions.component';
import { FiltersComponent } from '../components/filters/filters.component';
import { NgbModalBackdrop } from '@ng-bootstrap/ng-bootstrap/modal/modal-backdrop';
import { HomeComponent } from '../components/homepage/home.component';
import { CallDetailComponent } from '../components/calldetail/calldetail.component';
import { CallDetailViewComponent } from '../components/calldetailview/calldetailview.component';
import { EventDetailComponent } from '../components/eventdetail/event-detail.component';
import { CKEditorModule } from 'ckeditor4-angular';
import { ContactComponent } from '../components/contact/contact.component';
@NgModule({
    declarations: [
        ActionsComponent,
        ModalComponent,
        FiltersComponent,
        HomeComponent,
        CallDetailComponent,
        CallDetailViewComponent,
        EventDetailComponent,
        ContactComponent
        
    ],
    imports: [
        FlexLayoutModule.withConfig({ addOrientationBps: true }),
        CKEditorModule,
        TranslateModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        LayoutModule,
        RouterModule,
        NgSelectModule,
        NgbModule,
    ],
    providers: [
        ProfileResolver,
        MessageResolver,
        { provide: NgbDateAdapter, useClass: NgbStringAdapter },
        { provide: NgbDateParserFormatter, useClass: NgbDateCustomParserFormatter }
    ],
    exports: [
        CKEditorModule,
        NgbModule,
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        FlexLayoutModule,
        LayoutModule,
        RouterModule,
        NgSelectModule,
        TranslateModule,
        ActionsComponent,
        FiltersComponent,
        ModalComponent,
        FiltersComponent,
        HomeComponent,
        CallDetailComponent,
        CallDetailViewComponent,
        EventDetailComponent,
        ContactComponent

    ]
})
export class SharedModule {

    constructor(
        private translate: TranslateService
    ) {
    }
}
