<div fxLayout="row" [fxLayoutAlign]="isHandsetLandscape ? 'center start' : 'center center'" style="height: 100%;background-color: #F3F3F3;">
    <div class="login-wrapper" fxFlex="400px" fxFlex.lt-sm="90%">
        <div class="login" id="resetPasswordRequest">

            <div class="row">
               
                <div class="col-12" style="text-align: left;">
                    <span class="desfa-logo-grid a-lg"></span>
                </div>
                <div class="application-name" style="line-height: 1;">Welcome to the</div>
                <div class="application-name" style="line-height: 1;">innovation platform</div>
                <div class="application-text" style="margin-top:20px;font-weight: 600;">Reset password</div>
                <div class="application-text">Enter your email below, you will receive a link to reset your password</div>
               
            </div>

           

            <form [formGroup]="resetPasswordRequestForm" (ngSubmit)="onSubmit()" class="form-signin" style="margin-top: 10px;">
                <div class="user-fields">
                    <div class="a-d-flex a-flex-column a-textarea-box">
                       
                        <input type="text" formControlName="username" id="username" class="a-text-input"
                            placeholder="Email"
                            [ngClass]="{ 'a-input-error': submitted && f.username.errors }" />

                        <div *ngIf="submitted && f.username.errors" class="error-message">
                            <div *ngIf="f.username.errors.required"
                                [translate]="'resetPasswordRequest.form.username.required'"></div>
                        </div>

                    </div>
                    <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-between center"
                        fxLayoutAlign.lt-sm="space-between center" fxLayoutGap="8px" class="a-textarea-footer">
                        <div class="login-operation">
                            <a [routerLink]="['../login']" class="forgot-password-btn"
                                [translate]="'resetPasswordRequest.form.back.button.label'">
                            </a>
                        </div>
                        <button class="buttonInnoHub"
                            [ngClass]="isHandset && !isHandsetLandscape ? 'btn-block' : ''"
                            >Send</button>
                    </div>
                </div>
            </form>
        </div>
        <div style="padding: 20px;
        background-color: #696969;
        color: white;
        font-size: 12px;
        line-height: 12px;border-bottom-left-radius: 10px;border-bottom-right-radius: 10px;">
            Do you need information on the protection of personal data?<br>
            Read the <a style="color:white" href="/privacy" target="_blank">privacy policy</a>
        </div>
    </div>
</div>