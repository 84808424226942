

import { Component, OnInit } from '@angular/core';



@Component({
  selector: 'app-home',
  templateUrl: './calldetailview.component.html',
  styleUrls: ['./calldetailview.component.scss']
})
export class CallDetailViewComponent  {
 
  
}
