import { environment } from 'src/environments/environment';

export const mainConfigs = {
    loginSuccessURL: '/admin/home',
    loginPublicSuccessURL:'/secure/home',
    loginURL: 'login',
    homeURL: 'home',
    authStorageKey: 'CURRENT_AUTH',
    defaultPageSizeOptions: [10, 25, 50, 100],
    defaultPageSize: 25,
    authConfiguration: new Map<string, any>(Object.entries({
        internalBackEndpoint: environment.backendURL,
        internalLoginEndpoint: 'auth/login',
        internalLogoutEndpoint: 'auth/logout',
        internalResetPasswordRequestEndpoint: 'auth/${username}/passwordResetRequest/',
        internalResetPasswordPerformEndpoint: 'auth/${username}/passwordResetRequest/${token}/perform',
        internalChangePasswordEndpoint: 'auth/changePassword',
        internalFirstChangePasswordEndpoint: 'auth/${username}/firstChangePassword/${token}/perform',
        internalProfileEndpoint: 'auth/me',
        internalFindAllRolesEndpoint: 'auth/admin/role/',
        internalLockEndpoint: 'auth/admin/user/${username}/lock',
        internalUnlockEndpoint: 'auth/admin/user/${username}/unlock',
        internalAuthStorageKey: 'x-auth-token',
    })),
    auditProperties: ['id', 'createdDate', 'lastModifiedBy', 'lastModifiedBy', 'lastModifiedDate', 'owner'],
    accessToken: 'baseaccesstoken',
    oauth2AccessTokenUrl: 'oauth2/accessToken/',
    callbackurl: 'oauth2/authorize/',
};
