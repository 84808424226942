
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, HostListener, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { NVPreferencesService } from '@nv/core';
import { InternationalizationService } from '@nv/internationalization';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ModalComponent } from 'src/app/components/modal/modal.component';
import { mainConfigs } from 'src/app/configs/main-configs';
import { AuthService } from 'src/app/services/auth.service';
import { CallService } from 'src/app/services/crud/call.service';
import { EventService } from 'src/app/services/crud/evento.service';
import { environment } from 'src/environments/environment';
import { EFilterType, Filter } from '../filters/filters.component';



@Component({
  selector: 'app-homecomponent',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  isDesktop: boolean = window.innerWidth > 767;
  isHandsetLandscape: boolean;
  isHandset: boolean;
  active = 1;

  submitted = false;

  redirectInProgress = false;

  customRedirect: string = undefined;

  public isNavbarCollapsed = true;

  calls: any[] = [];
  events: any[] = [];
  projects: any[] = [];

  //buttone read more read less 
  isReadMore: boolean = true;

  constructor(
    private ngxloader: NgxUiLoaderService,
    private crudService: CallService,
    private formBuilder: FormBuilder,
    public authService: AuthService,
    private router: Router,
    public breakpointObserver: BreakpointObserver,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private internationalizationService: InternationalizationService,
    private preferencesService: NVPreferencesService,
    private callService: CallService,
    private eventService: EventService,
    private ngbModalService: NgbModal,
  ) {

    breakpointObserver.observe([
      Breakpoints.HandsetLandscape,
    ]).subscribe(result => {
      this.isHandsetLandscape = result.matches;
    });
    breakpointObserver.observe([
      Breakpoints.Handset,
    ]).subscribe(result => {
      this.isHandset = result.matches;
    });
  }

  public filterCall: Map<string, Filter> = new Map<string, Filter>([
    ['status', { type: EFilterType.RANGE, range: [{ value: 'OPEN' }, { value: 'CLOSED' }, { value: 'DRAFT' }] }],
    // ['dataScadenza', { type: EFilterType.DATE }],
  ]);

  public filterEvent: Map<string, Filter> = new Map<string, Filter>([
    ['status', { type: EFilterType.RANGE, range: [{ value: 'OPEN' }, { value: 'CLOSED' }, { value: 'DRAFT' }] }],
    // ['dataScadenza', { type: EFilterType.DATE }],
  ]);


  ngOnInit() {
    this.ngxloader.start();
    this.callService.getCallFromPublic().subscribe(data => {
      this.calls = data;
      this.ngxloader.stop();
    })
    this.eventService.getEventFromPublic().subscribe(data => {
      this.events = data;
    })

    console.log(" è destop? " + this.isDesktop)
    this.checkScreenWidth();

  }

  availableLanguages(): string[] {
    return this.translate.getLangs();
  }

  changeLanguage(language: string) {
    this.internationalizationService.changeLanguage(language);
    window.location.reload();
  }

  currentLanguage(): string {
    return this.translate.currentLang;
  }

  login() {
    this.router.navigate(['/login']);
  }

  register() {
    this.router.navigate(['/register']);
  }

  mysubmission() {
    this.router.navigate(['/secure/mysubmission']);
  }
  securecontact() {
    this.router.navigate(['/secure/contact']);
  }
  publiccontact() {
    this.router.navigate(['/contact']);
  }

  logout(): void {
    const modalRef = this.ngbModalService.open(ModalComponent);
    modalRef.componentInstance.message = this.translate.instant('logout.modal.message');
    modalRef.componentInstance.title = this.translate.instant('logout.modal.title');
    modalRef.result.then(result => {
      if (environment.enableOpenAM) {
        this.authService.openAMLogout();
      } else {
        this.authService.logout().subscribe((response) => {
          this.router.navigateByUrl(mainConfigs.loginURL);
        });
      }
    }).catch(() => {

    });
  }

  checkScreenWidth() {
    this.isDesktop = window.innerWidth > 767;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.checkScreenWidth();
  }

  onFilterCallChange(event: any) {

  }

  onFilterEventChange(event: any) {

  }

  toggleReadMore() {
    this.isReadMore = !this.isReadMore;
  }





}
