import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { InternationalizationService } from '@nv/internationalization';
import { SessionStorageService } from 'ngx-webstorage';
import { ModalComponent } from 'src/app/components/modal/modal.component';
import { mainConfigs } from 'src/app/configs/main-configs';
import { AuthService } from 'src/app/services/auth.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  public isNavbarCollapsed = true;
  public isHandset: boolean;

  constructor(
    public authService: AuthService,
    private translate: TranslateService,
    private internationalizationService: InternationalizationService,
    private router: Router,
    private ngbModalService: NgbModal,
    private sessionStorage: SessionStorageService,
    private breakpointObserver: BreakpointObserver
  ) {
    breakpointObserver.observe([
      Breakpoints.Handset,
    ]).subscribe(result => {
      this.isHandset = result.matches;
    });
  }

  availableLanguages(): string[] {
    return this.translate.getLangs();
  }

  changeLanguage(language: string) {
    this.internationalizationService.changeLanguage(language);
    window.location.reload();
  }

  currentLanguage(): string {
    return this.translate.currentLang;
  }

  ngOnInit() {
    console.log(this.authService)
  }

  logout(): void {
    const modalRef = this.ngbModalService.open(ModalComponent);
    modalRef.componentInstance.message = this.translate.instant('logout.modal.message');
    modalRef.componentInstance.title = this.translate.instant('logout.modal.title');
    modalRef.result.then(result => {
      if (environment.enableOpenAM) {
        this.authService.openAMLogout();
      } else {
        this.authService.logout().subscribe((response) => {
          this.router.navigateByUrl(mainConfigs.homeURL);
        });
      }
    }).catch(() => {

    });
  }
  get authContext() { return this.authService.getAuthContext(); }

  isAdmin() {
    this.authService.getAuthContext()
  }
}
