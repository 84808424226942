import { ActivatedRoute, Router, Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { MessageService } from '../services/crud/message.service';
import { Message } from '../models/message';
import { NVPathVariableResourceResolver } from '@nv/core';
import { Observable, of, from } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { mergeMap } from 'rxjs/operators';
@Injectable({ providedIn: 'root' })
export class MessageResolver extends NVPathVariableResourceResolver<Message> {

    constructor(
        private messageService: MessageService,
        private router: Router,
        private route: ActivatedRoute
    ) {
        super(
            messageService,
            router,
            route
        );
    }

}

@Injectable({ providedIn: 'root' })
export class MessageBreadcrumbResolver implements Resolve<string> {
    constructor(protected messageResolver: MessageResolver, protected translateService: TranslateService) { }

    resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<string> {
        const solvedMessage = this.messageResolver.resolve(route) as unknown as Observable<Message>;
        const label = route.data.breadcrumbLabel;
        return solvedMessage.pipe(
            mergeMap(
                value => {
                    return of(this.translateService.instant(label, { message: value }));
                })
        );
    }
}
