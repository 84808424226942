// *** class name is RegulationService
// *** entity name is Regulation
// *** TODO: please check that everything is correct

import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { NVCrudService } from '@nv/core';
import { EventsService } from '../global/events.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
        providedIn: 'root'
})
export class RegulationService {
    
    constructor(
        private httpClient: HttpClient,
        private eventService: EventsService
    ) {
       
    }
    
    regulationUploadAction(uploadedImage: File): Observable<any> {
        const regulationFormData = new FormData();
        regulationFormData.append('regulation', uploadedImage, uploadedImage.name);

        return this.httpClient.post(environment.backendURL + 'api/regulation/', regulationFormData, { observe: 'response' })
            .pipe(map(res => {
                return res.body;
            }));
    }

    updateRegulationForCall(uploadedImage: File,callid:string): Observable<any> {
        const imageFormData = new FormData();
        imageFormData.append('regulation', uploadedImage, uploadedImage.name);

        return this.httpClient.post(environment.backendURL + 'api/regulation/call/'+callid, imageFormData, { observe: 'response' })
            .pipe(map(res => {
                return res.body;
            }));
    }

    public download(regulationid: string): Observable<any> {
        let params: HttpParams = new HttpParams();  
            return this.httpClient.get(environment.backendURL + 'api/regulation/'+regulationid+'/download', { params, responseType: 'blob' });
     
    }


    
}