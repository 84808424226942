<div class="row">
    <div class="col-12">
        <div class="shadow-sm bg-white rounded-sm py-3">
            <ul class="fix-padding">
                <li *ngFor="let action of actions">
                    <span class="action-header text-uppercase" *ngIf="action.show">{{action.title}}</span>
                    <ul class="fa-ul mb-3">
                        <li *ngFor="let link of action.links">
                            <span [ngSwitch]="link.type" *ngIf="action.show">
                                <span class="fa-li"><i class="fa {{link.icon}}"></i></span>
                                <a *ngSwitchCase="actionLinkTypes.HREF" [href]="link.url"
                                    class="text-decoration-none">{{link.title}}</a>
                                <a *ngSwitchCase="actionLinkTypes.ROUTERLINK" [routerLink]="link.url"
                                    class="text-decoration-none">{{link.title}}</a>
                                <a *ngSwitchCase="actionLinkTypes.GOBACK" href="javascript:window.history.back()"
                                    class="text-decoration-none">{{link.title}}</a>
                            </span>
                        </li>
                    </ul>
                </li>
            </ul>
        </div>
    </div>
</div>
