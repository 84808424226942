import { Component, Input, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, FormControl } from '@angular/forms';
import { debounceTime } from 'rxjs/operators';
import { Subscription } from 'rxjs';
import { mainConfigs } from 'src/app/configs/main-configs';
import { NVPreferencesService } from '@nv/core';
export class RangeValue {
  id?: string;
  value: any;
}
export enum EFilterType {
  TEXT,
  NUMBER,
  DATE,
  RANGE,
  BOOLEAN
}
export class Filter {
  type: EFilterType;
  range?: RangeValue[];
  translateValues?: boolean;
}
export class FilterValue {
  attribute: string;
  value: any;
}
@Component({
  selector: 'app-filters',
  templateUrl: './filters.component.html',
  styleUrls: ['./filters.component.scss']
})
export class FiltersComponent implements OnInit, OnDestroy {

  private subscriptions: Set<Subscription> = new Set<Subscription>();

  filterForm: FormGroup;
  filterType = EFilterType;

  @Input() filterMap: Map<string, Filter>;
  @Input() resourceName: string;

  @Output() filter = new EventEmitter<Map<string, string>>();

  constructor(
    private nvPreferencesService: NVPreferencesService,
    formBuilder: FormBuilder,
  ) {
    this.filterForm = formBuilder.group({});
  }

  getLabelPrefix(attribute: string) {
    return this.resourceName + '.filter.' + attribute;
  }

  onSubmit() {
    const filters = new Map<string, string>();
    Object.keys(this.filterForm.controls).forEach(key => {
      if (this.filterForm.controls[key].value !== undefined && this.filterForm.controls[key].value !== null) {
        filters.set(key, this.filterForm.controls[key].value.toString());
      }
    });
    this.filter.emit(filters);
  }

  onReset() {
    this.initForm();
    this.onSubmit();
  }

  ngOnInit() {
    this.initForm();
  }

  initForm() {
    this.unsubscribeAll();
    this.filterForm.reset();
    if (this.filterMap !== undefined) {
      this.filterMap.forEach((value: Filter, key: string) => {
        this.filterForm.controls[key] = new FormControl();
        this.filterForm.controls[key].valueChanges
          .pipe(
            debounceTime(200),
          )
          .subscribe(() => {
            this.onSubmit();
          });
      });
    }
  }

  unsubscribeAll() {
    for (const subscription of this.subscriptions) {
      subscription.unsubscribe();
    }
  }

  ngOnDestroy() {
    this.unsubscribeAll();
  }

}
