// *** class name is BusinessService
// *** entity name is Business
// *** TODO: please check that everything is correct

import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { NVCrudService } from '@nv/core';
import { EventsService } from '../global/events.service';
import { EMPTY, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root'
})
export class BusinessService {

    constructor(
        private httpClient: HttpClient,
        private eventService: EventsService
    ) {

    }


    businessUploadAction(uploadedImage: File): Observable<any> {

      
            const privacyFormData = new FormData();
            privacyFormData.append('business', uploadedImage, uploadedImage.name);


            return this.httpClient.post(environment.backendURL + 'api/business/', privacyFormData, { observe: 'response' })
                .pipe(map(res => {
                    return res.body;
                }));
       
    }

    public download(businessid: string): Observable<any> {
        let params: HttpParams = new HttpParams();
        return this.httpClient.get(environment.backendURL + 'api/business/' + businessid + '/download', { params, responseType: 'blob' });

    }

}