import { Brevetto } from "./brevetto";
import { Call } from "./call";

export class Submission {
    public constructor(init?: Partial<Submission>) {
        Object.assign(this, init);
    }
    id: string = null;
    soluzione: string = null;
    tecnologia: string = null;
    progetti: string = null;
    dettagliFinanziari: string = null;
    brevetti: Brevetto[] = [];
    pitchId: string = null;
    businessId: string = null;
    callId: string = null;
    call: Call = null;
    nome: string = null;
    readiness: number = 0;
    status: string = null;
    evaluationStatus1: string = null;
    evaluationStatus2: string = null;
    pitch: any = null;
    business: any = null;
    cvs: string[] = [];
    curriculum: any[] = [];

    //aggiunti per call interne
    departmentBelonging: string = null;
    //attachments: string[] = [];
    desfaAdvantages: String = null;



}
