import { Component, OnInit } from '@angular/core';
import { Event, NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { InternationalizationService } from '@nv/internationalization';
import { SlimLoadingBarService } from 'ng2-slim-loading-bar';
import { PpBreadcrumbsService } from 'pp-breadcrumbs';
import { RoutingStateService } from './services/routing-state.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  constructor(
    private loadingBar: SlimLoadingBarService,
    private router: Router,
    private breadcrumbsService: PpBreadcrumbsService,
    private routingStateService: RoutingStateService,
    private internationalizationService: InternationalizationService
  ) {
    this.router.events.subscribe((event: Event) => {
      this.navigationInterceptor(event);
    });
    this.routingStateService.loadRouting();
  }

  public ngOnInit(): void {
  }

  private navigationInterceptor(event: Event): void {
    if (event instanceof NavigationStart) {
      this.loadingBar.start();
    } else if (event instanceof NavigationEnd) {
      this.loadingBar.complete();
    } else if (event instanceof NavigationCancel) {
      this.loadingBar.stop();
    } else if (event instanceof NavigationError) {
      this.loadingBar.stop();
    }
  }
}
