
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { DatePipe, Location, ViewportScroller } from '@angular/common';
import { Component, HostListener, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { CRUDMode, NVFormController, NVNotificationService, NVUtilsService } from '@nv/core';
import { InternationalizationService } from '@nv/internationalization';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Observable } from 'rxjs';
import { ModalComponent } from 'src/app/components/modal/modal.component';
import { mainConfigs } from 'src/app/configs/main-configs';
import { Brevetto } from 'src/app/models/brevetto';
import { Call } from 'src/app/models/call';
import { Submission } from 'src/app/models/submission';
import { AuthService } from 'src/app/services/auth.service';
import { BusinessService } from 'src/app/services/crud/business.service';
import { CallService } from 'src/app/services/crud/call.service';
import { CurriculumService } from 'src/app/services/crud/curriculum.service';
import { PitchService } from 'src/app/services/crud/pitch.service';
import { PrivacyService } from 'src/app/services/crud/privacy.service';
import { RegulationService } from 'src/app/services/crud/regulation.service';
import { SubmissionService } from 'src/app/services/crud/submission.service';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-calldetailcomponent',
  templateUrl: './calldetail.component.html',
  styleUrls: ['./calldetail.component.scss']
})
export class CallDetailComponent extends NVFormController<Submission> implements OnInit {

  isHandsetLandscape: boolean;
  isHandset: boolean;
  public formSubmitted: boolean = false;
  redirectInProgress = false;
  public brevetti: Brevetto[] = [];
  customRedirect: string = undefined;
  public pitchFile: File;
  public pitchUploadWarning = false;
  public businessFile: File = null;
  public businessFileWarning = false;
  public readiness: number = 1;
  public disclaimerRegulation: String;
  public disclaimerPrivacy: String;
  public cvFile: File[] = [];
  public cvFileWarnings: boolean[] = [];

  public isNavbarCollapsed = true;
  public numeroFasi = 0;
  public numeroCV = 0;

  callDetail: Call;
  public callid;
  public imageid;
  public detailImageId;
  isDesktop: boolean = window.innerWidth > 767;


  constructor(
    private viewportScroller: ViewportScroller,
    private regulationService: RegulationService,
    private privacyService: PrivacyService,
    private businessService: BusinessService,
    private crudService: SubmissionService,
    private router: Router,
    public breakpointObserver: BreakpointObserver,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private internationalizationService: InternationalizationService,
    private callService: CallService,
    private fb: FormBuilder,
    private notificationService: NVNotificationService,
    private datePipe: DatePipe,
    public authService: AuthService,
    private ngbModalService: NgbModal,
    private ngxloader: NgxUiLoaderService,
    private pitchService: PitchService,
    private curriculumService: CurriculumService,
    private location: Location,

  ) {

    super(
      route,
      Submission,
      'id',
      crudService,
      fb,
      notificationService,
      router,
      translate,
      datePipe,
      null,
      true
    );

    breakpointObserver.observe([
      Breakpoints.Handset,
    ]).subscribe(result => {
      this.isHandset = result.matches;
    });
  }

  ngOnInit() {

    this.ngxloader.start();
    this.callid = this.route.snapshot.paramMap.get('callid');
    this.callService.getCallDetailFromPublic(this.callid).subscribe(data => {
      this.callDetail = data;
      this.imageid = "data:image/jpg;base64," + this.callDetail.detailImage?.imagebyte;
      //this.detailImageId = "data:image/jpg;base64," + this.callDetail.detailImage.imagebyte;


      this.ngxloader.stop();
      console.log(this.resourceForm)
    })
    this.checkScreenWidth();
  }

  public onPitchUpload(event) {
    this.pitchFile = event.target.files[0];
    if (this.pitchFile.size > 10000 * 1024) {
      this.pitchUploadWarning = true;
    } else {
      this.pitchUploadWarning = false;
    }

  }

  public onCVUpload(event, i) {
    this.cvFile[i] = event.target.files[0];
    if (this.cvFile[i].size > 10000 * 1024) {
      this.cvFileWarnings[i] = true;
    } else {
      this.cvFileWarnings[i] = false;
    }

  }

  public onBusinessUpload(event) {
    this.businessFile = event.target.files[0];
    if (this.businessFile.size > 10000 * 1024) {
      this.businessFileWarning = true;
    } else {
      this.businessFileWarning = false;
    }

  }



  availableLanguages(): string[] {
    return this.translate.getLangs();
  }

  changeLanguage(language: string) {
    this.internationalizationService.changeLanguage(language);
    window.location.reload();
  }

  currentLanguage(): string {
    return this.translate.currentLang;
  }

  login() {
    this.router.navigate(['/login']);
  }

  logout(): void {
    const modalRef = this.ngbModalService.open(ModalComponent);
    modalRef.componentInstance.message = this.translate.instant('logout.modal.message');
    modalRef.componentInstance.title = this.translate.instant('logout.modal.title');
    modalRef.result.then(result => {
      if (environment.enableOpenAM) {
        this.authService.openAMLogout();
      } else {
        this.authService.logout().subscribe((response) => {
          this.router.navigateByUrl(mainConfigs.loginURL);
        });
      }
    }).catch(() => {

    });
  }

  aggiungiBrevetto() {
    this.numeroFasi = this.numeroFasi + 1;
  }

  eliminaPatent(i: number) {
    this.numeroFasi = this.numeroFasi - 1;
  }

  aggiungiCV() {
    this.numeroCV = this.numeroCV + 1;
  }

  eliminaCV(i: number) {
    this.cvFile.splice(i, 1);
    this.cvFileWarnings.splice(i, 1);
    this.numeroCV = this.numeroCV - 1;
  }

  onSubmit(onlyValidation: boolean = false, isModal: boolean = false) {
    this.ngxloader.start();
    const that = this;
    // stop here if form is invalid
    if (!onlyValidation && (that.resourceForm.invalid || that.crudMode === CRUDMode.READ)) {
      this.ngxloader.stop();
      return;
    }


    this.formSubmitted = true;
    let formDataEntity = new Submission(that.resourceForm.getRawValue());



    // if the form based entity is different than the entity, save the entity
    if (!NVUtilsService.compareValues(that.datePipe, that.getResource(), formDataEntity)) {
      if (that.crudMode === CRUDMode.CREATE) {

        for (var i = 0; i < this.numeroFasi; i++) {
          let brevetto = new Brevetto();
          let nome = (<HTMLInputElement>(document.getElementById("titolo" + i))).value;
          let descrizione = (<HTMLInputElement>(document.getElementById("descrizione" + i))).value;
          brevetto.nome = nome;
          brevetto.descrizione = descrizione;

          this.brevetti.push(brevetto);
        }

        formDataEntity.brevetti = this.brevetti;
        formDataEntity.callId = this.callid;
        formDataEntity.readiness = this.readiness;

        that.crudService.createAndUploadFiles(that.pathVariables, formDataEntity, this.cvFile, this.pitchFile, this.businessFile, onlyValidation)
          .subscribe(id => {

            this.ngxloader.stop();
            if (!onlyValidation) {

              //crea le erogazioni
              that.notificationService.showSuccess(
                that.translate.instant(
                  'resource.' + that.resourceNameLabel + '.modal.create.successMessage',
                  { resourceId: id }
                )
              );
              this.router.navigate(['/secure/mysubmission/list'])
            }
          },
            errorResponse => { this.ngxloader.stop(); that.validationError(errorResponse, onlyValidation); }
          );

        //create only for interne call
        // if (this.callDetail.internal) {

        //   // Upload CV
        //   this.curriculumService.cvUploadAction(this.cvFile).subscribe(data => {
        //     formDataEntity.cvs = data;

        //     that.crudService.create(that.pathVariables, formDataEntity, onlyValidation)
        //       .subscribe(id => {

        //         this.ngxloader.stop();
        //         if (!onlyValidation) {

        //           //crea le erogazioni
        //           that.notificationService.showSuccess(
        //             that.translate.instant(
        //               'resource.' + that.resourceNameLabel + '.modal.create.successMessage',
        //               { resourceId: id }
        //             )
        //           );
        //           this.router.navigate(['/secure/mysubmission/list'])
        //         }
        //       },
        //         errorResponse => { this.ngxloader.stop(); that.validationError(errorResponse, onlyValidation); }
        //       );
        //   })

        // } else {
        //   //create for external call only
        //   //Upload CV
        //   this.curriculumService.cvUploadAction(this.cvFile).subscribe(data => {

        //     formDataEntity.cvs = data;
        //     // UPLOAD Pitch
        //     this.pitchService.pitchUploadAction(this.pitchFile).subscribe(pitchid => {
        //       formDataEntity.pitchId = pitchid;


        //       if (this.businessFile != null) {
        //         // TODO: riorganizzare l'upload dei file
        //         // UPLOAD business
        //         this.businessService.businessUploadAction(this.businessFile).subscribe(businessId => {
        //           formDataEntity.businessId = businessId;

        //           // it is a new entity, create it
        //           that.crudService.create(that.pathVariables, formDataEntity, onlyValidation)
        //             .subscribe(id => {

        //               this.ngxloader.stop();
        //               if (!onlyValidation) {

        //                 //crea le erogazioni
        //                 that.notificationService.showSuccess(
        //                   that.translate.instant(
        //                     'resource.' + that.resourceNameLabel + '.modal.create.successMessage',
        //                     { resourceId: id }
        //                   )
        //                 );
        //                 this.router.navigate(['/secure/mysubmission/list'])
        //               }
        //             },
        //               errorResponse => { this.ngxloader.stop(); that.validationError(errorResponse, onlyValidation); }
        //             );
        //         },
        //           errorResponse => { this.ngxloader.stop(); that.validationError(errorResponse, onlyValidation); }
        //         );
        //       }
        //       else {
        //         that.crudService.create(that.pathVariables, formDataEntity, onlyValidation)
        //           .subscribe(id => {

        //             this.ngxloader.stop();
        //             if (!onlyValidation) {

        //               //crea le erogazioni
        //               that.notificationService.showSuccess(
        //                 that.translate.instant(
        //                   'resource.' + that.resourceNameLabel + '.modal.create.successMessage',
        //                   { resourceId: id }
        //                 )
        //               );
        //               this.router.navigate(['/secure/mysubmission/list'])
        //             }
        //           },
        //             errorResponse => { this.ngxloader.stop(); that.validationError(errorResponse, onlyValidation); }
        //           );
        //       }
        //     })
        //   })
        // }

      } else if (that.crudMode === CRUDMode.UPDATE) {
        // it is an existing entity, update it
        that.crudService.update(that.pathVariables, that.resource.id, formDataEntity, onlyValidation).subscribe(
          () => {
            this.ngxloader.stop();
            if (!onlyValidation) {
              that.notificationService.showSuccess(
                that.translate.instant(
                  'resource.' + that.resourceNameLabel + '.modal.update.successMessage',
                  { resourceId: that.resource.id }
                )
              );
              if (isModal) {
                this.modal.close();
              } else {
                this.onSubmitRedirect(that.crudMode);
              }
            }
          },
          errorResponse => { that.validationError(errorResponse, onlyValidation); }
        );
      }
    } else { // otherwise, go back to the list of entities without saving any changes
      if (!onlyValidation) {
        this.ngxloader.stop();
        that.notificationService.showWarning(
          that.translate.instant(
            'resource.' + that.resourceNameLabel + '.modal.update.nothingChanged',
            { resourceId: that.resource.id }
          )
        );
        if (isModal) {
          this.modal.close();
        } else {
          this.onSubmitRedirect(that.crudMode);
        }
      }
    }
  }
  onSubmitRedirect(crudMode: CRUDMode) {
    if (crudMode === CRUDMode.CREATE) {
      this.router.navigate(['../'], { relativeTo: this.route });
    } else if (crudMode === CRUDMode.UPDATE) {
      this.router.navigate(['../../'], { relativeTo: this.route });
    }
  }

  @HostListener('window:beforeunload', ['$event'])
  onWindowClose(event: any): void {
    const formDataEntity = new Call(this.resourceForm.getRawValue());
    if (!this.formSubmitted && !NVUtilsService.compareValues(this.datePipe, this.getResource(), formDataEntity)) {
      event.preventDefault();
      event.returnValue = false;
    }
  }

  checkScreenWidth() {
    this.isDesktop = window.innerWidth > 767;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.checkScreenWidth();
  }



  canDeactivate(): Observable<boolean> | Promise<boolean> | boolean {
    return true;
  }

  addReadiness() {
    this.readiness = this.readiness + 1;
  }

  minusReadiness() {
    this.readiness = this.readiness - 1;
  }

  downloadRegulation(regulation: any) {

    this.ngxloader.start();

    this.regulationService.download(regulation.id).subscribe(res => {
      this.ngxloader.stop();
      let url = window.URL.createObjectURL(res);
      let a = document.createElement("a");
      document.body.appendChild(a);
      a.setAttribute("style", "display: none");
      a.href = url;
      a.download = regulation.nome
      a.click();
      window.URL.revokeObjectURL(url);
      a.remove();
    },
      error => {
        this.ngxloader.stop();
        this.notificationService.showError("Error downloading file! Contact the help desk")
      });
  }

  downloadPrivacy(privacy: any) {

    this.ngxloader.start();

    this.privacyService.download(privacy.id).subscribe(res => {
      this.ngxloader.stop();
      let url = window.URL.createObjectURL(res);
      let a = document.createElement("a");
      document.body.appendChild(a);
      a.setAttribute("style", "display: none");
      a.href = url;
      a.download = privacy.nome
      a.click();
      window.URL.revokeObjectURL(url);
      a.remove();
    },
      error => {
        this.ngxloader.stop();
        this.notificationService.showError("Error downloading file! Contact the help desk")
      });
  }

  gotoanchor(elementId: string) {
    this.viewportScroller.scrollToAnchor(elementId);
  }
  register() {
    this.router.navigate(['/register']);
  }
  publiccontact() {
    this.router.navigate(['/contact']);
  }
  securecontact() {
    this.router.navigate(['/secure/contact']);
  }

  mysubmission() {
    this.router.navigate(['/secure/mysubmission']);
  }

  checkFilesValidity() {
    this.cvFileWarnings.forEach(cv => {
      if (cv)
        return true;
    })
    if (this.pitchUploadWarning || this.businessFileWarning)
      return true;
    else return false;
  }

  shouldShowForm(): boolean {
    return this.authService.isLogged() && this.callDetail && (this.authService.isInternal() || !this.callDetail.internal);
  }

  goBack() {
    this.location.back();
  }

}
