// *** class name is CallService
// *** entity name is Call
// *** TODO: please check that everything is correct

import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Event, EventType, NVCrudService } from '@nv/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Call } from 'src/app/models/call';
import { environment } from 'src/environments/environment';
import { EventsService } from '../global/events.service';

@Injectable({
    providedIn: 'root'
})
export class CallService extends NVCrudService<Call> {

    constructor(
        private httpClient: HttpClient,
        private eventService: EventsService
    ) {
        super(
            httpClient,
            'api/',
            'callforidea',
            eventService
        )
    }

    getParentResourceURI(pathVariables: Map<string, string>): string {
        // *** TODO: verify how to compose the URI based on the relativity of the entity (if it has parent or not) ***
        // *** EXAMPLE: return environment.backendURL + super.getBaseURI() + "parentEntityName/" + pathVariables.get("parentEntityID") + "/";
        return environment.backendURL + super.getBaseURI();
    }

    getCallFromPublic(): Observable<any> {
        // this.internalNVEventService.emit(new Event(this.getResourceName(), EventType.LOADING));
        return this.httpClient.get<any>(environment.backendURL + 'api/callforideahome/')
            .pipe(map(res => {
                return res;
            }));
    }

    getCallDetailFromPublic(callid: any): Observable<any> {
        // this.internalNVEventService.emit(new Event(this.getResourceName(), EventType.LOADING));
        return this.httpClient.get<any>(environment.backendURL + 'api/callforideahome/' + callid)
            .pipe(map(res => {
                return res;
            }));
    }

    public createAndUploadFiles(pathVariables: Map<string, string>, resource: Call, image: File, detailImage: File, privacyFile: File, regulationFile: File, onlyValidation?: boolean): Observable<string> {
        const formData = new FormData();
        formData.append('newCall', new Blob([JSON.stringify(resource)], { type: 'application/json' }));
        formData.append('image', image, image?.name);
        formData.append('detailImage', detailImage, detailImage?.name);
        formData.append('privacy', privacyFile, privacyFile?.name);
        formData.append('regulation', regulationFile, regulationFile?.name);

        const extraParams: HttpParams = new HttpParams()
            .set('onlyValidation', onlyValidation.toString());

        return this.httpClient
            .post<string>(environment.backendURL + 'api/callforidea/', formData, {
                responseType: 'text' as 'json',
                params: extraParams
            })
            .pipe(map(res => {
                this.eventService.emit(new Event('callforidea', EventType.CREATE, res));
                return res;
            }));
    }

}
