import { Component, OnInit } from '@angular/core';
import { Breadcrumb, PpBreadcrumbsService } from 'pp-breadcrumbs';
import { TranslateService } from '@ngx-translate/core';
import { ActivatedRoute, Router, ResolveEnd } from '@angular/router';

@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styleUrls: ['./breadcrumbs.component.scss']
})
export class BreadcrumbsComponent implements OnInit {
  public breadcrumbList: Breadcrumb[] = [];

  constructor(
    private ppBreadcrumbsService: PpBreadcrumbsService,
    private translate: TranslateService,
    private router: Router,
    private route: ActivatedRoute
  ) {
  }

  ngOnInit() {
    this.ppBreadcrumbsService.crumbs$.subscribe(crumbs => {
      this.breadcrumbList = crumbs;
    });
  }

  cutLastUrlSegment(path: string): string {
    return path.substr(0, path.lastIndexOf('/'));
  }

}
