
import { Component, HostListener, OnInit } from '@angular/core';

@Component({
  selector: 'app-home',
  templateUrl: './calldetail.component.html',
  styleUrls: ['./calldetail.component.scss']
})
export class SecureCallDetailComponent {

  


}
