import { Injectable } from '@angular/core';
import { NgbDateAdapter, NgbDateStruct } from '@ng-bootstrap/ng-bootstrap';

@Injectable()
export class NgbStringAdapter extends NgbDateAdapter<Date> {

    fromModel(date: Date): NgbDateStruct {
        if (date) {
            const tmpDate = new Date(date);
            return {
                year: tmpDate.getUTCFullYear(),
                month: tmpDate.getUTCMonth() + 1,
                day: tmpDate.getUTCDate()
            };
        } else {
            return null;
        }
    }

    toModel(date: NgbDateStruct): Date {
        return date ? new Date(Date.UTC(date.year, date.month - 1, date.day, 0, 0, 0)) : null;
    }
}
