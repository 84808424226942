import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ROLE } from './enums/role';
import { CanLoadChildrenViaHasRoleGuard } from './guards/can-load-children-via-has-role.guard';
import { MainComponent } from './main/main.component';
import { LoginComponent } from './main/public/login/login.component';
import { ChangePasswordComponent } from './main/secure/profile/change-password/change-password.component';
import { NVCanActivateViaHasRoleGuard, NVCanActivateViaIsLoggedGuard } from '@nv/auth';
import { ResetPasswordRequestComponent } from './main/public/resetPasswordRequest/reset-password-request.component';
import { ResetPasswordConfirmComponent } from './main/public/resetPasswordConfirm/reset-password-confirm.component';
import { PublicHomeComponent } from './main/public/home/home.component';
import { PublicCallDetailComponent } from './main/public/calldetail/calldetail.component';
import { RegisterComponent } from './main/public/register/register.component';
import { SecureHomeComponent } from './main/secure/home/home.component';
import { SecureCallDetailComponent } from './main/secure/calldetail/calldetail.component';
import { MySubmissionListComponent } from './main/secure/mysubmission/mysubmission-list.component';
import { CallDetailViewComponent } from './main/secure/calldetailview/calldetailview.component';
import { PublicEventDetailComponent } from './main/public/eventdetail/eventdetail.component';
import { PrivacyComponent } from './main/public/privacypolicy/privacy.component';
import { RegulationComponent } from './main/public/regulation/regulation.component';
import { ContactPublicComponent } from './main/public/contact/contact.component';
import { SecureContactComponent } from './main/secure/contact/contact.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'register',
    component: RegisterComponent
  },
  {
    path: 'home',
    component: PublicHomeComponent,
  },
  {
    path: 'contact',
    component: ContactPublicComponent,
  },
  {
    path: 'privacy',
    component: PrivacyComponent,
  },
  {
    path: 'regulation',
    component: RegulationComponent,
  },
  {
    path: 'calldetail/:callid',
    component: PublicCallDetailComponent,
  },
  {
    path: 'eventdetail/:eventid',
    component: PublicEventDetailComponent,
  },
  {
    path: 'reset-password-request',
    component: ResetPasswordRequestComponent
  },
  {
    path: ':username/first-change-password/:resetCode',
    data: {firstChangePassword: true},
    component: ResetPasswordConfirmComponent
  },
  {
    path: ':username/reset-password-confirm/:resetCode',
    data: {firstChangePassword: false},
    component: ResetPasswordConfirmComponent
  },
  {
    path: 'secure',
    canActivate: [NVCanActivateViaIsLoggedGuard],

    children: [
      {
        path: 'home',
        component: SecureHomeComponent,
        loadChildren: () => import('./main/secure/home/home.module').then(m => m.SecureHomeModule)
      },
      {
        path: 'mysubmission',
        component: MainComponent,
        loadChildren: () => import('./main/secure/mysubmission/mysubmission.module').then(m => m.MySubmissionModule)
      },
      {
        path: 'calldetail/:callid',
        component: SecureCallDetailComponent,
        loadChildren: () => import('./main/secure/calldetail/calldetail.module').then(m => m.SecureCallDetailModule)
      },
      {
        path: 'mysubmission/:submissionid',
        component: CallDetailViewComponent,
        loadChildren: () => import('./main/secure/calldetailview/calldetailview.module').then(m => m.CallDetailViewModule)
      },
      {
        path: 'profile',
        data: { breadcrumbs: 'breadcrumb.profile' },
        component: MainComponent,
        loadChildren: () => import('./main/secure/profile/profile.module').then(m => m.ProfileModule)
      },
      {
        path: 'contact',
        component: SecureContactComponent,
        loadChildren: () => import('./main/secure/contact/contact.module').then(m => m.SecureContactModule)
      }
    ]
  },
  {
    path: 'admin',
    canActivate: [NVCanActivateViaIsLoggedGuard],
    component: MainComponent,
    loadChildren: () => import('./main/secure/admin/admin.module').then(m => m.AdminModule),
  },
  {
    path: 'admin/submission/:submissionid',
    canActivate: [NVCanActivateViaIsLoggedGuard],
    loadChildren: () => import('./main/secure/admin/calldetailview/calldetailview.module').then(m => m.CallDetailViewModule)
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
