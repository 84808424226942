import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { EventsService } from '../global/events.service';
import { Message } from 'src/app/models/message';
import { NVCrudService } from '@nv/core';
import { mainConfigs } from 'src/app/configs/main-configs';

@Injectable({
    providedIn: 'root'
})
export class MessageService extends NVCrudService<Message> {

    constructor(
        private http: HttpClient,
        private eventsService: EventsService
    ) {
        super(http, environment.backendURL + 'api/', 'message', eventsService, mainConfigs.auditProperties);
    }

}
