// *** class name is ProfileFormComponent
// *** entity name is Profile
// *** TODO: please check that everything is correct

import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NVNotificationService } from '@nv/core';
import { mainConfigs } from 'src/app/configs/main-configs';
import { Profile } from 'src/app/models/profile';
import { ProfileService } from 'src/app/services/crud/profile.service';


@Component({
  selector: 'app-profile-form',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {

  loginForm: FormGroup;
  isHandsetLandscape: boolean;
  isHandset: boolean;
  submitted = false;
  redirectInProgress = false;
  customRedirect: string = undefined;
  profile: Profile = new Profile();
  email: string;
  // password:string;
  // confirmpassword:string;
  lastName: string;
  firstName: string;
  username: string;


  constructor(
    private formBuilder: FormBuilder,
    private profileService: ProfileService,
    private router: Router,
    public breakpointObserver: BreakpointObserver,
    private route: ActivatedRoute,
    private notificationService: NVNotificationService
  ) {
    breakpointObserver.observe([
      Breakpoints.HandsetLandscape,
    ]).subscribe(result => {
      this.isHandsetLandscape = result.matches;
    });
    breakpointObserver.observe([
      Breakpoints.Handset,
    ]).subscribe(result => {
      this.isHandset = result.matches;
    });
  }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      lastName: ['', [Validators.required, Validators.maxLength(20)]],
      firstName: ['', [Validators.required, Validators.maxLength(20)]],
      email: ['', [Validators.required, Validators.maxLength(50)]],
      //    username: ['', Validators.required],
      // password: ['', Validators.required],
      // confirmpassword: ['' , Validators.required]
    });
    // this.loginForm.addValidators(
    //   this.matchValidator(this.loginForm.get('password'), this.loginForm.get('confirmpassword'))
    // );
  }

  //  matchValidator(
  //   control: AbstractControl,
  //   controlTwo: AbstractControl
  // ): ValidatorFn {
  //   return () => {
  //     if (control.value !== controlTwo.value)
  //       return { password: "Passwords does not match"};
  //     return null;
  //   };
  // }

  onSubmit() {
    this.profile.email = this.email;
    this.profile.firstName = this.firstName;
    this.profile.lastName = this.lastName;
    // this.profile.password=this.password;
    this.profile.createdDate = null;
    this.profile.lastModifiedDate = null;
    this.profile.username = this.email;

    this.submitted = true;
    if (this.loginForm.invalid) {
      return;
    }
    this.profileService.createProfilePublic(this.profile)
      .subscribe((response) => {
        this.notificationService.showSuccess("Registration successfully completed!")
        this.router.navigateByUrl(mainConfigs.loginURL);
      });
  }
  // convenience getter for easy access to form fields
  get f() { return this.loginForm.controls; }




}
