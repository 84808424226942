<nav class="navbar navbar-expand-lg navbar-light mb-1 px-3">
  <div class="desfa-logo-grid"></div>


  <span class="divider mx-3"></span>
  <span class="divider ms-3"></span>
  <button class="navbar-toggler hidden-sm-up" type="button" (click)="isNavbarCollapsed = !isNavbarCollapsed"
    data-target="#navbarsDefault" aria-controls="navbarsDefault" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>

  <div [ngbCollapse]="isNavbarCollapsed" class="collapse navbar-collapse" id="navbarsDefault">
    <span class="ms-auto">
    </span>

    <ul class="navbar-nav" *ngIf="!authService.isLogged()">

      <a [ngClass]="isHandset? 'menu-option-mobile' : 'login-option pointer'" (click)="login()">
        <span *ngIf="isHandset" class="appkiticon a-btn-icon icon-login-outline"></span> Log in</a>
      <a (click)="register()" [ngClass]="isHandset? 'menu-option-mobile ' : 'btn_contact'">
        <span *ngIf="isHandset" class="appkiticon a-btn-icon icon-login-outline"></span> Sign up</a>
      <a (click)="publiccontact()" [ngClass]="isHandset? 'menu-option-mobile' : 'btn_contact'">
        <span *ngIf="isHandset" class="appkiticon a-btn-icon icon-help-question-outline"></span> Contact Us</a>

    </ul>
    <ul class="navbar-nav" *ngIf="authService.isLogged()" style="font-size:14px">
      <a (click)="mysubmission()" [ngClass]="isHandset? 'menu-option-mobile' : 'menu-option right-border'">
        <span *ngIf="isHandset" class="appkiticon a-btn-icon icon-survey-outline"></span> My Submissions</a>
      <a (click)="logout()" [ngClass]="isHandset? 'menu-option-mobile' : 'menu-option'">
        <span *ngIf="isHandset" class="appkiticon a-btn-icon icon-log-out-outline"></span> Log out</a>
      <a (click)="securecontact()" [ngClass]="isHandset? 'menu-option-mobile' : 'btn_contact'">
        <span *ngIf="isHandset" class="appkiticon a-btn-icon icon-help-question-outline"></span> Contact Us</a>
      <a [routerLink]="['../profile/my-profile']" *ngIf="isHandset && authService.isLogged()"
        class="menu-option-mobile">
        <span class="appkiticon a-btn-icon icon-person-outline"></span> Profile
      </a>
    </ul>

    <ul class="navbar-nav" *ngIf="!isHandset && authService.isLogged()">
      <li class="nav-item" ngbDropdown>
        <a class="nav-link"
          style="cursor:pointer; font-size: 14px;
                padding-left: 6px;
                padding-top: 3px;margin-left:5px;width:30px;height:30px;border-radius: 15px;background-color: #2BABE2;color:white"
          ngbDropdownToggle>
          {{authService.getAuthContext().user.username.substring(0,2).toUpperCase()}}
        </a>
        <div class="dropdown-menu dropdown-menu-right text-right" ngbDropdownMenu style="left:-125px">
          <a class="dropdown-item" [routerLink]="['../profile/my-profile']">
            <span class="appkiticon a-btn-icon icon-person-outline"></span> Profile</a>
        </div>
      </li>
    </ul>

  </div>
</nav>
<div *ngIf="isDesktop" class="row banner"
  style="margin-top:50px; background-image: url(../../../../assets/images/Banner.jpg)">
  <div class="col-1"></div>
  <div class="col-5">
    <span style="font-size: 20px; color: white;">Today, innovation thrives on collaboration, knowledge sharing, and
      collective problem-solving. It is with this vision that Desfa + Innovation center is raised up as a dynamic
      initiative to harness the power of collaborative creativity and drive transformative change. </span><br><br>
    <span style="font-size: 20px; color: white;">By opening our doors to external partners, startups, researchers,
      and innovators from various domains, we aim to cultivate a rich ecosystem of shared knowledge and mutual
      growth. </span><br><br>
    <span style="font-size: 20px; color: white;">Through the Call4Ideas, we aim to catalyze innovation and
      accelerate the development of transformative technologies that will address the challenges in the energy
      industry and beyond.</span><br><br>
    <span style="font-size: 20px;font-weight: 500;color:white">Navigate our portal to discover the open calls,
      submit your idea and take part to Desfa + acceleration program</span><br><br>
  </div>
  <div class="col-1"></div>
</div>
<div *ngIf="!isDesktop" class="row bannerMobile">
  <!-- <div class="col-1"></div> -->
  <div class="col">

    <h1 class="mt-3" style="color: #20376C; ">Innovation Portal</h1>

    <span style="font-size: 20px; color: black;">Today, innovation thrives on collaboration, knowledge sharing, and
      collective problem-solving. It is with this vision that Desfa + Innovation center is raised up as a dynamic
      initiative to harness the power of collaborative creativity and drive transformative change. </span><br><br>

    <span *ngIf="!isReadMore" id="more">
      <span style="font-size: 20px; color: black;">By opening our doors to external partners, startups,
        researchers,
        and innovators from various domains, we aim to cultivate a rich ecosystem of shared knowledge and mutual
        growth. </span><br><br>
      <span style="font-size: 20px; color: black;">Through the Call4Ideas, we aim to catalyze innovation and
        accelerate the development of transformative technologies that will address the challenges in the energy
        industry and beyond.</span><br><br>
      <span style="font-size: 20px;font-weight: 500;color:black">Navigate our portal to discover the open calls,
        submit your idea and take part to Desfa + acceleration program</span><br><br>

    </span>
    <button (click)="toggleReadMore()" id="myBtn" class="mb-2">{{ isReadMore ? 'Read more' : 'Read less'
      }}</button>
    <br><br>

  </div>
  <img class="center-cropped" src="{{'../../../../assets/images/Banner.jpg'}} "
    style="width: 100%; height: 190px; object-fit: cover;  padding-left: 10px;  padding-right: 30px;">
</div>

<div class="row" [ngStyle]="isDesktop ? {'margin-top': '50px'} : {}">
  <div class="col-1"></div>
  <div class="col-10">

    <ul ngbNav #nav="ngbNav" [(activeId)]="active" [ngClass]="{'nav-tabs': isDesktop, '': !isDesktop}">
      <li [ngbNavItem]="1">
        <a ngbNavLink style="color:black">Calls</a>
        <ng-template ngbNavContent>
          <div class="row">
            <div class="row col-md-12 ">
              <div *ngIf="!isDesktop" class=" col-8">
                <app-filters (filter)="onFilterCallChange($event)" resourceName="call" [filterMap]="filterCall"
                  fxLayoutGap="2px grid">
                  <i class="fas fa-search"></i></app-filters>
                <!-- <i class="fas fa-search"></i>
                                <input (change)="onFilterChange($event)" type="text" placeholder="Search..."> -->

              </div>
              <div *ngIf="!isDesktop" ngbDropdown class="dropdown show col-4 mb-2">
                <a ngbDropdownToggle class="btn a-btn-primary" role="button" id="dropdownMenuLink"
                  data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                  style=" font-size: 18px; position: sticky; ">
                  Sort By
                </a>
                <div ngbDropdownMenu aria-labelledby class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                  <a class="dropdown-item">Status</a>
                  <a class="dropdown-item">Date</a>
                </div>
              </div>
            </div>

            <div *ngIf="calls.length==0">No calls available</div>
            <!-- [ngClass]="{'desktop-layout': isDesktop, 'mobile-layout': !isDesktop}"-->
            <div *ngFor="let item of calls" [ngClass]="{'col-4': isDesktop, 'col-12': !isDesktop}">

              <div [ngStyle]="isDesktop ? {'margin' : '20px'} : {'width' : '100%', 'margin-bottom' : '20px'}"
                style="box-shadow: 0 3px 10px rgb(0 0 0 / 0.2);background-color: white;border-radius: 15px;">

                <div class="container">
                  <img class="center-cropped" src="{{'data:image/jpg;base64,' + item.image.imagebyte}}"
                    [ngStyle]="isDesktop ? {} : {'height' : '170px'}">

                  <div *ngIf="item.status=='OPEN'" class="top-right"
                    style="background-color: green;color:white;font-size: 12px;border-radius: 15px;padding-left: 10px;padding-right: 10px;padding-top: 5px;padding-bottom: 5px;">
                    {{item.status}}</div>
                  <div *ngIf="item.status=='CLOSE'" class="top-right"
                    style="background-color: red;color:white;font-size: 12px;border-radius: 15px;padding-left: 10px;padding-right: 10px;padding-top: 5px;padding-bottom: 5px;">
                    {{item.status}}</div>

                  <div *ngIf="item.internal" class="top-right"
                    style="margin-top: 40px;background-color: green;color:white;font-size: 12px;border-radius: 15px;padding-left: 10px;padding-right: 10px;padding-top: 5px;padding-bottom: 5px;">
                    INTERNAL</div>
                </div>

                <div class="row" style="padding: 10px;">
                  <div class="col-3" style="text-align:center">
                    <span [ngStyle]="isDesktop ? {'font-size' : '10px'} : {'font-size' : '13px'}">DUE
                      DATE</span><br>
                    <span [ngStyle]="isDesktop ? {'font-size' : '10px'} : {'font-size' : '20px'}">
                      {{item.dataScadenza | date: 'MMM' |
                      uppercase}}</span>
                    <br><span [ngStyle]="isDesktop ? {} : {'font-weight' : 'bold'}">
                      {{item.dataScadenza | date: 'dd'}}</span>

                  </div>
                  <div [ngClass]="{'col-6': isDesktop, 'col-8': !isDesktop}">
                    <span style="font-style: italic;">Call for idea</span><br>
                    <span
                      [ngStyle]="isDesktop ? {'font-weight' : 'bold'} : {'font-size' : '20px',  'line-height': '25px', 'font-weight' : 'bold'}">{{item.titolo}}</span><br>
                    <div
                      [ngStyle]="isDesktop ? {'font-size' : '10px', 'line-height': '14px' } : {'font-size' : '14px',  'line-height': '16px'}">
                      {{(item.breveDescrizione.length>60)? (item.breveDescrizione |
                      slice:0:60)+'...':(item.breveDescrizione)}}</div>
                  </div>
                  <div [ngClass]="{'col-3': isDesktop, 'col-12': !isDesktop}" style="margin-top: 20px;font-size: 14px;"
                    *ngIf="item.status != 'CLOSED'">
                    <button *ngIf="!authService.isLogged()"
                      [ngClass]="{'buttonInnoHub': isDesktop, 'col-11 buttonInnoHubMobile': !isDesktop}"
                      [routerLink]="['/calldetail/'+item.id]">Apply</button>
                    <button *ngIf="authService.isLogged()"
                      [ngClass]="{'buttonInnoHub': isDesktop, 'col-11 buttonInnoHubMobile': !isDesktop}"
                      [routerLink]="['/secure/calldetail/'+item.id]">Apply</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-template>
      </li>
      <li [ngbNavItem]="2">
        <a ngbNavLink style="color:black">Events</a>
        <ng-template ngbNavContent>
          <div class="row">
            <div class="row col-md-12 ">
              <div *ngIf="!isDesktop" class=" col-8">
                <app-filters (filter)="onFilterEventChange($event)" resourceName="event" [filterMap]="filterEvent"
                  fxLayoutGap="2px grid">
                  <i class="fas fa-search"></i></app-filters>
                <!-- <i class="fas fa-search"></i>
                                <input (change)="onFilterChange($event)" type="text" placeholder="Search..."> -->

              </div>
              <div *ngIf="!isDesktop" ngbDropdown class="dropdown show col-4 mb-2">
                <a ngbDropdownToggle class="btn a-btn-primary" role="button" id="dropdownMenuLink"
                  data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"
                  style=" font-size: 18px; position: sticky; ">
                  Sort By
                </a>
                <div ngbDropdownMenu aria-labelledby class="dropdown-menu" aria-labelledby="dropdownMenuLink">
                  <a class="dropdown-item">Status</a>
                  <a class="dropdown-item">Date</a>
                </div>
              </div>
            </div>
            <div *ngIf="events.length==0">No events available</div>
            <div *ngFor="let item of events" [ngClass]="{'col-4': isDesktop, 'col-12': !isDesktop}">

              <div style="margin: 20px;background-color: white;">

                <div class="row">
                  <div class="col-5">
                    <div class="container">
                      <img class="center-cropped-event" src="{{'data:image/jpg;base64,' + item.image.imagebyte}}">
                      <div class="top-right"
                        style="background-color: green;color:white;font-size: 12px;border-radius: 15px;padding-left: 10px;padding-right: 10px;padding-top: 5px;padding-bottom: 5px;">
                        {{item.status}}</div>
                    </div>
                  </div>
                  <div class="col-7">
                    <span style="font-size:12px;color:#696969">DATE:</span>
                    <span style="font-size:12px;color:#696969">{{item.dataScadenza | date: 'MMM' |
                      uppercase}}</span>
                    <span style="font-size:12px;color:#696969">{{item.dataScadenza | date:
                      'dd'}}</span>
                    <br>
                    <span style="font-weight: 500">{{item.titolo}}</span><br>
                    <div style="font-size:12px;color:#474747;line-height: 1.3;margin-top: 10px;">
                      {{(item.breveDescrizione.length>60)? (item.breveDescrizione |
                      slice:0:60)+'...':(item.breveDescrizione)}}</div>
                    <br>
                    <button class="buttonInnoHub" [routerLink]="['/eventdetail/'+item.id]">Partecipate</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-template>
      </li>
    </ul>

  </div>
  <div class="col-1">

  </div>
</div>


<div class="row">
  <div class="col-1"></div>
  <div class="col-10">
    <div class="row">
      <div [ngbNavOutlet]="nav" class="mt-2"></div>
    </div>
  </div>
  <div class="col-1"></div>
</div>