export class Contact {
    public constructor(init?: Partial<Contact>) {
        Object.assign(this, init);
    }
    id: string = '';
    fullname:string = null;
    email:string = null;
    environment:string = null;
    role:string = null;
    message:string = null;
    contactFileID:string=null;
    contactFile:any=null
}