import { HttpClient } from '@angular/common/http';
import { Injectable, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { NVAuth, NVAuthConfigurations, NVAuthService } from '@nv/auth';
import { SessionStorageService } from 'ngx-webstorage';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { mainConfigs } from '../configs/main-configs';

@Injectable({
  providedIn: 'root',
  useExisting: NVAuthService
})
export class AuthService extends NVAuthService {

  companyDomain: string = environment.companyDomain;

  constructor(
    private sessionStorageService: SessionStorageService,
    private httpClient: HttpClient,
    private config: NVAuthConfigurations,
    private domSanitizer: DomSanitizer
  ) {
    super(
      sessionStorageService,
      httpClient,
      config
    );
  }

  openAMInit() {
    return this.httpClient.post<any>(
      environment.backendURL + mainConfigs.oauth2AccessTokenUrl,
      {},
      { observe: 'response', withCredentials: true })
      .pipe(map(r => {
        return r.body;
      }))
      .subscribe(response => {
        // console.log("Application Token Reponse: " + response);
        this.setAccessToken(response);
        window.location.href = environment.backendURL + mainConfigs.callbackurl + 'openam' + "?redirect_uri=" + environment.redirecturi;
      });
  }

  private setAccessToken(input: string) {
    return this.sessionStorageService.store(mainConfigs.accessToken, input);
  }

  public getAccessToken(): string {
    return this.sessionStorageService.retrieve(mainConfigs.accessToken);
  }

  openAMGetAuthToken(applicationToken: string): Observable<any> {
    // let headers = new HttpHeaders();
    let options = { responseType: 'text' as 'text', withCredentials: true };
    // console.log(headers);
    return this.httpClient.get(environment.backendURL + mainConfigs.oauth2AccessTokenUrl, options).pipe(map(r => {
      return r;
    }));
  }

  setToken(input: string) {
    this.sessionStorageService.store(mainConfigs.authConfiguration.get('internalAuthStorageKey'), input);
  }

  loadContextWithToken(token): Observable<NVAuth> {

    this.setToken(token);
    return this.loadContext();
  }

  openAMLogout() {
    const that = this;
    that.httpClient.post(environment.backendURL + mainConfigs.authConfiguration.get('internalLogoutEndpoint'), null, { responseType: 'text' }).subscribe(res => {
      this.sessionStorageService.clear();
      window.location.href = this.domSanitizer.sanitize(SecurityContext.URL, res);
    });
    return null;
  }

  isInternal(): boolean {
    if (this.getAuthContext()?.user.username.endsWith('@' + this.companyDomain)) {
      return true
    } else return false;
  }
}
