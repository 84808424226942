<nav *ngIf="isDesktop" class="navbar navbar-expand-lg navbar-light mb-1 px-3">
    <div style="cursor: pointer;" [routerLink]="['/']" class="desfa-logo-grid md"></div>
    <span class="divider ms-3"></span>
    <button class="navbar-toggler hidden-sm-up" type="button" (click)="isNavbarCollapsed = !isNavbarCollapsed"
        data-target="#navbarsDefault" aria-controls="navbarsDefault" aria-expanded="false"
        aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
    </button>
    <div [ngbCollapse]="isNavbarCollapsed" class="collapse navbar-collapse" id="navbarsDefault">
        <span class="ms-auto">
        </span>
        <ul class="navbar-nav" *ngIf="!authService.isLogged()">
            <a [ngClass]="isHandset? 'menu-option-mobile' : 'login-option pointer'" (click)="login()">
                <span *ngIf="isHandset" class="appkiticon a-btn-icon icon-login-outline"></span> Log in</a>
            <a (click)="register()" [ngClass]="isHandset? 'menu-option-mobile ' : 'btn_contact'">
                <span *ngIf="isHandset" class="appkiticon a-btn-icon icon-login-outline"></span> Sign up</a>
            <a (click)="publiccontact()" [ngClass]="isHandset? 'menu-option-mobile' : 'btn_contact'">
                <span *ngIf="isHandset" class="appkiticon a-btn-icon icon-help-question-outline"></span> Contact Us</a>
        </ul>

        <ul class="navbar-nav" *ngIf="authService.isLogged()" style="font-size: 14px;">
            <a (click)="mysubmission()" [ngClass]="isHandset? 'menu-option-mobile' : 'menu-option right-border'">My
                Submissions</a>
            <a (click)="logout()" [ngClass]="isHandset? 'menu-option-mobile' : 'menu-option right-border'">
                <span *ngIf="isHandset" class="appkiticon a-btn-icon icon-log-out-outline"></span> Log out</a>
            <a (click)="securecontact()" [ngClass]="isHandset? 'menu-option-mobile' : 'btn_contact'">
                <span *ngIf="isHandset" class="appkiticon a-btn-icon icon-help-question-outline"></span> Contact Us</a>
            <a [routerLink]="['../profile/my-profile']" *ngIf="isHandset" class="menu-option-mobile">
                <span class="appkiticon a-btn-icon icon-person-outline"></span> Profile</a>
        </ul>
        <ul class="navbar-nav" *ngIf="!isHandset && authService.isLogged()">
            <li class="nav-item" ngbDropdown>
                <a class="nav-link"
                    style="cursor:pointer; font-size: 14px;
                padding-left: 6px;
                padding-top: 3px;margin-left:5px;width:30px;height:30px;border-radius: 15px;background-color: #2BABE2;color:white"
                    ngbDropdownToggle>
                    {{authService.getAuthContext().user.username.substring(0,2).toUpperCase()}}
                </a>
                <div class="dropdown-menu dropdown-menu-right text-right" ngbDropdownMenu style="left:-125px">
                    <a class="dropdown-item" [routerLink]="['../../profile/my-profile']">
                        <span class="appkiticon a-btn-icon icon-person-outline"></span> Profile</a>
                </div>
            </li>
        </ul>

    </div>
</nav>


<div *ngIf="callDetail && isDesktop" class="immagineCall">
    <img class="center-cropped-detail" src="{{'data:image/jpg;base64,' + callDetail.detailImage?.imagebyte}}">
    <div style="color:white">{{callDetail.titolo}}</div>
</div>
<div *ngIf="callDetail && !isDesktop" class="immagineCall">
    <img class="center-cropped-detail" src="{{'data:image/jpg;base64,' + callDetail.detailImage?.imagebyte}}">
    <div style="color:white;margin-top:-220px;margin-left:20px;">
        <span type="button" (click)="goBack()" style="font-size: large;">
            <span class="appkiticon a-btn-icon icon-left-chevron-outline"></span>
            All the Initiatives</span><br><br>
        <div class="text-align">Call for Ideas<br>
            <span style="font-size: xx-large; font-weight: bold;">{{callDetail.titolo}} </span>
        </div>
    </div>
</div>
<div *ngIf="callDetail" class="calendario">
    <div class="row">
        <div class="col-7" style="color:#FFFFFF">
            <span [ngStyle]="isDesktop ? {'font-size' : '10px'} : {'font-size' : '20px'}">Due date </span><br
                *ngIf="isDesktop">
            <span [ngStyle]="isDesktop ? {'font-size' : '30px'} : {'font-size' : '25px'}">{{callDetail.dataScadenza |
                date:'d MMMM'}}</span>
        </div>
        <div class="col-5" style="color:#FFFFFF"
            [ngStyle]="isDesktop ? {} : {'text-align' : 'end' , 'margin-top' : '10px'}">
            <span *ngIf="callDetail.status=='OPEN'"
                class="openState position-relative top-40 end-0 translate-middle-y">{{callDetail.status}}</span><br>
            <span class="closeState" *ngIf="callDetail.status=='CLOSE'">{{callDetail.status}}</span><br>
            <span *ngIf="callDetail.internal"
                class="openState position-relative top-90 end-0 translate-middle-y">INTERNAL</span><br>
        </div>
    </div>
    <div class="row mt-4">
        <div [ngClass]="{'col-7': isDesktop, 'col-12': !isDesktop}">
            <button *ngIf="!authService.isLogged()" (click)="login();"
                style="width:100%;border-radius: 5px;padding:5px;font-size: 14px;">Apply</button>
            <button *ngIf="authService.isLogged()" (click)="gotoanchor('submission');"
                [disabled]="callDetail.internal && !authService.isInternal()"
                style="width:100%;border-radius: 5px;padding:5px;font-size: 14px;">Apply</button>
        </div>
    </div>
    <div class="row mt-2">
        <div [ngClass]="{'col-7': isDesktop, 'col-12': !isDesktop}">
            <button (click)="downloadRegulation(callDetail.regulation)"
                style="width:100%;color:white;border:1px solid white;background-color: #20376C;border-radius: 5px;padding:5px;font-size: 14px;"><img
                    src="../../../../assets/images/download.png">&nbsp;&nbsp;Download the rules and regulation</button>
        </div>
    </div>
    <div class="row mt-2">
        <div class="col-7">
            <div (click)="downloadPrivacy(callDetail.privacy)" class="privacy">Privacy Policy
            </div>
        </div>
    </div>

</div>

<div *ngIf="callDetail" class="row" style="padding-top:40px;margin-bottom: 40px;"
    [ngStyle]="isDesktop ? {} : { 'padding-left' : '20px' }">
    <div class="col-1" *ngIf="isDesktop">

    </div>
    <div class="col-1" *ngIf="isDesktop">
        <img src="../../../../assets/images/path248.png">
    </div>
    <div class="col-1" *ngIf="isDesktop">

    </div>
    <div [ngClass]="{'col-5' : isDesktop , 'col-12' : !isDesktop}">
        <span style="color:#20376C;font-weight: bold;">Description</span>
        <br *ngIf="isDesktop"><br>
        {{callDetail.breveDescrizione}}
    </div>
    <div [ngClass]="{'col-3' : isDesktop , 'col-12' : !isDesktop}">
        <div class="row mb-3">
            <div [ngClass]="{'col-12' : isDesktop , 'col-12  mt-4' : !isDesktop}">
                <span style="color:#20376C;font-weight: bold;">Call phases</span>
            </div>
        </div>

        <div class="row" *ngFor="let fase of callDetail.fasi; let i = index;">
            <div class="col-1">
                <div [ngClass]="fase.attiva==true ? 'tondoblu':'tondogrigio'">{{i+1}}</div>
            </div>
            <div class="col-6" [ngStyle]="{'font-weight': fase.attiva==true ? 'bold':'normal'}">
                {{fase.nome}}
            </div>
            <div [ngClass]="{'col-5' : isDesktop , 'col-12' : !isDesktop}" *ngIf="fase.toData!=null"
                [ngStyle]="isDesktop ? {} : { 'padding-left' : '45px' }">
                From&nbsp;<span style="font-size: 14px;">{{fase.fromData | date:'dd/MM'}}</span>
                To&nbsp;<span style="font-size: 14px;">{{fase.toData | date:'dd/MM'}}</span>
            </div>
            <div class="col-5" *ngIf="fase.toData==null">
                <span style="font-size: 14px;">{{fase.fromData | date:'dd/MM'}}</span>
            </div>
        </div>
    </div>
    <div class="col-1" *ngIf="isDesktop">

    </div>
</div>


<div id="submission" *ngIf="shouldShowForm()" style="background-color: #E8E8E8;width:100%;padding-bottom: 60px;">
    <div class="row" [ngStyle]="isDesktop ? {'padding-top' : '100px'} : { 'padding-top' : '30px' }">
        <div class="col-1"></div>
        <div class="col-10">
            <span style="font-size: 12px;color:#20376C" class="primary">Submission</span>
        </div>
        <div class="col-1"></div>
    </div>
    <div class="row">
        <div class="col-1"></div>
        <div class="col-10">
            <span style="font-size: 25px;color:#20376C" class="primary">{{callDetail.titolo}}</span>
        </div>
        <div class="col-1"></div>
    </div>

    <div class="row" [ngStyle]="isDesktop ? { 'padding-top' : '60px' } : { 'padding-top' : '30px' }">
        <div class="col-1"></div>
        <div class="col-10">


            <form [formGroup]="resourceForm" (ngSubmit)="onSubmit()">

                <div class="row mb-2">
                    <!-- *** TODO: Insert fields here *** -->
                    <!-- *** FIELD titolo STARTS *** -->
                    <div class="col-lg-6 col-md-6 col-sm-12  " [ngClass]="{'' : isDesktop , 'mb-4' : !isDesktop}">
                        <!-- *** TODO: For required fields, please add .required class to the label *** -->
                        <div class="tondogrigio" style="position:absolute">1</div>
                        <label style="margin-left:25px" id="nome_label" for="nome"
                            class="a-form-label text-uppercase required"
                            [ngClass]="{ 'mb-0' : crudMode == crudModeType.READ }" translate>
                            call.form.nome.label</label>
                        <input required id="nome" type="text" formControlName="nome" class="a-text-input"
                            autocomplete="off"
                            placeholder="{{ crudMode == crudModeType.READ ? '' : ('general.inserttext' | translate )}}"
                            [ngClass]="{ 'a-input-error': (f.nome.dirty || f.nome.touched) && f.nome.errors && crudMode != crudModeType.READ,
         'form-control-plaintext pl-0 pt-0 no-box-shadow' : crudMode == crudModeType.READ }"
                            [readonly]="crudMode == crudModeType.READ" />
                        <div *ngIf="(f.nome.dirty || f.nome.touched) && f.nome.errors && crudMode != crudModeType.READ"
                            class="error-message">
                            <!-- *** TODO: Add or remove validators if needed *** -->
                            <div *ngIf="f.nome.errors.required" translate>call.form.nome.errors.required
                            </div>
                        </div>

                    </div>

                    <div class="col-lg-6 col-md-6 col-sm-12">
                        <!-- *** TODO: For required fields, please add .required class to the label *** -->
                        <div class="tondogrigio" style="position:absolute">2</div>
                        <label style="margin-left:25px" id="soluzione_label" for="soluzione"
                            class="a-form-label text-uppercase required"
                            [ngClass]="{ 'mb-0' : crudMode == crudModeType.READ }"
                            translate>call.form.solution.label</label>
                        <input required id="soluzione" type="text" formControlName="soluzione" class="a-text-input"
                            autocomplete="off"
                            placeholder="{{ crudMode == crudModeType.READ ? '' : ('general.inserttext' | translate )}}"
                            [ngClass]="{ 'a-input-error': (f.soluzione.dirty || f.soluzione.touched) && f.soluzione.errors && crudMode != crudModeType.READ,
         'form-control-plaintext pl-0 pt-0 no-box-shadow' : crudMode == crudModeType.READ }"
                            [readonly]="crudMode == crudModeType.READ" />
                        <div *ngIf="(f.soluzione.dirty || f.soluzione.touched) && f.soluzione.errors && crudMode != crudModeType.READ"
                            class="error-message">
                            <!-- *** TODO: Add or remove validators if needed *** -->
                            <div *ngIf="f.soluzione.errors.required" translate>call.form.soluzione.errors.required
                            </div>
                        </div>
                    </div>
                </div>
                <hr>

                <!-- *** TODO: Ensure that there is an <onSubmit()> function in the controller *** -->
                <div class="row mb-2">
                    <!-- *** TODO: Insert fields here *** -->
                    <!-- *** FIELD titolo STARTS *** -->


                    <div *ngIf="!callDetail.internal" class="col-lg-6 col-md-6 col-sm-12"
                        [ngClass]="{'' : isDesktop , 'mb-4' : !isDesktop}">
                        <!-- *** TODO: For required fields, please add .required class to the label *** -->
                        <div class="tondogrigio" style="position:absolute">3</div>
                        <label style="margin-left:25px" id="tecnologia_label" for="tecnologia"
                            class="a-form-label text-uppercase required"
                            [ngClass]="{ 'mb-0' : crudMode == crudModeType.READ }" translate>Technology used (max 255
                            charatcters)</label>
                        <input required id="tecnologia" type="text" formControlName="tecnologia" class="a-text-input"
                            autocomplete="off"
                            placeholder="{{ crudMode == crudModeType.READ ? '' : ('general.inserttext' | translate )}}"
                            [ngClass]="{ 'a-input-error': (f.tecnologia.dirty || f.tecnologia.touched) && f.tecnologia.errors && crudMode != crudModeType.READ,
         'form-control-plaintext pl-0 pt-0 no-box-shadow' : crudMode == crudModeType.READ }"
                            [readonly]="crudMode == crudModeType.READ" />
                        <div *ngIf="(f.tecnologia.dirty || f.tecnologia.touched) && f.tecnologia.errors && crudMode != crudModeType.READ"
                            class="error-message">
                            <!-- *** TODO: Add or remove validators if needed *** -->
                            <div *ngIf="f.tecnologia.errors.required" translate>call.form.tecnologia.errors.required
                            </div>
                        </div>
                    </div>

                    <div *ngIf="callDetail.internal" class="col-lg-6 col-md-6 col-sm-12"
                        [ngClass]="{'' : isDesktop , 'mb-4' : !isDesktop}">
                        <!-- *** TODO: For required fields, please add .required class to the label *** -->
                        <div class="tondogrigio" style="position:absolute">3</div>
                        <label style="margin-left:25px" id="tecnologia_label" for="desfaAdvantages"
                            class="a-form-label text-uppercase required"
                            [ngClass]="{ 'mb-0' : crudMode == crudModeType.READ }" translate>Describe your objective and
                            advantage for desfa</label>
                        <input required id="tecnologia" type="text" formControlName="desfaAdvantages"
                            class="a-text-input" autocomplete="off"
                            placeholder="{{ crudMode == crudModeType.READ ? '' : ('general.inserttext' | translate )}}"
                            [ngClass]="{ 'a-input-error': (f.desfaAdvantages.dirty || f.desfaAdvantages.touched) && f.desfaAdvantages.errors && crudMode != crudModeType.READ,
         'form-control-plaintext pl-0 pt-0 no-box-shadow' : crudMode == crudModeType.READ }"
                            [readonly]="crudMode == crudModeType.READ" />
                        <div *ngIf="(f.desfaAdvantages.dirty || f.desfaAdvantages.touched) && f.desfaAdvantages.errors && crudMode != crudModeType.READ"
                            class="error-message">
                            <!-- *** TODO: Add or remove validators if needed *** -->
                            <div *ngIf="f.desfaAdvantages.errors.required" translate>
                                call.form.desfaAdvantages.errors.required
                            </div>
                        </div>
                    </div>

                    <div *ngIf="!callDetail.internal" class="col-lg-6 col-md-6 col-sm-12">
                        <!-- *** TODO: For required fields, please add .required class to the label *** -->
                        <div class="tondogrigio" style="position:absolute">4</div>
                        <label style="margin-left:25px" id="readiness_label" for="readiness"
                            class="a-form-label text-uppercase required"
                            [ngClass]="{ 'mb-0' : crudMode == crudModeType.READ }" translate>Indicate company's
                            Technology Readiness Level</label>
                        <button style="background-color: #CFCFCF;width: 30px;height: 35px;font-size: 20px;"
                            (click)="addReadiness()">+</button>
                        {{readiness}}
                        <button style="background-color: #CFCFCF;width: 30px;height: 35px;font-size: 20px;"
                            (click)="minusReadiness()">-</button>
                        <div *ngIf="(f.readiness.dirty || f.readiness.touched) && f.readiness.errors && crudMode != crudModeType.READ"
                            class="error-message">
                            <!-- *** TODO: Add or remove validators if needed *** -->
                            <div *ngIf="f.readiness.errors.required" translate>call.form.readiness.errors.required
                            </div>
                        </div>
                    </div>

                    <div *ngIf="callDetail.internal" class="col-lg-6 col-md-6 col-sm-12">
                        <!-- *** TODO: For required fields, please add .required class to the label *** -->
                        <div class="tondogrigio" style="position:absolute">4</div>
                        <label style="margin-left:25px" id="tecnologia_label" for="departmentBelonging"
                            class="a-form-label text-uppercase required"
                            [ngClass]="{ 'mb-0' : crudMode == crudModeType.READ }" translate>Department beloging</label>
                        <input required id="tecnologia" type="text" formControlName="departmentBelonging"
                            class="a-text-input" autocomplete="off"
                            placeholder="{{ crudMode == crudModeType.READ ? '' : ('general.inserttext' | translate )}}"
                            [ngClass]="{ 'a-input-error': (f.departmentBelonging.dirty || f.desfaAdvantages.touched) && f.departmentBelonging.errors && crudMode != crudModeType.READ,
         'form-control-plaintext pl-0 pt-0 no-box-shadow' : crudMode == crudModeType.READ }"
                            [readonly]="crudMode == crudModeType.READ" />
                        <div *ngIf="(f.departmentBelonging.dirty || f.departmentBelonging.touched) && f.departmentBelonging.errors && crudMode != crudModeType.READ"
                            class="error-message">
                            <!-- *** TODO: Add or remove validators if needed *** -->
                            <div *ngIf="f.departmentBelonging.errors.required" translate>
                                call.form.departmentBelonging.errors.required
                            </div>
                        </div>
                    </div>
                </div>
                <hr *ngIf="!callDetail.internal">

                <div *ngIf="!callDetail.internal" class="row mb-2">
                    <div class="col-lg-6 col-md-6 col-sm-12">
                        <div class="tondogrigio" style="position:absolute">5</div>
                        <label style="margin-left:25px" id="readiness_label" for="readiness"
                            class="a-form-label text-uppercase" [ngClass]="{ 'mb-0' : crudMode == crudModeType.READ }"
                            translate>Number of filled/submitted
                            patents and description</label>
                    </div>
                </div>

                <div class="row mb-2" *ngFor="let item of [].constructor(numeroFasi);let i = index">
                    <!-- *** TODO: Insert fields here *** -->
                    <!-- *** FIELD titolo STARTS *** -->
                    <div *ngIf="!callDetail.internal" class="col-lg-4 col-md-4 col-sm-12">
                        <!-- *** TODO: For required fields, please add .required class to the label *** -->

                        <label id="titolo_label" for="titolo" class="a-form-label text-uppercase required"
                            [ngClass]="{ 'mb-0' : crudMode == crudModeType.READ }" translate>Patent</label>
                        <input id="titolo{{i}}" type="text" class="a-text-input" autocomplete="off"
                            placeholder="{{ crudMode == crudModeType.READ ? '' : ('general.inserttext' | translate )}}"
                            [readonly]="crudMode == crudModeType.READ" />

                    </div>

                    <div *ngIf="!callDetail.internal" class="col-lg-6 col-md-6 col-sm-12">
                        <!-- *** TODO: For required fields, please add .required class to the label *** -->
                        <label id="titolo_label" for="titolo" class="a-form-label text-uppercase required"
                            [ngClass]="{ 'mb-0' : crudMode == crudModeType.READ }" translate>Description</label>
                        <input id="descrizione{{i}}" type="text" class="a-text-input" autocomplete="off"
                            placeholder="{{ crudMode == crudModeType.READ ? '' : ('general.inserttext' | translate )}}"
                            [readonly]="crudMode == crudModeType.READ" />

                    </div>
                    <div *ngIf="!callDetail.internal" class="col-lg-2 col-md-2 col-sm-12" style="margin-top:30px">
                        <button class="buttonInnoHub_inverse" (click)="eliminaPatent(i)">Delete</button>
                    </div>
                </div>




                <div *ngIf="!callDetail.internal" class="row mb-2">
                    <div class="col-lg-12 col-md-12 col-sm-12">
                        <button style="background-color: transparent;color:#20376C" (click)="aggiungiBrevetto();">+
                            Add patent</button>
                    </div>
                </div>

                <hr>


                <div class="row">
                    <div class="row mb-2">
                        <div class="col-lg-6 col-md-6 col-sm-12">
                            <div *ngIf="!callDetail.internal" class="tondogrigio" style="position:absolute">6</div>
                            <div *ngIf="callDetail.internal" class="tondogrigio" style="position:absolute">5</div>
                            <label style="margin-left:25px" id="readiness_label" for="cvs"
                                class="a-form-label text-uppercase required"
                                [ngClass]="{ 'mb-0' : crudMode == crudModeType.READ }" translate>Attachment</label>
                        </div>
                        <!-- <div *ngIf="callDetail.internal" class="col-lg-6 col-md-6 col-sm-12">
                            <div class="tondogrigio" style="position:absolute">5</div>
                            <label style="margin-left:25px" id="readiness_label" for="cvs"
                                class="a-form-label text-uppercase required"
                                [ngClass]="{ 'mb-0' : crudMode == crudModeType.READ }" translate>Attachment</label>
                        </div> -->
                    </div>

                    <div class="row mb-2" *ngFor="let item of [].constructor(numeroCV);let i = index">
                        <!-- *** TODO: Insert fields here *** -->
                        <!-- *** FIELD titolo STARTS *** -->
                        <div class="col-lg-6 col-md-6 col-sm-6">
                            <div style="border:1px dashed #203570;padding:10px">
                                <input required type="file" id="cv{{i}}" (change)="onCVUpload($event,i)" class="hidden">
                                <label for="cv{{i}}"
                                    style="border:1px solid #203570;padding-top:5px;padding-bottom:5px;padding-right: 10px;padding-left:10px;font-size: 14px;border-radius: 5px;"><img
                                        src="../../../../../../assets/images/upload.png">&nbsp;Add CV</label>
                                <span *ngIf="cvFile">{{cvFile[i]?.name}}</span>
                                <span>
                                    <label *ngIf="cvFileWarnings[i]" class="error-message"> File attached exced maximun
                                        file
                                        size (10mb)</label>
                                </span>
                            </div>
                        </div>
                        <div class="col-lg-2 col-md-2 col-sm-12" style="margin-top:10px">
                            <button class="buttonInnoHub_inverse" (click)="eliminaCV(i)">Delete</button>
                        </div>
                    </div>
                    <div class="row mb-2">
                        <div class="col-lg-12 col-md-12 col-sm-12">
                            <button type="button" style="background-color: transparent;color:#20376C"
                                (click)="aggiungiCV();">+
                                Add CV</button>
                        </div>
                    </div>
                </div>

                <div class="">

                </div>

                <hr *ngIf="!callDetail.internal">







                <div *ngIf="!callDetail.internal" class="row mb-2 mt-5">
                    <!-- *** TODO: Insert fields here *** -->
                    <!-- *** FIELD titolo STARTS *** -->
                    <div class="col-lg-6 col-md-6 col-sm-12" [ngClass]="{'' : isDesktop , 'mb-4' : !isDesktop}">
                        <!-- *** TODO: For required fields, please add .required class to the label *** -->
                        <div class="tondogrigio" style="position:absolute">7</div>
                        <label style="margin-left:25px" id="progetti_label" for="progetti"
                            class="a-form-label text-uppercase required"
                            [ngClass]="{ 'mb-0' : crudMode == crudModeType.READ }" translate>MAIN PROJECTS OR RESULTS
                            OBTAINED (References)</label>
                        <input required id="progetti" type="text" formControlName="progetti" class="a-text-input"
                            autocomplete="off"
                            placeholder="{{ crudMode == crudModeType.READ ? '' : ('general.inserttext' | translate )}}"
                            [ngClass]="{ 'a-input-error': (f.progetti.dirty || f.progetti.touched) && f.progetti.errors && crudMode != crudModeType.READ,
         'form-control-plaintext pl-0 pt-0 no-box-shadow' : crudMode == crudModeType.READ }"
                            [readonly]="crudMode == crudModeType.READ" />
                        <div *ngIf="(f.progetti.dirty || f.progetti.touched) && f.progetti.errors && crudMode != crudModeType.READ"
                            class="error-message">
                            <!-- *** TODO: Add or remove validators if needed *** -->
                            <div *ngIf="f.progetti.errors.required" translate>call.form.progetti.errors.required
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6 col-sm-12">
                        <!-- *** TODO: For required fields, please add .required class to the label *** -->
                        <div class="tondogrigio" style="position:absolute">8</div>
                        <label style="margin-left:25px" id="dettagliFinanziari_label" for="dettagliFinanziari"
                            class="a-form-label text-uppercase" [ngClass]="{ 'mb-0' : crudMode == crudModeType.READ }"
                            translate>Funding raised (if any)</label>
                        <input id="dettagliFinanziari" type="text" formControlName="dettagliFinanziari"
                            class="a-text-input" autocomplete="off"
                            placeholder="{{ crudMode == crudModeType.READ ? '' : ('general.inserttext' | translate )}}"
                            [ngClass]="{ 'a-input-error': (f.dettagliFinanziari.dirty || f.dettagliFinanziari.touched) && f.dettagliFinanziari.errors && crudMode != crudModeType.READ,
         'form-control-plaintext pl-0 pt-0 no-box-shadow' : crudMode == crudModeType.READ }"
                            [readonly]="crudMode == crudModeType.READ" />
                    </div>
                </div>
                <hr>
                <div *ngIf="!callDetail.internal" class="row mb-2 mt-5">
                    <div class="col-lg-6 col-md-6 col-sm-6" [ngClass]="{'' : isDesktop , 'mb-4' : !isDesktop}">
                        <div class="tondogrigio" style="position:absolute">9</div>
                        <label style="margin-left:25px" class="a-form-label text-uppercase required"
                            [ngClass]="{ 'mb-0' : crudMode == crudModeType.READ }" translate>Pitch Deck</label>


                        <div style="border:1px dashed #203570;padding:10px">
                            <input required type="file" id="pitch" (change)="onPitchUpload($event)" class="hidden">
                            <label for="pitch"
                                style="border:1px solid #203570;padding-top:5px;padding-bottom:5px;padding-right: 10px;padding-left:10px;font-size: 14px;border-radius: 5px;"><img
                                    src="../../../../../../assets/images/upload.png">&nbsp;Add file</label>
                            <span *ngIf="pitchFile">{{pitchFile.name}}</span>
                            <span>
                                <label *ngIf="pitchUploadWarning" class="error-message"> File attached exced maximun
                                    file
                                    size (10mb)</label>
                            </span>
                        </div>


                    </div>
                    <div class="col-lg-6 col-md-6 col-sm-6">
                        <div class="tondogrigio" style="position:absolute">10</div>
                        <label style="margin-left:25px" class="a-form-label text-uppercase"
                            [ngClass]="{ 'mb-0' : crudMode == crudModeType.READ }" translate>Business model upload(If
                            it's not included in the pitch deck)</label>

                        <div style="border:1px dashed #203570;padding:10px">
                            <input type="file" id="business" (change)="onBusinessUpload($event)" class="hidden">
                            <label for="business"
                                style="border:1px solid #203570;padding-top:5px;padding-bottom:5px;padding-right: 10px;padding-left:10px;font-size: 14px;border-radius: 5px;"><img
                                    src="../../../../../../assets/images/upload.png">&nbsp;Add file</label>
                            <span *ngIf="businessFile">{{businessFile.name}}</span>
                            <span>
                                <label *ngIf="businessFileWarning" class="error-message"> File attached exced maximun
                                    file
                                    size (10mb)</label>
                            </span>
                        </div>
                    </div>
                </div>
                <div class="row mb-2 mt-5">
                    <div class="col-12">
                        I accept <a href="../../regulation" target="_blank">rules and regulation</a>
                    </div>
                </div>
                <div class="row" style="margin-left:-10px;margin-top:10px">
                    <div class="col-md-12">
                        <input type="radio" [(ngModel)]="disclaimerRegulation" name="disclaimerRegulation" value="si"
                            [ngModelOptions]="{standalone: true}"> <b> YES</b>
                        <input type="radio" [(ngModel)]="disclaimerRegulation" name="disclaimerRegulation" value="no"
                            style="margin-left: 20px;" [ngModelOptions]="{standalone: true}"><b>
                            NO</b>
                    </div>
                </div>
                <div class="row mb-2 mt-5">
                    <div class="col-12">
                        I accept <a href="../../privacy" target="_blank">privacy policy</a>
                    </div>
                </div>
                <div class="row" style="margin-left:-10px;margin-top:10px">
                    <div class="col-md-12">
                        <input type="radio" [(ngModel)]="disclaimerPrivacy" name="disclaimerPrivacy" value="si"
                            [ngModelOptions]="{standalone: true}"> <b> YES</b>
                        <input type="radio" [(ngModel)]="disclaimerPrivacy" name="disclaimerPrivacy" value="no"
                            style="margin-left: 20px;" [ngModelOptions]="{standalone: true}"><b>
                            NO</b>
                    </div>
                </div>
                <div class="row mb-2 mt-5">

                    <div [ngClass]="{ 'col-6' : isDesktop ,'col-3' : !isDesktop}">
                        <button type="button" ngbAutofocus style="background-color: transparent;color:#203570;"
                            (click)="modal.dismiss('Cancel click')" [translate]="'Cancel'"></button>
                    </div>

                    <div [ngClass]="{ 'col-6' : isDesktop ,'col-9' : !isDesktop}" style="text-align: right;">
                        <button type="button"
                            [ngClass]="{ 'buttonInnoHub_inverse' : isDesktop ,'buttonInnoHub_inverseMobile' : !isDesktop}"
                            (click)="resetFormWithOriginalResourceData();"
                            [disabled]="!resourceForm.dirty || checkFilesValidity()" translate>Save for later</button>
                        &nbsp; <button class="btn" type="submit"
                            [ngClass]="{ 'buttonInnoHub' : isDesktop ,'buttonInnoHubMobile' : !isDesktop}"
                            [disabled]="!resourceForm.valid || !resourceForm.dirty || disclaimerPrivacy!='si' || disclaimerRegulation!='si'
                              || checkFilesValidity() || callDetail.status == 'CLOSED' || (!callDetail.internal && (pitchFile === null || pitchFile === undefined))
                              || (cvFile === null || cvFile.length === 0)"
                            translate>Apply</button>
                    </div>
                </div>


            </form>

        </div>
        <div class="col-1"></div>
    </div>
</div>
