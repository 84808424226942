<app-header></app-header>
<div class="container">
   <!-- <div class="row mt-2">
        <div class="col-lg col-md-12">
            <app-breadcrumbs></app-breadcrumbs>
        </div>
    </div>-->
    <div class="row mt-2">
        <div class="col-lg col-md-12">
            <router-outlet></router-outlet>
        </div>
    </div>
</div>