
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { DatePipe, Location, ViewportScroller } from '@angular/common';
import { Component, HostListener, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { NVNotificationService } from '@nv/core';
import { InternationalizationService } from '@nv/internationalization';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ModalComponent } from 'src/app/components/modal/modal.component';
import { mainConfigs } from 'src/app/configs/main-configs';
import { EEvaluationStep } from 'src/app/enums/EEvaluationStep';
import { Brevetto } from 'src/app/models/brevetto';
import { Call } from 'src/app/models/call';
import { Submission } from 'src/app/models/submission';
import { AuthService } from 'src/app/services/auth.service';
import { BusinessService } from 'src/app/services/crud/business.service';
import { CurriculumService } from 'src/app/services/crud/curriculum.service';
import { PitchService } from 'src/app/services/crud/pitch.service';
import { PrivacyService } from 'src/app/services/crud/privacy.service';
import { RegulationService } from 'src/app/services/crud/regulation.service';
import { SubmissionService } from 'src/app/services/crud/submission.service';
import { environment } from 'src/environments/environment';


@Component({
  selector: 'app-calldetailview',
  templateUrl: './calldetailview.component.html',
  styleUrls: ['./calldetailview.component.scss']
})
export class CallDetailViewComponent implements OnInit {

  isHandsetLandscape: boolean;
  isHandset: boolean;
  public formSubmitted: boolean = false;
  redirectInProgress = false;
  public brevetti: Brevetto[] = [];
  customRedirect: string = undefined;
  public pitchFile: File;
  public businessFile: File;
  public readiness: number = 1;
  public disclaimer: String;
  public statusList = [];
  public adminEvaluationStatus1: string;
  public adminEvaluationStatus2: string;

  public isNavbarCollapsed = true;
  public numeroFasi = 0;

  submissionDetail: Submission;
  callDetail: Call;
  public submissionid;
  isDesktop: boolean = window.innerWidth > 767;


  constructor(
    private viewportScroller: ViewportScroller,
    private regulationService: RegulationService,
    private privacyService: PrivacyService,
    private businessService: BusinessService,
    private crudService: SubmissionService,
    private router: Router,
    public breakpointObserver: BreakpointObserver,
    private route: ActivatedRoute,
    private translate: TranslateService,
    private internationalizationService: InternationalizationService,
    private fb: FormBuilder,
    private notificationService: NVNotificationService,
    private datePipe: DatePipe,
    public authService: AuthService,
    private ngbModalService: NgbModal,
    private ngxloader: NgxUiLoaderService,
    private pitchService: PitchService,
    private curriculumService: CurriculumService,
    private location: Location,

  ) {
    breakpointObserver.observe([
      Breakpoints.Handset,
    ]).subscribe(result => {
      this.isHandset = result.matches;
    });
  }

  ngOnInit() {

    this.ngxloader.start();
    this.submissionid = this.route.snapshot.paramMap.get('submissionid');
    this.crudService.getSubmission(this.submissionid).subscribe(data => {
      this.submissionDetail = data;
      this.callDetail = this.submissionDetail.call;
      this.ngxloader.stop();
    })
    this.initializeStatusEnumList();
    this.checkScreenWidth();
  }

  public initializeStatusEnumList(): void {

    for (const value in Object.values(EEvaluationStep)) {
      if (typeof EEvaluationStep[value] !== "string") {
        continue;
      }
      this.translate.get(EEvaluationStep[value]).subscribe(translated => {
        this.statusList.push({
          value: EEvaluationStep[value], translation: translated
        })
      })
    }
  }





  availableLanguages(): string[] {
    return this.translate.getLangs();
  }

  changeLanguage(language: string) {
    this.internationalizationService.changeLanguage(language);
    window.location.reload();
  }

  currentLanguage(): string {
    return this.translate.currentLang;
  }

  mysubmission() {
    this.router.navigate(['/secure/mysubmission']);
  }

  securecontact() {
    this.router.navigate(['/secure/contact']);
  }

  login() {
    this.router.navigate(['/login']);
  }

  logout(): void {
    const modalRef = this.ngbModalService.open(ModalComponent);
    modalRef.componentInstance.message = this.translate.instant('logout.modal.message');
    modalRef.componentInstance.title = this.translate.instant('logout.modal.title');
    modalRef.result.then(result => {
      if (environment.enableOpenAM) {
        this.authService.openAMLogout();
      } else {
        this.authService.logout().subscribe((response) => {
          this.router.navigateByUrl(mainConfigs.loginURL);
        });
      }
    }).catch(() => {

    });
  }

  aggiungiBrevetto() {
    this.numeroFasi = this.numeroFasi + 1;
  }

  eliminaPatent(i: number) {
    this.numeroFasi = this.numeroFasi - 1;
  }


  addReadiness() {
    this.readiness = this.readiness + 1;
  }

  minusReadiness() {
    this.readiness = this.readiness - 1;
  }

  downloadRegulation(regulation: any) {

    this.ngxloader.start();

    this.regulationService.download(regulation.id).subscribe(res => {
      this.ngxloader.stop();
      let url = window.URL.createObjectURL(res);
      let a = document.createElement("a");
      document.body.appendChild(a);
      a.setAttribute("style", "display: none");
      a.href = url;
      a.download = regulation.nome
      a.click();
      window.URL.revokeObjectURL(url);
      a.remove();
    },
      error => {
        this.ngxloader.stop();
        this.notificationService.showError("Error downloading file! Contact the help desk")
      });
  }

  downloadPrivacy(privacy: any) {

    this.ngxloader.start();

    this.privacyService.download(privacy.id).subscribe(res => {
      this.ngxloader.stop();
      let url = window.URL.createObjectURL(res);
      let a = document.createElement("a");
      document.body.appendChild(a);
      a.setAttribute("style", "display: none");
      a.href = url;
      a.download = privacy.nome
      a.click();
      window.URL.revokeObjectURL(url);
      a.remove();
    },
      error => {
        this.ngxloader.stop();
        this.notificationService.showError("Error downloading file! Contact the help desk")
      });
  }


  downloadPitch(pitchid: string, pitchname: string) {

    this.ngxloader.start();

    this.pitchService.download(pitchid).subscribe(res => {
      this.ngxloader.stop();
      let url = window.URL.createObjectURL(res);
      let a = document.createElement("a");
      document.body.appendChild(a);
      a.setAttribute("style", "display: none");
      a.href = url;
      a.download = pitchname
      a.click();
      window.URL.revokeObjectURL(url);
      a.remove();
    },
      error => {
        this.ngxloader.stop();
        this.notificationService.showError("Error downloading file! Contact the help desk")
      });
  }

  downloadCV(cvid: string, cvname: string) {

    this.ngxloader.start();

    this.curriculumService.download(cvid).subscribe(res => {
      this.ngxloader.stop();
      let url = window.URL.createObjectURL(res);
      let a = document.createElement("a");
      document.body.appendChild(a);
      a.setAttribute("style", "display: none");
      a.href = url;
      a.download = cvname
      a.click();
      window.URL.revokeObjectURL(url);
      a.remove();
    },
      error => {
        this.ngxloader.stop();
        this.notificationService.showError("Error downloading file! Contact the help desk")
      });
  }


  downloadBusiness(businessid: string, businessname: string) {

    this.ngxloader.start();

    this.businessService.download(businessid).subscribe(res => {
      this.ngxloader.stop();
      let url = window.URL.createObjectURL(res);
      let a = document.createElement("a");
      document.body.appendChild(a);
      a.setAttribute("style", "display: none");
      a.href = url;
      a.download = businessname
      a.click();
      window.URL.revokeObjectURL(url);
      a.remove();
    },
      error => {
        this.ngxloader.stop();
        this.notificationService.showError("Error downloading file! Contact the help desk")
      });
  }


  gotoanchor(elementId: string) {
    this.viewportScroller.scrollToAnchor(elementId);
  }

  updateSubmission() {
    this.ngxloader.start();
    let submission: Submission = new Submission();
    submission.id = this.submissionid;
    submission.evaluationStatus1 = this.adminEvaluationStatus1;
    submission.evaluationStatus2 = this.adminEvaluationStatus2;
    this.crudService.updateSubmission(submission).subscribe();
    this.notificationService.showSuccess(this.translate.instant('Submission successful updated'))
    this.ngxloader.stop();
  }


  checkScreenWidth() {
    this.isDesktop = window.innerWidth > 767;
  }

  @HostListener('window:resize', ['$event'])
  onResize(event: any) {
    this.checkScreenWidth();
  }

  goBack() {
    this.location.back();
  }

}
