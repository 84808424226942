import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { mainConfigs } from 'src/app/configs/main-configs';
import { AuthService } from 'src/app/services/auth.service';
import { NVAuthService } from '@nv/auth';
import { ProfileService } from 'src/app/services/crud/profile.service';

@Component({
  selector: 'app-reset-password-request',
  templateUrl: './reset-password-request.component.html',
  styleUrls: ['./reset-password-request.component.scss']
})
export class ResetPasswordRequestComponent implements OnInit {
  resetPasswordRequestForm: FormGroup;
  isHandsetLandscape: boolean;
  isHandset: boolean;

  submitted = false;

  constructor(
    private formBuilder: FormBuilder,
    private authService: NVAuthService,
    private profileService: ProfileService,
    private router: Router,
    public breakpointObserver: BreakpointObserver
  ) {
    breakpointObserver.observe([
      Breakpoints.HandsetLandscape,
    ]).subscribe(result => {
      this.isHandsetLandscape = result.matches;
    });
    breakpointObserver.observe([
      Breakpoints.Handset,
    ]).subscribe(result => {
      this.isHandset = result.matches;
    });
  }

  ngOnInit() {
    this.resetPasswordRequestForm = this.formBuilder.group({
      username: ['', Validators.required],
    });

    if (this.authService.getToken()) {
      this.authService.loadContext().subscribe(() => {
        this.router.navigateByUrl(mainConfigs.loginSuccessURL);
        console.log('redirect to:', mainConfigs.loginSuccessURL);
      });
    }
  }


  onSubmit() {
    this.submitted = true;
    if (this.resetPasswordRequestForm.invalid) {
      return;
    }
    this.authService
      .resetPasswordRequest(this.f.username.value)
      .subscribe((response) => {
        this.router.navigateByUrl(mainConfigs.loginSuccessURL);
      });
  }
  // convenience getter for easy access to form fields
  get f() { return this.resetPasswordRequestForm.controls; }
}
