<div fxLayout="row" [fxLayoutAlign]="isHandsetLandscape ? 'center start' : 'center center'"
    style="height: 100%;background-color: #F3F3F3;">
    <div class="login-wrapper" fxFlex="400px" fxFlex.lt-sm="90%">
        <div class="login" id="login">
            <div fxLayout="column" fxLayoutAlign="space-around left" fxLayoutGap="8px">
                <div class="login-logo">
                    <span class="desfa-logo-grid a-lg"></span>
                </div>
                <div class="application-name" *ngIf="!isEnabledOpenAMLogin()" [translate]="'login.form.title'"></div>
            </div>
            <div class="a-loading a-primary" *ngIf="isEnabledOpenAMLogin()"></div>


            <form [formGroup]="loginForm" (ngSubmit)="onSubmit()" class="form-signin" *ngIf="!isEnabledOpenAMLogin()">
                <div class="user-fields">
                    <div class="a-d-flex a-flex-column a-textarea-box">
                        <div style="margin-top: 40px;margin-bottom: 20px;" [translate]="'login.form.data.label'"></div>
                        <input type="text" formControlName="username" id="username" class="a-text-input"
                            placeholder="Username" [ngClass]="{ 'a-input-error': submitted && f.username.errors }" />
                        <input type="password" formControlName="password" id="password" class="a-text-input"
                            placeholder="{{ 'login.form.password.placeholder' | translate }}"
                            [ngClass]="{ 'a-input-error': submitted && f.password.errors }" />
                        <div *ngIf="submitted && f.username.errors && !f.password.errors" class="error-message">
                            <div *ngIf="f.username.errors.required && !f.password.errors?.required"
                                [translate]="'login.form.username.required'"></div>
                        </div>
                        <div *ngIf="submitted && f.password.errors && !f.username.errors" class="error-message">
                            <div *ngIf="f.password.errors.required && !f.username.errors?.required"
                                [translate]="'login.form.password.required'"></div>
                        </div>
                        <div *ngIf="submitted && f.password.errors && f.username.errors" class="error-message">
                            <div *ngIf="f.password.errors.required && f.username.errors.required"
                                [translate]="'login.form.username-and-password.required'"></div>
                        </div>

                    </div>
                    <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutAlign="space-between center"
                        fxLayoutAlign.lt-sm="space-between center" fxLayoutGap="8åpx" class="a-textarea-footer">
                        <div class="login-operation">
                            <a [routerLink]="['../register']" class="forgot-password-btn"
                                [translate]="'login.form.register.button.label'">
                            </a>
                            <a [routerLink]="['../reset-password-request']" class=" forgot-password-btn"
                                [translate]="'login.form.forgot-password.button.label'">
                            </a>
                        </div>
                        <button class="buttonInnoHub" [ngClass]="isHandset && !isHandsetLandscape ? 'btn-block' : ''"
                            [translate]="'login.form.login.button.label'"></button>
                    </div>
                </div>
            </form>
        </div>
        <div style="padding: 20px;
        background-color: #696969;
        color: white;
        font-size: 12px;
        line-height: 12px;border-bottom-left-radius: 10px;border-bottom-right-radius: 10px;">
            Do you need information on the protection of personal data?<br>
            Read the <a style="color:white" href="/privacy" target="_blank">privacy policy</a>
        </div>
    </div>
</div>