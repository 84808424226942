<nav class="navbar navbar-expand-lg navbar-light mb-1 px-3">
    <div class="desfa-logo-grid"></div>
    <span class="divider mx-3"></span>
    <span class="divider ms-3"></span>
    <button class="navbar-toggler hidden-sm-up" type="button" (click)="isNavbarCollapsed = !isNavbarCollapsed"
        data-target="#navbarsDefault" aria-controls="navbarsDefault" aria-expanded="false"
        aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
    </button>
    <div [ngbCollapse]="isNavbarCollapsed" class="collapse navbar-collapse" id="navbarsDefault">
        <span class="ms-auto">
        </span>
    </div>
</nav>

<div class="row" style="margin-top:50px">
    <div class="col-1"></div>
    <div class="col-10">
        <div class="goback" onclick="history.back()" translate><img
                src="../../../../../assets/images/left-chevron.png">&nbsp;go back</div>
        <span style="font-size: 60px;font-weight: 400;">Rules and Regulation</span><br>
        <span style="font-size: 30px;font-weight: 300;">Rules for participating in
            “δesfa+ Acceleration Program”</span><br>

        <div class="col-1"></div>
    </div>

    <div class="row" style="margin-top:50px">
        <div class="col-1"></div>
        <div class="col-10">
            <p style="font-size: 20px;font-weight: 300;">Definition</p>
            &quot;δesfa+ Acceleration Program&quot; (hereinafter also referred to as &quot;acceleration program&quot; or
            &quot;program&quot;) is an open innovation program, promoted by Hellenic Gas Transmission System
            Operator S.A. (hereinafter, also only “DESFA”), as part of the launch of the Vertical
            Corporate Accelerator.
            The Vertical Corporate Accelerator enables the implementation of corporate open innovation
            strategy. It represents an enabling/complementary factor for all innovation activities within
            the Company, such as Digital Transformation initiatives or implementation of new
            technologies and tools to support the business.
            <br><br>
            <p style="font-size: 20px;font-weight: 300;">Introduction</p>
            In order to scout and identify beneficiaries, the acceleration program starts with the
            publication of a call for applications for the purpose of enhancing entrepreneurial ideas and
            offer development opportunities to projects proposed by entities, such as startups, students
            associations, natural persons, those indicatively mentioned.
            The acceleration program is implemented through the opening of two types of calls:
            o call4ideas, to search for innovative projects within a broad business area;
            o call4solution, to search for targeted technology solutions for a specific
            business need.
            The business areas/needs concern the field of Information &amp; Communication Technology
            (hereinafter, also only &quot;ICT&quot;) and Fleet &amp; Facility Management (hereinafter, also only
            &quot;FFM&quot;), focusing in particular on:
            ● ICT Digitalization on Business Process
            ● Smart building
            Participation in the program implies full acceptance of and compliance with the instructions
            and provisions contained in this document, as well as with the terms such as set deadlines,
            penalties e.t.c. which are mentioned herein and in the dedicated pages (URL).
            <br><br>
            <p style="font-size: 20px;font-weight: 300;">Art. 1: Aims of the Acceleration Program</p>


            The aim of this program is to actively support the growth process of innovative start-ups and
            other entities, that can support DESFA in implementing its innovation roadmap through the
            execution of Proof of Concepts (hereinafter also referred to as PoC), carried out directly by the
            selected entities in collaboration with DESFA&#39;s Business Unit representatives.
            <br><br>
            <p style="font-size: 20px;font-weight: 300;">Art. 2: Benefits for selected entities</p>


            Participants, as defined in Art. 3 herein, that submit their application to the call(s) that will
            be launched by DESFA from time to time, will be evaluated and selected for access to the
            acceleration program, where they will have the opportunity to develop a PoC financed by
            DESFA, with an established amount and only for the duration of the PoC, as the latter will be
            further defined in the agreement that will be concluded between DESFA and the selected
            Participants, in the context of the development of the PoC (hereinafter “Acceleration
            Agreement”). DESFA will collaborate with the selected Participants and provide access to in-
            house facilities, as the latter may be provided in the Acceleration Agreement.
            <br><br>
            <p style="font-size: 20px;font-weight: 300;">Art. 3: Eligibility Requirements for participating in a call
            </p>

            Participation in a call is open to all established legal entities and/ or associations and/or
            natural persons (hereinafter “the Participants”) that:
            <ul>
                <li> have a registered office and/or principal place of business in an EU country;</li>
                <li> have one or more business solutions that meet the business areas/needs
                    indicated in the call;</li>
                <li>do not have any relationship (branches, subsidiaries, economic activity) with
                    sanctioned countries <span style="font-size: 10px;">(1)</span> (indicatively Russia, Syria, Sudan, Iran, North Korea,
                    Cuba) and/or sanctioned entities and/or persons, during the duration of a
                    sanction regime as the case may be;</li>
                <li> whose members of the governing body, management team and personnel do
                    not have any relationship with any of DESFA personnel, management team
                    or governing body;</li>
                <li> do not have any Politically Exposed Person (PEPs) among the company
                    governing body or management team;</li>
                <li>guarantee that they have the economic stability to complete all phases of the
                    acceleration program (e.g. 12 months),</li>
                <li> whose members of the governing body, management team have not been
                    convicted, fined, investigated or charged for bribery, corruption, fraud,
                    misrepresentation, terrorist offenses, money laundering, improper
                    accounting or other related activities;</li>
                <li>are not subject to court decisions or administrative measures affecting in any
                    way their participation in the call;</li>
                <li> own and/or are entitled to use all the intellectual and industrial property
                    rights in the materials submitted in the context of their application.</li>
            </ul>
                    Desfa reserve the right to contact the company to asking additional information
                    Participants whose proposals concern products/solutions that in any way could be
                    considered as not complying with the applicable legislation and the present Rules and
                    Regulations and/or contain material violating third parties’ rights, positions or claims
                    (including for example, intellectual property and industrial property law rights) and/or not
                    be in line with generally recognised ethical values (including, for example, weapons,
                    pornography, prostitution, human trafficking, organ trafficking, drugs, gambling, etc.), are
                    not eligible and will be excluded from the respective call.
                    The Participants will be requested to submit a duly executed declaration confirming the
                    fulfilment of the eligibility criteria, as per above, and the acknowledgement of DESFA’s Code
                    of Ethics and Anticorruption Policy, using the relevant template on the portal, in the context
                    of submitting their application in an individual call.
                    Furthermore, the Participant will declare if is currently participating in other selection
                    processes and/or acceleration call for tenders.
            <br>
            <span style="font-size: 10px;">1 &quot;&#39;Sanctions&#39; means the economic, financial or trade sanctions
                laws, regulations, embargoes or
                restrictive measures administered, adopted, enacted, enforced or imposed by any of: (a) Greece; (b) the
                European Union; (c) the Security Council of the United Nations (the Council as a whole and not its
                individual members); (d) the United Kingdom; (e) the United States of America; (e) and/or (f) the
                governments and official institutions or agencies of paragraphs (a) to (e) above, including the Office
                of
                Foreign Assets Control of the US Department of Treasury (&quot;OFAC&quot;), the US Department of State,
                the US
                Department of Commerce and Her Majesty&#39;s Treasury.&quot;</span>
            <br><br>
            <p style="font-size: 20px;font-weight: 300;">Art. 4: How to participate in a call</p>
            <p style="font-size: 18px;font-weight: 300;"> Art. 4.1 Deadline and submission method</p>
           
            In the context of the acceleration program, DESFA may open either a call4ideas or a
            call4solution, upon its needs, on its portal, specifying, each time, the targeted business area as
            well as the deadline for the submission.
            Participation in the call(s) is free of charge, and there is no maximum number of projects
            that each Participant can submit per call.
            Applications must be submitted using the form on the portal no later than the deadline for
            submission set in the call.
            DESFA has the right to verify the accuracy and the truth of all the information submitted for
            the proof of eligibility requirements and, at its sole discretion will reject or accept the
            Participant&#39;s application accordingly. Any incomplete application or application that does
            not comply with the rules or specifications set out herein, will not be considered, unless
            clarifications that may be requested by DESFA, at its sole discretion, are provided to DESFA’
            s satisfaction.
            Without prejudice to already signed Acceleration Agreements, DESFA reserves the right to
            interrupt a call that has been launched or the acceleration program in general, at any time, at
            its complete discretion, without assuming any responsibility and/ or incurring any claim for
            damages from the Participants taking part in the program, which by simply registering,
            declare that they have nothing to claim in such an eventuality.
            <br><br>
            <p style="font-size: 18px;font-weight: 300;"> Art. 4.2 Content of the application</p>
           
           
            The application must be submitted in the portal by filling the relevant form with the
            following information, as may be applicable for each Participant, and as may be
            supplemented by DESFA in the context of each call. The path will follow three steps:


            <ul>
            <li>User registration</li>
            o Name<br>
            o Surname<br>
            o Email<br>
            <li> Company registration (after the first access the user needs to insert the company info</li>
            below and answer to the eligibility requirements referring to Art.3)<br>
            o Company name<br>
            o Website<br>
            o Employees number<br>
            o Telephone<br>
            o Address<br>
            o VAT number<br>
            o Incorporation certificate from the General Commercial Registry or equivalent
            document, as per the applicable laws to the country of establishment and
            according to the company legal form.<br>

            <li>Call application</li>
            1. Key Figure’s CVs.<br>
            2. Idea Name<br>
            3. Brief description of the solution and value proposition.<br>
            4. Technology used (if used third parties SW and HW).<br>
            5. Pitch deck.<br>
            6. Number of filed/submitted patents and patent description.<br>
            7. Main projects or achievements.<br>
            8. Funding raised (if any).<br>
            9. Business model.<br>
            10. Technology Readiness Level (TRL).<br>

        <li> Any additional presentation documents of the Participant or product/solution deemed
            useful for the evaluation by the evaluation committee, as per Article 5 below.</li>
        </ul>

            The submission of the application and related documents is considered as declaration by the
            Participant that such information is true and accurate.
            Referring to Participants’ information indicated under User and Company registration steps,
            it is noted that such input will be archived by DESFA in a registry, created specifically for the
            purposes of the Acceleration Program, in order to facilitate the Participants who will not need
            to resubmit them, in case of participating in future calls, as long as they are up to date.
            <br><br>
            <p style="font-size: 20px;font-weight: 300;">Art. 5: Selection method</p>

            Applications must be strictly submitted within the deadline set out in each specific call to be
            launched by DESFA from time to time, as per Article 4.1 of these Rules and Regulations, by
            filling in the application form indicated in Art. 4.2 above.
            The assessment of the Participants&#39; eligibility (Art. 3) and fulfilment of the participation
            requirements and their admission to the selection, will be carried out at the sole discretion of
            DESFA, being committed to the highest standards of transparency and impartiality.
            Selection will be carried out by an evaluation team comprised of DESFA professionals, freely
            appointed by DESFA on an ad hoc basis, depending on the nature of the targeted business
            area to be defined per call and assuring that no conflict of interest exists according to Desfa’s



            internal procedures.
            DESFA reserves the right to expand the deadlines of a call and to change the present Rules
            and Regulations at any time, undertaking to communicate any updates online, at its
            discretion, to be available to all Participants, without assuming any responsibility and/or
            incurring any claim for damages from the Participants having submitted an application or
            envisaging to submit an application in the program, as a result thereof. The change of the
            Present Rules and Regulations will not be applicable to active calls that have not been
            completed by the time of such a change, unless there is a change in law, in which case the
            latter applies directly, even to active calls.
            Any requests for clarification and/or further information, must be addressed exclusively
            through the contact form in the Innovation portal.
            <br><br>
            <p style="font-size: 18px;font-weight: 300;"> Art. 5.1 Selection criteria</p>
           
            Applications will be assessed both on the quality of the material submitted and on its
            consistency with the objectives/targeted business area of the call. In addition, the
            completeness of the set of information requested at the application stage, will be taken into
            account.
            As an example, but not limited to, the following criteria will be assessed for each Participant:
            <ul>
            <li> Innovativeness of the solution: a Participant has to propose a solution with an
            appropriate level of innovation.</li>
            <li> Degree of coverage of the expressed business need: will be evaluated the
            responsiveness to the business needs.</li>
            <li> Maturity of the business model: will be evaluated how the startup business model
            is market- ready.</li>
            <li> Scalability: will be evaluated how scalable the solution is, referring to a business that
            can increase its size, its customers and its volume of business and referring to the
            ability of an infrastructure to enable the management of any increases flexibly and
            dynamically in operational load.</li>
            <li> Team&#39;s skills: will be evaluated the startup team experiences and competences.</li>
            <li> Technical feasibility: will be evaluated the feasibility in terms of financial
            sustainability and in terms of resources, infrastructure and services needed to the
            implementation.</li>
        </ul>

            It is additionally noted that the evaluation of applications may also take into consideration
            any criteria, other than those listed above in Art. 5.2 Selection Criteria, as the latter may be
            provided in the context of each call, according to Desfa’s internal procedures.
        <br><br>
            <p style="font-size: 18px;font-weight: 300;"> Art. 5.2 Individual stages</p>
            
            Stage 1: Receipt of applications<br>
            The Participant submits its application using the form on the portal no later than the deadline,
            that will be stipulated in the context of each individual call to be opened by DESFA from time
            to time.
            <br><br>
            Stage 2: Selection<br>
            DESFA will screen the applications, assessing the fulfilment of the eligibility and selection
            criteria of the call, to select the start-ups or other entities that will be eligible for the
            acceleration program. At the end of this stage selected entities will present their solution
            during the Pitch Day.


            <br><br>
            Stage 3: Program launch<br>
            The selected start-ups or other entities will be notified of the positive outcome of DESFA&#39;s
            decision via email and will be called to initiate the dialogue for the commencement of the
            acceleration program and related activities through the development of a PoC.
            Further on, an Acceleration Agreement shall be executed between the parties, setting forth
            specific technical, operational, commercial terms for the development of the PoC, including
            the financing of the latter, in line with DESFA’s policies and internal procedures.
            <br><br>
            Stage 4: Demo Day<br>
            At the end of the program, the selected participants will present the PoC carried out and the
            results achieved during the Demo Day.

            <br><br>
            <p style="font-size: 20px;font-weight: 300;">Art. 6: Confidentiality</p>

            When participating in the call(s) and submitting applications, Participants may disclose
            confidential information/materials. The internal DESFA and external people who, for
            reasons related to the management of this initiative are involved, undertake to treat these
            information/materials with the utmost care and confidentiality, use them for purposes
            strictly related to this program and not to disclose them without the prior consent of the
            Participants, provided that it has been previously identified by a Participant as
            &quot;confidential&quot;.
            To implement confidentiality obligations in detail, Participants acknowledge that they may
            be required to sign a specific confidentiality agreement, aimed at regulating mutual
            safeguards in terms of sharing confidential information and expertise.
            <br><br>
            <p style="font-size: 20px;font-weight: 300;">Art. 7: Processing of Personal Data</p>

            DESFA, in its capacity as Data Controller as per the EU Regulation 2016/679 (General Data
            Protection Regulation), hereafter GDPR, shall process personal data of the Participants and
            of the data subjects related to them, in particular categories of data referred to under Art. 3,
            4, 5 herein, to enable participation in the program, and for purposes connected with legal
            obligations.
            The data will be processed according to the principles and rules of the GDPR and the
            regulations related thereto. Participants declare that they have lawfully collected from data
            subjects and lawfully transfer such data to DESFA in relation to the program. The data will
            be processed by the personnel necessary for the management of the program and may be
            transferred to DESFA’s vendors that support its organisation and execution, such as IT
            service providers, consultants etc.
            DESFA shall retain and process the personal data only for the period required to pursue the
            purposes described above and at most for one (2) year after the end of the year of the closure
            of the call.
            Participants and data subjects related to them may exercise their rights under Articles 15 and


            following of the GDPR at any time. For more information regarding the processing of
            personal data as well as to exercise such rights, data subjects may contact our Data
            Protection Officer (DPO) via the following email dpo@desfa.gr.
            Consent to data processing is a prerequisite for participation in the program. In case of
            absence of consent by the respective data subjects, Participants will not be able to participate
            in the call for entries to the acceleration program. When submitting its application, each
            Participant (data subject) will be asked to provide their consent and authorisation to DESFA,
            without any remuneration being due, to use its name, surname and any description of its
            project for purposes relating to promotional campaigns, internal and external advertising
            relating to the program by any means, including but not limited to DESFA&#39;s website through
            press releases and any other means of communication to the public, for an unlimited number
            of reproductions and distribution worldwide. In the same sense, Participants (data subjects)
            will be informed and asked to provide their consent and authorization to DESFA, without
            any remuneration being due in this regard, to acquire and record its image and/or voice, by
            means of photos or videos, during the program and to advertise the program with its image
            and/or voice, event and to copy, reproduce and distribute its image in all communication
            channels, in any way and by any means of communication.
            <br><br>
            <p style="font-size: 20px;font-weight: 300;">Art. 8: Intellectual property</p>

            By participating in this program, each Participant is always fully responsible for the
            protection of its intellectual property as part of its participation in the program. In addition,
            each Participant declares that each proposed idea is original and does not infringe, in any
            way, either in whole or in part, any third party&#39;s intellectual or industrial property rights,
            releasing DESFA from any liability, claims for damages and/or legal action brought by third
            parties in this regard. In the event of a breach of the provisions of this article, the Participant,
            or the participating team, will be excluded from the program. The intellectual and/or
            industrial property rights to the submitted ideas belong to the individual participants. Any
            further rights arising from the collaboration between DESFA and the participants during the
            acceleration program and in particular in the context of the development of the PoC belong
            to both, as the latter will be further defined in the Acceleration Agreement. If at the stage of
            the development of the PoC the Participants decide to submit a patent application, DESFA
            shall have the right to claim co-ownership in accordance with the provisions of the law. In
            case DESFA is not interested in such a patent application, the Participant may proceed on its
            own and DESFA shall provide reasonable assistance in this regard.
            <br><br>
            <p style="font-size: 20px;font-weight: 300;">Art. 9: Liability</p>

            Throughout the duration of the acceleration program, Participants must ensure, under their
            own responsibility, the security of their data and any software used, including against
            possible cyber-attacks.
            DESFA assumes no responsibility for any kind of problems, including technical ones, that
            may result to the inability of the Participant to register and/or upload material in the context
            of submitting its application to the program.
            Participants declare that they are aware that they are solely and exclusively liable for any
            kind of responsibility concerning or arising from the contents of the projects delivered, for
            the purpose of taking part in the call and, more generally, in the program, and that they


            undertake to indemnify and hold harmless DESFA and all those involved in the program in
            any way from any claim, demand for compensation or request for damages to property and/
            or persons made by third parties, having been caused by the Participants and/or third
            parties, including those relating to breaches of the provisions contained in these Regulations.
            DESFA and all parties in any way engaged by DESFA in the program reaffirm their non-
            involvement in any act or behaviour carried out during the program itself by the Participants.

            <br><br>
            <p style="font-size: 20px;font-weight: 300;">Art. 10: Participants’ Representations, Warranties and
                Obligations</p>

            Participants in the program acknowledge and unconditionally accept the present Rules and
            Regulations to full extent. In case of changes hereto, participants shall acknowledge and
            accept that the new Rules and Regulations will be valid as of the date that they will be
            published at DESFA’s portal without prejudice to their right to withdraw their participation
            in the program at hand.
            Participants undertake not to engage in any type of conduct, action or behaviour that is
            potentially offensive or damaging to DESFA&#39;s business reputation and honour. Participants
            acknowledge OWNER’s Code of Ethics (the “Code”), as published on DESFA’s site, and
            declare that they shall comply with the relevant provisions of the Code, including conflicts of
            interest, and shall ensure compliance with all applicable Laws, rules and regulations related
            thereto. They further acknowledge DESFA’s Anticorruption Policy, as published on DESFA’s
            site, and that they shall comply with its principles; they undertake no to violate or knowingly
            permit their employees or persons that in any case act on its behalf, to violate the Code’s and
            the above Policy’s prohibition on bribery or any policies and procedures related thereto in
            performing under the program. The Participants further undertake to adopt, effectively
            implement, and maintain for the entire duration of the program measures to prevent such an
            occurrence. Furthermore, during the program, the Participants undertake to communicate to
            DESFA via the appropriate channel as per DESFA’s Whistleblowing Policy
            (tell.us@desfa.gr), any behaviour attributable to its employees or persons that in any case act
            on its behalf, as well as any behaviour on the part of DESFA, its people and the parties
            engaged by it in the organisation and/or management of the program, in violation of this
            article.
            DESFA may immediately terminate or suspend participation of the involved Participant in
            the program if the Participant breaches this Article. In the event of a breach of this Article,
            the participating start-ups undertake to indemnify and hold DESFA harmless from any
            action and/or claim, including claims for compensation, and/or claims for damages, made
            against it by third parties.
            Participants also undertake to comply with the applicable legal provisions on market abuse,
            in accordance with the provisions of the Greek Law No. 4443/2016 Part B, its implementing
            regulations and subsequent amendments and additions, and EU Regulation 596/14.
            <br><br>
            <p style="font-size: 20px;font-weight: 300;">Art. 11: Miscellaneous</p>
            
            DESFA shall not be held responsible for any misunderstandings or delays or other events
            that prevent the timely receipt of the documentation within the terms described.
            DESFA and all parties involved in any way in the organisation and/or management of the
            program acknowledge that they are not involved in any act and/or behaviour that the
            Participants adopt and/or engage in during the entire duration of the program, which may



            be considered a breach of the applicable legal provisions.
        </div>
    </div>
</div>