import { AfterContentInit, Attribute, Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, ViewEncapsulation } from '@angular/core';
import { NotificationConfig } from './notification-config';

@Component({
  selector: 'app-notification',
  // templateUrl: './notification.component.html',
  encapsulation: ViewEncapsulation.None,
  host: {
    'role': 'alert',
    '[attr.aria-live]': 'ariaLive',
    'aria-atomic': 'true',
    '[class.notification]': 'true',
    '[class.show]': 'true',
    '[class.autohide]': 'autohide',
  },
  template: `
     <div class="notification-content">
       <ng-content></ng-content>
     </div>
   `,
  styleUrls: ['./notification.component.scss']
})

export class NotificationComponent implements AfterContentInit, OnChanges {
  private _timeoutID;

  /**
   * Delay after which the notification will hide (ms).
   * default: `500` (ms) (inherited from NotificationComponent)
   */
  @Input() delay: number;

  /**
   * Auto hide the notification after a delay in ms.
   * default: `true` (inherited from NotificationComponent)
   */
  @Input() autohide: boolean;

  /**
   * An event fired immediately when notification's `hide()` method has been called.
   * It can only occur in 2 different scenarios:
   * - `autohide` timeout fires
   * - user clicks on a closing cross (&times)
   *
   * Additionally this output is purely informative. The notification won't disappear. It's up to the user to take care of
   * that.
   */
  @Output('hide') hideOutput = new EventEmitter<void>();

  constructor(@Attribute('aria-live') public ariaLive: string, config: NotificationConfig) {
    if (this.ariaLive == null) {
      this.ariaLive = config.ariaLive;
    }
    this.delay = config.delay;
    this.autohide = config.autohide;
  }

  ngAfterContentInit() { this._init(); }

  ngOnChanges(changes: SimpleChanges) {
    if ('autohide' in changes) {
      this._clearTimeout();
      this._init();
    }
  }

  hide() {
    this._clearTimeout();
    this.hideOutput.emit();
  }

  private _init() {
    if (this.autohide && !this._timeoutID) {
      this._timeoutID = setTimeout(() => this.hide(), this.delay);
    }
  }

  private _clearTimeout() {
    if (this._timeoutID) {
      clearTimeout(this._timeoutID);
      this._timeoutID = null;
    }
  }

}
