
import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './regulation.component.html',
  styleUrls: ['./regulation.component.scss']
})
export class RegulationComponent implements OnInit {
 
  public isNavbarCollapsed = true;
  isHandsetLandscape: boolean;
  isHandset: boolean;

  constructor(
   
  ) {
   
  }

  ngOnInit() {
   
  }
}
