import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, TemplateRef } from '@angular/core';
import { NVNotificationService } from '@nv/core';

@Component({
  selector: 'app-notification-container',
  templateUrl: './notification-container.component.html',
  styleUrls: ['./notification-container.component.scss'],
  animations: [
    trigger('EnterLeave', [
      state('flyIn', style({ transform: 'translate3d(0, 0, 0)' })),
      transition(':enter', [
        style({ transform: 'translate3d(0, -100%, 0)' }),
        animate('0.3s ease-in')
      ]),
      transition(':leave', [
        animate('0.3s ease-out', style({ transform: 'translate3d(0, -100%, 0)' }))
      ])
    ])
  ]
})
export class NotificationContainerComponent {
  constructor(public notificationService: NVNotificationService) { }

  isTemplate(notification) { return notification.textOrTpl instanceof TemplateRef; }
}