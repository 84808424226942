// *** class name is CallResolver
// *** entity name is Call
// *** TODO: please check that everything is correct

import { Injectable } from '@angular/core';
import { ActivatedRoute, Router, ActivatedRouteSnapshot, RouterStateSnapshot, Resolve } from '@angular/router';
import { NVPathVariableResourceResolver } from '@nv/core';
import { Call } from '../models/call';
import { CallService } from '../services/crud/call.service';
import { mergeMap } from 'rxjs/operators';
import { Observable, of, from } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

// *** TODO: insert the resolver in the providers section of the main module ***

@Injectable()
export class CallResolver extends NVPathVariableResourceResolver<Call> {
    constructor(
        private crudService: CallService,
        private router: Router,
        private route: ActivatedRoute
    ) {
        super(crudService, router, route);
    }
}
@Injectable({ providedIn: 'root' })
export class CallBreadcrumbResolver implements Resolve<string> {
        constructor(protected CallResolver: CallResolver, protected translateService: TranslateService) { }

        resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<string> {
                const solvedMessage = this.CallResolver.resolve(route) as unknown as Observable<Call>;
                const label = route.data.breadcrumbLabel;

            return solvedMessage.pipe(
                mergeMap(
                        value => {
                                return of(this.translateService.instant(label, { Call: value }));
                                })
                );
        }
}