<div fxLayout="row" [fxLayoutAlign]="isHandsetLandscape ? 'center start' : 'center center'"
  style="height: 100%;background-color: #F3F3F3;">
  <div class="login-wrapper" fxFlex="450px" fxFlex.lt-sm="90%">
    <div class="login" id="login">
      <div class="row">

        <div class="col-12" style="text-align: left;">
          <span class="desfa-logo-grid a-lg"></span>
        </div>
        <div class="application-name" style="line-height: 1;">Welcome to the</div>
        <div class="application-name" style="line-height: 1;">innovation platform</div>
        <!--<div class="application-text"  [translate]="'register.form.subtitle'"></div>-->


      </div>
      <form [formGroup]="loginForm" (ngSubmit)="onSubmit()" class="form-signin" style="margin-top: 20px;">
        <div class="user-fields">
          <div class="a-d-flex a-flex-column a-textarea-box">


            <div class="mb-3">
              <input required type="text" formControlName="firstName" [(ngModel)]="firstName" id="firstName"
                class="a-text-input" placeholder="{{ 'login.form.firstname.placeholder' | translate }}"
                [ngClass]="{ 'a-input-error': submitted && f.firstName.errors }" />

              <div *ngIf="f.firstName.errors?.maxlength" class="error-message mb-2">first name cannot be more than 20
                characters.</div>
            </div>


            <div class="mb-3">
              <input required type="text" formControlName="lastName" [(ngModel)]="lastName" id="lastName"
                class="a-text-input" placeholder="{{ 'login.form.lastname.placeholder' | translate }}"
                [ngClass]="{ 'a-input-error': submitted && f.lastName.errors }" />
              <div *ngIf="f.lastName.errors?.maxlength" class="error-message">Last name cannot be more than 20
                characters.</div>
            </div>

            <div class="mb-3">
              <input required type="email" formControlName="email" [(ngModel)]="email" id="email" email
                class="a-text-input" placeholder="{{ 'login.form.email.placeholder' | translate }}"
                [ngClass]="{ 'a-input-error': submitted && f.email.errors }" />

              <div *ngIf="f.email.errors?.email" class="error-message">Email address is not valid.</div>
              <div *ngIf="f.email.errors?.maxlength" class="error-message">Email cannot be more than 50 characters.
              </div>
            </div>






            <!-- <input required type="password" formControlName="password" [(ngModel)]="password" id="password" class="a-text-input"
                            placeholder="{{ 'login.form.password.placeholder' | translate }}"
                            [ngClass]="{ 'a-input-error': submitted && f.password.errors }" />


                        <input required type="password"  id="confirmpassword" class="a-text-input" formControlName="confirmpassword"
                            placeholder="{{ 'login.form.confirmpassword.placeholder' | translate }}" [ngClass]="{ 'a-input-error': submitted && f.confirmpassword.errors }"/> -->

            <!--x    <div *ngIf="submitted && f.username.errors && !f.password.errors" class="error-message">
                            <div *ngIf="f.username.errors.required && !f.password.errors?.required"
                                [translate]="'login.form.username.required'"></div>
                        </div>-->


            <!-- <div *ngIf="submitted && f.password.errors && !f.username.errors" class="error-message">
                            <div *ngIf="f.password.errors.required && !f.username.errors?.required"
                                [translate]="'login.form.password.required'"></div>
                        </div>
                        <div *ngIf="submitted && f.password.errors && f.username.errors" class="error-message">
                            <div *ngIf="f.password.errors.required && f.username.errors.required"
                                [translate]="'login.form.username-and-password.required'"></div>
                        </div>
                        <div *ngIf="f.password.dirty && loginForm.errors" class="error-message" style="margin-top:15px">
                            Password doesn't match.
                        </div> -->


          </div>
          <div style="text-align: right;">

            <button class="buttonInnoHub" type="submit" [ngClass]="isHandset && !isHandsetLandscape ? 'btn-block' : ''"
              [translate]="'register.form.button.label'" [disabled]="loginForm.invalid"></button>
          </div>
        </div>
      </form>
    </div>
    <div style="padding: 20px;
        background-color: #696969;
        color: white;
        font-size: 12px;
        line-height: 12px;border-bottom-left-radius: 10px;border-bottom-right-radius: 10px;">
      Do you need information on the protection of personal data?<br>
      Read the <a style="color:white" href="/privacy" target="_blank">privacy policy</a>
    </div>
  </div>
</div>