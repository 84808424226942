<div>
    <form [formGroup]="filterForm" (ngSubmit)="onSubmit()">
        <!-- *** TODO: Ensure that there is an <onSubmit()> function in the controller *** -->
        <div class="row mb-2" >
            <div class="col" *ngFor="let filter of filterMap | keyvalue; let i = index;">
                <div [ngSwitch]="filter.value.type">
                  
                    <input *ngSwitchCase="filterType.TEXT" [id]="filter.key" type="text" [formControlName]="filter.key"
                        class="a-text-input"
                        placeholder="{{ getLabelPrefix(filter.key)+'.placeholder' | translate }}" />
                    <span *ngSwitchCase="filterType.DATE" fxLayout="row">
                        <input class="a-text-input" placeholder="{{ getLabelPrefix(filter.key)+'.placeholder' | translate }}" [name]="filter.key"
                            [formControlName]="filter.key" ngbDatepicker #i="ngbDatepicker">
                        <button class="btn-table-icon btn-secondary " (click)="i.toggle()" type="button"><i
                                class="fa fa-calendar"></i></button>
                    </span>
                    <input *ngSwitchCase="filterType.NUMBER" [id]="filter.key" type="number"
                        [formControlName]="filter.key" class="a-text-input"
                        placeholder="{{ getLabelPrefix(filter.key)+'.placeholder' | translate }}" />
                    <select *ngSwitchCase="filterType.RANGE" class="form-control" [formControlName]="filter.key"
                        [name]="filter.key" #i>
                        <option [ngValue]="null">{{getLabelPrefix(filter.key)+'.empty.defaulttext' | translate}}
                        </option>
                        <option *ngFor="let item of filter.value.range" [ngValue]="item.id?item.id:item.value">
                            {{ filter.value.translateValues ? ( item.value | translate ) : item.value }}
                        </option>
                    </select>
                    <input *ngSwitchCase="filterType.BOOLEAN" [id]="filter.key" type="checkbox"
                        [formControlName]="filter.key" />
                </div>
            </div>
        </div>
    </form>
</div>