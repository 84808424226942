<div class="row">
    <div class="col-12">
        <div class="px-2 py-2 shadow-sm bg-white rounded-sm">
            <span *ngFor="let item of breadcrumbList; let i = index; let last = last"
                [class.active]="i===breadcrumbList.length - 1">
                <a [routerLink]="i < breadcrumbList.length - 1 && i>1? cutLastUrlSegment(item.path) : item.path"
                    *ngIf="!last" class="breadcrumb-link" [translate]="item.text">
                </a>
                <span class="breadcrumb-text" *ngIf="last" [translate]="item.text"></span>
                <span class="mx-1" *ngIf="!last">></span>
            </span>
        </div>
    </div>
</div>