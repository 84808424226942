import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/services/auth.service';
import { NVAuthService, NVAuthConfigurations } from '@nv/auth';
import { NVNotificationService } from '@nv/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class HttpResponseInterceptorService implements HttpInterceptor {

  constructor(
    private notificationService: NVNotificationService,
    private configuration: NVAuthConfigurations,
    private translateService: TranslateService,
    private authService: NVAuthService,
    private router: Router
  ) { }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      tap((event: HttpEvent<any>) => { }, (errorResponse: any) => {
        // if it's not an HttpErrorResponse instance, return
        if (!(errorResponse instanceof HttpErrorResponse)) { return; }

        if (request.params.get('onlyValidation') === 'true') { return; }
        // console.log('errorResponse', JSON.parse(errorResponse.error));
        const err = request.responseType === 'text' ? JSON.parse(errorResponse.error) : errorResponse.error;
        // it's a connection problem, show message and return
        if (err.status === 0) {
          this.notificationService.showError('Unable to connect to ' + environment.backendURL);
          return;
        }
        // if there is an error message, manage it
        if (err) {
          switch (err.error) {
            // *** TODO: error handling ***
            case 'AUTH000001':
              this.authService.logout();
              this.notificationService.showError(this.translateService.instant(err.error));
              console.warn('Unauthorized', err);
              break;
            case 'BcVAL0000':
              this.notificationService.showError(this.translateService.instant(err.error));
              break;
            default:
              if (err.status !== undefined) {
                if (err.status === 'UNAUTHORIZED') {
                  this.authService.logout();
                  this.router.navigateByUrl(this.configuration.loginURL);
                }
                this.notificationService.showError(this.translateService.instant(err.error));
                console.warn('An error has occured', err);
              } else {
                console.warn('An error has occured', err);
              }
          }
        } else {
          if (err.message) {
            this.notificationService.showError(this.translateService.instant(err.message));
          } else {
            this.notificationService.showError(this.translateService.instant('Unable to contact the server'));
          }
          console.warn('An error has occured', err);
        }
        return request;
      }));
  }
}
