// *** class name is EventService
// *** entity name is Event
// *** TODO: please check that everything is correct

import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Event, EventType, NVCrudService } from '@nv/core';
import { Evento } from 'src/app/models/evento';
import { EventsService } from '../global/events.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
        providedIn: 'root'
})
export class EventService extends NVCrudService<Evento>{

    constructor(
        private httpClient: HttpClient,
        private eventService: EventsService
    ) {
        super(
            httpClient,
            'api/',
            'event',
            eventService
        )
    }

    getParentResourceURI(pathVariables: Map<string, string>): string {
        // *** TODO: verify how to compose the URI based on the relativity of the entity (if it has parent or not) ***
        // *** EXAMPLE: return environment.backendURL + super.getBaseURI() + "parentEntityName/" + pathVariables.get("parentEntityID") + "/";
        return environment.backendURL + super.getBaseURI();
    }

    getEventFromPublic(): Observable<any> {
        // this.internalNVEventService.emit(new Event(this.getResourceName(), EventType.LOADING));
        return this.httpClient.get<any>(environment.backendURL + 'api/eventhome/')
            .pipe(map(res => {
                return res;
            }));
    }

    getEventDetailFromPublic(callid:any): Observable<any> {
        // this.internalNVEventService.emit(new Event(this.getResourceName(), EventType.LOADING));
        return this.httpClient.get<any>(environment.backendURL + 'api/eventhome/'+callid)
            .pipe(map(res => {
                return res;
            }));
    }

    public createAndUploadFiles(pathVariables: Map<string, string>, resource: Evento, image: File, privacyFile: File, onlyValidation?: boolean): Observable<string> {
      const formData = new FormData();
      formData.append('newEvent', new Blob([JSON.stringify(resource)], { type: 'application/json' }));
      formData.append('image', image, image?.name);
      formData.append('privacy', privacyFile, privacyFile?.name);

      const extraParams: HttpParams = new HttpParams()
        .set('onlyValidation', onlyValidation.toString());

      return this.httpClient
        .post<string>(environment.backendURL + 'api/event/', formData, {
          responseType: 'text' as 'json',
          params: extraParams
        })
        .pipe(map(res => {
          this.eventService.emit(new Event('event', EventType.CREATE, res));
          return res;
        }));
    }

}
