// *** class name is EventSubmissionService
// *** entity name is EventSubmission
// *** TODO: please check that everything is correct

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { NVCrudService } from '@nv/core';
import { EventSubmission } from 'src/app/models/eventSubmission';
import { EventsService } from '../global/events.service';

@Injectable({
        providedIn: 'root'
})
export class EventSubmissionService extends NVCrudService<EventSubmission>{
    
    constructor(
        private httpClient: HttpClient,
        private eventService: EventsService
    ) {
        super(
            httpClient,
            'api/',
            'eventSubmission',
            eventService
        )
    }
    
    getParentResourceURI(pathVariables: Map<string, string>): string {
        // *** TODO: verify how to compose the URI based on the relativity of the entity (if it has parent or not) ***
        // *** EXAMPLE: return environment.backendURL + super.getBaseURI() + "parentEntityName/" + pathVariables.get("parentEntityID") + "/";
        return environment.backendURL + super.getBaseURI();
    }
    
}