import { FasiCall } from "./fasiCall";

export class Call {
    public constructor(init?: Partial<Call>) {
        Object.assign(this, init);
    }
    id: string = '';
    titolo: string = null;
    dataScadenza: Date = null;
    breveDescrizione: string = null;
    status: any = null;
    imageId: string = null;
    privacyId: string = null;
    regulationId: string = null;
    fasi: FasiCall[] = [];
    image: any = null;
    detailImage: any = null;
    regulation: any = null;
    privacy: any = null;
    disclaimer: string = null;
    internal: boolean = false;
}
