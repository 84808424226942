import { Component, Input, OnInit } from '@angular/core';
import { ActionLinkType } from 'src/app/enums/action-link-type.enum';
import { ActionHeader } from 'src/app/interfaces/action';

@Component({
  selector: 'app-actions',
  templateUrl: './actions.component.html',
  styleUrls: ['./actions.component.scss']
})
export class ActionsComponent implements OnInit {
  // tslint:disable-next-line:no-input-rename
  @Input('actions') actions: ActionHeader[];
  actionLinkTypes = ActionLinkType;

  constructor() { }

  ngOnInit() {
  }

}
