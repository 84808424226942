// *** class name is SubmissionService
// *** entity name is Submission
// *** TODO: please check that everything is correct

import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Event, EventType, NVCrudService } from '@nv/core';
import { Submission } from 'src/app/models/submission';
import { EventsService } from '../global/events.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class SubmissionService extends NVCrudService<Submission> {

  constructor(
    private httpClient: HttpClient,
    private eventService: EventsService
  ) {
    super(
      httpClient,
      'api/',
      'submission',
      eventService
    )
  }

  getParentResourceURI(pathVariables: Map<string, string>): string {
    // *** TODO: verify how to compose the URI based on the relativity of the entity (if it has parent or not) ***
    // *** EXAMPLE: return environment.backendURL + super.getBaseURI() + "parentEntityName/" + pathVariables.get("parentEntityID") + "/";
    return environment.backendURL + super.getBaseURI();
  }
  getSubmission(submissionid: string): Observable<Submission> {
    // this.internalNVEventService.emit(new Event(this.getResourceName(), EventType.LOADING));
    return this.httpClient.get<Submission>(environment.backendURL + 'api/submission/' + submissionid + '/')
      .pipe(map(res => {
        return res;
      }));
  }
  updateSubmission(submission: Submission): Observable<Submission> {
    return this.httpClient.put<Submission>(environment.backendURL + 'api/submission/' + submission.id + '/', submission)
      .pipe(map(res => {
        return res;
      }));
  }
  public download(): Observable<any> {
    let params: HttpParams = new HttpParams();
    return this.httpClient.get(environment.backendURL + 'api/submission/download', { params, responseType: 'blob' });

  }

  public createAndUploadFiles(pathVariables: Map<string, string>, resource: Submission, cvFiles: File[], pitchFile: File, businessFile: File, onlyValidation?: boolean): Observable<string> {
    const formData = new FormData();
    formData.append('newSubmission', new Blob([JSON.stringify(resource)], { type: 'application/json' }));

    for (let i = 0; i < cvFiles.length; i++) {
      formData.append('curriculum', cvFiles[i], cvFiles[i].name);
    }
    if (pitchFile) {
      formData.append('pitch', pitchFile, pitchFile?.name);
    }
    if (businessFile) {
      formData.append('business', businessFile, businessFile?.name);
    }

    const extraParams: HttpParams = new HttpParams()
      .set('onlyValidation', onlyValidation.toString());

    return this.httpClient
      .post<string>(environment.backendURL + 'api/submission/', formData, {
        responseType: 'text' as 'json',
        params: extraParams
      })
      .pipe(map(res => {
        this.eventService.emit(new Event('submission', EventType.CREATE, res));
        return res;
      }));
  }
}
