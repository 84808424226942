<div id="notification-container">
    <app-notification *ngFor="let notification of notificationService.notifications"
        [class]="notification.classname + ' a-notification'" [autohide]="true" [delay]="notification.delay || 5000"
        (hide)="notificationService.remove(notification)" [@EnterLeave]="'flyIn'">

        <ng-template [ngIf]="isTemplate(notification)" [ngIfElse]="text">
            <ng-template [ngTemplateOutlet]="notification.textOrTpl"></ng-template>
        </ng-template>

        <ng-template #text>{{ notification.textOrTpl }}</ng-template>
    </app-notification>
</div>