<nav class="navbar navbar-expand-lg navbar-light mb-1 px-3" *ngIf="isDesktop">
    <div style="cursor: pointer;" [routerLink]="['/']" class="desfa-logo-grid md"></div>
    <span class="divider ms-3"></span>
    <button class="navbar-toggler hidden-sm-up" type="button" (click)="isNavbarCollapsed = !isNavbarCollapsed"
        data-target="#navbarsDefault" aria-controls="navbarsDefault" aria-expanded="false"
        aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
    </button>
    <div [ngbCollapse]="isNavbarCollapsed" class="collapse navbar-collapse" id="navbarsDefault">
        <span class="ms-auto">
        </span>
        <ul class="navbar-nav" *ngIf="!authService.isLogged()">
            <button class="buttonInnoHub" (click)="login()">LOGIN</button>
        </ul>
        <ul class="navbar-nav" *ngIf="authService.isLogged()" style="font-size: 14px;">
            <a [ngClass]="isHandset? 'menu-option-mobile' : 'menu-option right-border'">
                <span *ngIf="authService.hasRole('ROLE_USER') && !authService.hasRole('ROLE_ADMIN')"
                    (click)="mysubmission()">
                    <span *ngIf="isHandset" class="appkiticon a-btn-icon icon-survey-outline"></span> My
                    Submissions</span>
                <a [routerLink]="['../../submission/']" *ngIf="authService.hasRole('ROLE_ADMIN')"
                    [ngClass]="isHandset? 'menu-option-mobile' : 'menu-option right-border'">
                    Submissions
                </a>
                <a [routerLink]="['../../profile/']" *ngIf="authService.hasRole('ROLE_ADMIN')"
                    [ngClass]="isHandset? 'menu-option-mobile' : 'menu-option right-border'">
                    Registry
                </a>
                <a [routerLink]="['../../call/']" *ngIf="authService.hasRole('ROLE_ADMIN')"
                    style="color:#474747;text-decoration:none">
                    Initiatives
                </a>
            </a>
            <a (click)="logout()" [ngClass]="isHandset? 'menu-option-mobile' : 'menu-option'">
                <span *ngIf="isHandset" class="appkiticon a-btn-icon icon-log-out-outline"></span> Log out</a>
            <a *ngIf="authService.hasRole('ROLE_USER') && !authService.hasRole('ROLE_ADMIN')" (click)="securecontact()"
                [ngClass]="isHandset? 'menu-option-mobile' : 'btn_contact'">
                <span *ngIf="isHandset" class="appkiticon a-btn-icon icon-help-question-outline"></span> Contact Us</a>
            <a [routerLink]="['../profile/my-profile']" *ngIf="isHandset" class="menu-option-mobile">
                <span class="appkiticon a-btn-icon icon-person-outline"></span> Profile</a>
        </ul>
        <ul class="navbar-nav" *ngIf="!isHandset && authService.isLogged()">
            <li class="nav-item" ngbDropdown>
                <a class="nav-link"
                    style="cursor:pointer; font-size: 14px;
                padding-left: 6px;
                padding-top: 3px;margin-left:5px;width:30px;height:30px;border-radius: 15px;background-color: #2BABE2;color:white"
                    ngbDropdownToggle>
                    {{authService.getAuthContext().user.username.substring(0,2).toUpperCase()}}
                </a>
                <div class="dropdown-menu dropdown-menu-right text-right" ngbDropdownMenu style="left:-125px">
                    <a class="dropdown-item" [routerLink]="['../../profile/my-profile']">
                        <span class="appkiticon a-btn-icon icon-person-outline"></span> Profile</a>
                </div>
            </li>
        </ul>
    </div>
</nav>


<div *ngIf="callDetail" style="width:67%;height: 250px;float: left;"
    [ngStyle]="isDesktop ? {'width' : '67%'}  : {'width' : '100%'}">
    <img class="center-cropped-detail" src="{{'data:image/jpg;base64,' + callDetail.detailImage.imagebyte}}">
    <div style="color:white;margin-top:-220px;margin-left:20px;">
        <span type="button" (click)="goBack()" style="font-size: large;">
            <span class="appkiticon a-btn-icon icon-left-chevron-outline"></span>
            All the Initiatives</span><br><br>
        <div class="text-align">Call for Ideas<br>
            <span style="font-size: xx-large; font-weight: bold;">{{callDetail.titolo}} </span>
        </div>
    </div>
    <!-- <div style="color:white" class="top-left">
        <span type="button" (click)="goBack()" style="font-size: large;"> <span
                class="appkiticon a-btn-icon icon-left-chevron-outline" style="color: #FFFFFF;"></span> All the
            Initiatives</span><br>
        <div class="text-align">Call for idea<br>
            <span style="font-size: xx-large; font-weight: bold;">{{callDetail.titolo}} </span>
        </div>

    </div> -->

    <!-- <div style="color:white">{{callDetail.titolo}}</div> -->
</div>
<div *ngIf="callDetail" class="calendario" [ngStyle]="isDesktop ? {'width' : '33%'}  : {'width' : '100%'}">
    <div class="row">
        <div class="col-7" style="color:#FFFFFF">
            <span [ngStyle]="isDesktop ? {'font-size' : '10px'} : {'font-size' : '20px'}">Due date </span><br
                *ngIf="isDesktop">
            <span [ngStyle]="isDesktop ? {'font-size' : '30px'} : {'font-size' : '25px'}">{{callDetail.dataScadenza |
                date:'d MMMM'}}</span>
        </div>
        <div class="col-5" style="color:#FFFFFF"
            [ngStyle]="isDesktop ? {} : {'text-align' : 'end' , 'margin-top' : '10px'}">
            <span *ngIf="callDetail.status=='OPEN'" class="openState">{{callDetail.status}}</span><br>
            <span *ngIf="callDetail.status=='CLOSE'" class="closeState">{{callDetail.status}}</span><br>
        </div>
    </div>
    <div class="row mt-4">
        <div [ngClass]="{'col-7':isDesktop , 'col-12':!isDesktop}">
            <button *ngIf="!authService.isLogged()" (click)="login();"
                style="width:100%;border-radius: 5px;padding:5px;font-size: 14px;">Apply</button>
            <button *ngIf="authService.isLogged()" (click)="gotoanchor('submission');"
                style="width:100%;border-radius: 5px;padding:5px;font-size: 14px;">Apply</button>
        </div>
    </div>
    <div class="row mt-2">
        <div [ngClass]="{'col-7':isDesktop , 'col-12':!isDesktop}">
            <button (click)="downloadRegulation(callDetail.regulation)"
                style="width:100%;color:white;border:1px solid white;background-color: #20376C;border-radius: 5px;padding:5px;font-size: 14px;"><img
                    src="../../../../assets/images/download.png">&nbsp;&nbsp;Download the rules and regulation</button>
        </div>
    </div>
    <div class="row mt-2">
        <div class="col-7">
            <div (click)="downloadPrivacy(callDetail.privacy)" class="privacy">Privacy
                Policy
            </div>
        </div>
    </div>

</div>

<div *ngIf="callDetail" class="row" style="padding-top:40px;margin-bottom: 40px;"
    [ngStyle]="isDesktop ? {} : { 'padding-left' : '20px' }">
    <div class="col-1" *ngIf="isDesktop">

    </div>
    <div class="col-1" *ngIf="isDesktop">
        <img src="../../../../assets/images/path248.png">
    </div>
    <div class="col-1" *ngIf="isDesktop">

    </div>
    <div [ngClass]="{'col-5' : isDesktop , 'col-12' : !isDesktop}">
        <span style="color:#20376C;font-weight: bold;">Description</span>
        <br><br>
        {{callDetail.breveDescrizione}}
    </div>
    <div [ngClass]="{'col-3' : isDesktop , 'col-12' : !isDesktop}">
        <div class="row mb-3">
            <div [ngClass]="{'col-12' : isDesktop , 'col-12  mt-4' : !isDesktop}">
                <span style="color:#20376C;font-weight: bold;">Call phases</span>
            </div>
        </div>

        <div class="row" *ngFor="let fase of callDetail.fasi; let i = index;">
            <div class="col-1">
                <div [ngClass]="fase.attiva==true ? 'tondoblu':'tondogrigio'">{{i+1}}</div>
            </div>
            <div class="col-6" [ngStyle]="{'font-weight': fase.attiva==true ? 'bold':'normal'}">
                {{fase.nome}}
            </div>
            <div [ngClass]="{'col-5' : isDesktop , 'col-12' : !isDesktop}" *ngIf="fase.toData!=null"
                [ngStyle]="isDesktop ? {} : { 'padding-left' : '45px' }">
                From&nbsp;<span style="font-size: 14px;">{{fase.fromData | date:'dd/MM'}}</span>
                To&nbsp;<span style="font-size: 14px;">{{fase.toData | date:'dd/MM'}}</span>
            </div>
            <div class="col-5" *ngIf="fase.toData==null">
                <span style="font-size: 14px;">{{fase.fromData | date:'dd/MM'}}</span>
            </div>
        </div>
    </div>
    <div class="col-1" *ngIf="isDesktop">

    </div>
</div>


<div id="submission" *ngIf="authService.isLogged() && callDetail" style="width:100%;">
    <div class="row">
        <div class="col-1" style="background-color: #E8E8E8;"></div>
        <div style="background-color: #E8E8E8;padding-bottom: 60px;"
            [ngStyle]="isDesktop ? {} : { 'padding-left' : '30px' }"
            [ngClass]="{'col-7' : isDesktop , 'col-12' : !isDesktop}">
            <div class="row" [ngStyle]="isDesktop ? {'padding-top' : '100px'} : { 'padding-top' : '30px'}">
                <div class="col-12">
                    <span style="font-size: 12px;color:#20376C" class="primary">Submission</span>
                </div>
            </div>
            <div class="row">
                <div class="col-6" style="background-color: #E8E8E8;">
                    <span style="font-size: 25px;color:#20376C;font-weight: 600;"
                        class="primary">{{callDetail.titolo}}</span>
                </div>
            </div>

            <div class="row mb-2" style="margin-top: 30px;">
                <!-- *** TODO: Insert fields here *** -->
                <!-- *** FIELD titolo STARTS *** -->
                <div class="col-lg-12 col-md-12 col-sm-12">
                    <!-- *** TODO: For required fields, please add .required class to the label *** -->
                    <div class="tondogrigio" style="position:absolute">1</div>
                    <label style="margin-left:25px" id="nome_label" for="nome"
                        class="a-form-label text-uppercase required" translate>
                        call.form.nome.label</label>
                    {{submissionDetail.nome}}

                </div>
            </div>
            <hr>
            <div class="row mb-2">
                <div class="col-lg-12 col-md-12 col-sm-12">
                    <!-- *** TODO: For required fields, please add .required class to the label *** -->
                    <div class="tondogrigio" style="position:absolute">2</div>
                    <label style="margin-left:25px" id="soluzione_label" for="soluzione"
                        class="a-form-label text-uppercase required" translate>call.form.solution.label</label>
                    {{submissionDetail.soluzione}}

                </div>
            </div>
            <hr>

            <!-- *** TODO: Ensure that there is an <onSubmit()> function in the controller *** -->
            <div *ngIf="!callDetail.internal" class="row">
                <div class="row mb-2">
                    <!-- *** TODO: Insert fields here *** -->
                    <!-- *** FIELD titolo STARTS *** -->

                    <div class="col-lg-12 col-md-12 col-sm-12">
                        <!-- *** TODO: For required fields, please add .required class to the label *** -->
                        <div class="tondogrigio" style="position:absolute">3</div>
                        <label style="margin-left:25px" id="tecnologia_label" for="tecnologia"
                            class="a-form-label text-uppercase required" translate>call.form.technology.label</label>
                        {{submissionDetail.tecnologia}}

                    </div>
                </div>
            </div>

            <div *ngIf="callDetail.internal" class="row">
                <div class="row mb-2">
                    <!-- *** TODO: Insert fields here *** -->
                    <!-- *** FIELD titolo STARTS *** -->

                    <div class="col-lg-12 col-md-12 col-sm-12">
                        <!-- *** TODO: For required fields, please add .required class to the label *** -->
                        <div class="tondogrigio" style="position:absolute">3</div>
                        <label style="margin-left:25px" id="tecnologia_label" for="desfaAdvantages"
                            class="a-form-label text-uppercase required"
                            translate>call.form.desfaAdvantages.label</label>
                        {{submissionDetail.desfaAdvantages}}

                    </div>
                </div>
            </div>
            <hr>

            <div *ngIf="!callDetail.internal" class="row mb-2">
                <div class="col-lg-12 col-md-12 col-sm-12">
                    <!-- *** TODO: For required fields, please add .required class to the label *** -->
                    <div class="tondogrigio" style="position:absolute">4</div>
                    <label style="margin-left:25px" id="readiness_label" for="readiness"
                        class="a-form-label text-uppercase required" translate>Indicate company's
                        Technology Readiness Level</label>
                    {{submissionDetail.readiness}}
                </div>
            </div>

            <div *ngIf="callDetail.internal" class="row mb-2">
                <div class="col-lg-12 col-md-12 col-sm-12">
                    <!-- *** TODO: For required fields, please add .required class to the label *** -->
                    <div class="tondogrigio" style="position:absolute">4</div>
                    <label style="margin-left:25px" id="readiness_label" for="departmentBelonging"
                        class="a-form-label text-uppercase required"
                        translate>call.form.departmentBelonging.label</label>
                    {{submissionDetail.departmentBelonging}}
                </div>
            </div>
            <hr>

            <div *ngIf="!callDetail.internal" class="row">
                <div class="row mb-2">
                    <div class="col-lg-12 col-md-12 col-sm-12">
                        <div class="tondogrigio" style="position:absolute">5</div>
                        <label style="margin-left:25px" id="readiness_label" for="readiness"
                            class="a-form-label text-uppercase" translate>Number of filled/submitted
                            patents and description</label>
                    </div>
                </div>

                <div class="row mb-2" *ngFor="let item of submissionDetail.brevetti;let i = index">
                    <!-- *** TODO: Insert fields here *** -->
                    <!-- *** FIELD titolo STARTS *** -->
                    <div class="col-lg-4 col-md-4 col-sm-12">
                        <!-- *** TODO: For required fields, please add .required class to the label *** -->

                        <label id="titolo_label" for="titolo" class="a-form-label text-uppercase required"
                            translate>Patent</label>
                        {{item.nome}}

                    </div>

                    <div class="col-lg-6 col-md-6 col-sm-12">
                        <!-- *** TODO: For required fields, please add .required class to the label *** -->
                        <label id="titolo_label" for="titolo" class="a-form-label text-uppercase required"
                            translate>Description</label>
                        {{item.descrizione}}

                    </div>
                </div>
                <hr>
            </div>
            <div class="row">
                <div class="row mb-2" *ngIf="!callDetail.internal">
                    <div class="col-lg-12 col-md-12 col-sm-12">
                        <div class="tondogrigio" style="position:absolute">6</div>
                        <label style="margin-left:25px" id="readiness_label" for="readiness"
                            class="a-form-label text-uppercase required" translate>Curriculum</label>
                    </div>
                </div>
                <div *ngIf="callDetail.internal" class="row mb-2">
                    <div class="col-lg-12 col-md-12 col-sm-12">
                        <div class="tondogrigio" style="position:absolute">5</div>
                        <label style="margin-left:25px" id="readiness_label" for="readiness"
                            class="a-form-label text-uppercase required" translate>Curriculum</label>
                    </div>
                </div>

                <div class="row mb-2" *ngFor="let item of submissionDetail.curriculum;let i = index">
                    <div class="col-lg-12 col-md-12 col-sm-12" style="margin-bottom: 10px;">

                        <span style="background-color: #CFCFCF;padding:10px">
                            <img src="../../../assets/images/icon file.png">
                            {{item.nome}}
                        </span>
                        <span (click)="downloadCV(item.id,item.nome)" style="background-color: #20376C;;padding:10px">
                            <img src="../../../assets/images/download.png">
                        </span>
                    </div>

                </div>
            </div>
            <hr *ngIf="!callDetail.internal">
            <div class="row mb-2" *ngIf="!callDetail.internal">
                <!-- *** TODO: Insert fields here *** -->
                <!-- *** FIELD titolo STARTS *** -->
                <div class="col-lg-12 col-md-12 col-sm-12">
                    <!-- *** TODO: For required fields, please add .required class to the label *** -->
                    <div class="tondogrigio" style="position:absolute">7</div>
                    <label style="margin-left:25px" id="progetti_label" for="progetti"
                        class="a-form-label text-uppercase required" translate>MAIN PROJECTS OR RESULTS OBTAINED
                        (References)</label>
                    {{submissionDetail.progetti}}

                </div>
            </div>
            <hr *ngIf="!callDetail.internal">
            <div class="row mb-2" *ngIf="!callDetail.internal">
                <div class="col-lg-12 col-md-12 col-sm-12">
                    <!-- *** TODO: For required fields, please add .required class to the label *** -->
                    <div class="tondogrigio" style="position:absolute">8</div>
                    <label style="margin-left:25px" id="dettagliFinanziari_label" for="dettagliFinanziari"
                        class="a-form-label text-uppercase" translate>Funding raised (if any)</label>
                    {{submissionDetail.dettagliFinanziari}}

                </div>
            </div>
            <hr *ngIf="!callDetail.internal">
            <div *ngIf="!callDetail.internal" class="row mb-2">
                <div class="col-lg-12 col-md-12 col-sm-12">
                    <div class="tondogrigio" style="position:absolute">9</div>
                    <label style="margin-left:25px" class="a-form-label text-uppercase required" translate>Pitch
                        Deck</label>
                    <span *ngIf="submissionDetail.pitch" style="background-color: #CFCFCF;padding:10px">
                        <img src="../../../assets/images/icon file.png">
                        {{submissionDetail.pitch.nome}}
                    </span>
                    <span (click)="downloadPitch(submissionDetail.pitch.id,submissionDetail.pitch.nome)"
                        *ngIf="submissionDetail.pitch" style="background-color: #20376C;;padding:10px">
                        <img src="../../../assets/images/download.png">
                    </span>
                </div>
            </div>

            <hr *ngIf="!callDetail.internal">
            <div *ngIf="!callDetail.internal" class="row mb-2">
                <div class="col-lg-12 col-md-12 col-sm-12">
                    <div class="tondogrigio" style="position:absolute">10</div>
                    <label style="margin-left:25px" class="a-form-label text-uppercase" translate>Business model
                        upload(If it's not in the pitch deck)</label>

                    <span *ngIf="submissionDetail.business" style="background-color: #CFCFCF;padding:10px">
                        <img src="../../../assets/images/icon file.png">
                        {{submissionDetail.business.nome}}
                    </span>
                    <span (click)="downloadBusiness(submissionDetail.business.id,submissionDetail.business.nome)"
                        *ngIf="submissionDetail.business" style="background-color: #20376C;;padding:10px">
                        <img src="../../../assets/images/download.png">
                    </span>
                </div>
            </div>
        </div>
        <div style="background-color: white;padding-left: 30px;"
            [ngClass]="{'col-3' : isDesktop , 'col-12' : !isDesktop}"
            [ngStyle]="isDesktop ? {} : { 'padding-bottom' : '80px', 'padding-right' : '20px' }">
            <div class="row">
                <div class="col-12" [ngStyle]="isDesktop ? { 'padding-top' : '120px' } : { 'padding-top' : '30px' }">
                    <span style="font-size:25px;color: #20376C;font-weight: 600;">Evaluation</span>
                </div>
            </div>
            <div class="row">
                <div class="col-12" style="padding-top: 20px;">
                    <span style="color: #20376C;font-weight: bold;">Step 1</span>
                </div>
            </div>
            <div class="row">
                <div class="col-12" style="padding-top: 20px;">
                    <div *ngIf="authService.hasRole('ROLE_USER')"
                        style="border:1px solid #dedede;border-radius:5px;padding:5px">
                        {{submissionDetail.evaluationStatus1}}&nbsp;
                    </div>
                    <div *ngIf="authService.hasRole('ROLE_ADMIN')">
                        <ng-select required [(ngModel)]="adminEvaluationStatus1" class="a-dropdown" [searchable]="false"
                            [multiple]="false" [items]="statusList" bindLabel="value" bindValue="value"
                            placeholder="Select...">
                            <!-- *** TODO: insert your options here (please refer to the official ng-select site: https://ng-select.github.io/ng-select#/data-sources)  *** -->
                        </ng-select>
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-12" style="padding-top: 40px;">
                    <span style="color: #20376C;font-weight: bold;">Step 2</span>
                </div>
            </div>
            <div class="row">
                <div class="col-12" style="padding-top: 20px;">
                    <div *ngIf="authService.hasRole('ROLE_USER')"
                        style="border:1px solid #dedede;border-radius:5px;padding:5px">
                        {{submissionDetail.evaluationStatus2}}&nbsp;
                    </div>
                    <div *ngIf="authService.hasRole('ROLE_ADMIN')">
                        <ng-select required [(ngModel)]="adminEvaluationStatus2" class="a-dropdown" [searchable]="false"
                            [multiple]="false" [items]="statusList" bindLabel="value" bindValue="value"
                            placeholder="Select...">
                            <!-- *** TODO: insert your options here (please refer to the official ng-select site: https://ng-select.github.io/ng-select#/data-sources)  *** -->
                        </ng-select>
                    </div>
                </div>
            </div>
            <div class="row" *ngIf="authService.hasRole('ROLE_ADMIN')">
                <div class="col-12" style="padding-top: 20px;text-align: right;">
                    <button class="buttonInnoHub_inverse">Cancel</button>
                    &nbsp;<button (click)="updateSubmission()" class="buttonInnoHub">Save</button>
                </div>
            </div>
        </div>
        <div class="col-1"></div>
    </div>