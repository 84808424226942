// *** class name is ContactService
// *** entity name is Contact
// *** TODO: please check that everything is correct

import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Event, EventType, NVCrudService } from '@nv/core';
import { Contact } from 'src/app/models/contact';
import { EventsService } from '../global/events.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ContactService extends NVCrudService<Contact> {

  constructor(
    private httpClient: HttpClient,
    private eventService: EventsService
  ) {
    super(
      httpClient,
      'api/',
      'contact',
      eventService
    )
  }

  getParentResourceURI(pathVariables: Map<string, string>): string {
    // *** TODO: verify how to compose the URI based on the relativity of the entity (if it has parent or not) ***
    // *** EXAMPLE: return environment.backendURL + super.getBaseURI() + "parentEntityName/" + pathVariables.get("parentEntityID") + "/";
    return environment.backendURL + super.getBaseURI();
  }

  // contactUploadAction(uploadedImage: File): Observable<any> {
  //     const privacyFormData = new FormData();
  //     privacyFormData.append('contact', uploadedImage, uploadedImage.name);

  //     return this.httpClient.post(environment.backendURL + 'api/contactFile/', privacyFormData, { observe: 'response' })
  //         .pipe(map(res => {
  //             return res.body;
  //         }));
  // }

  public download(contactid: string): Observable<any> {
    let params: HttpParams = new HttpParams();
    return this.httpClient.get(environment.backendURL + 'api/contactFile/' + contactid + '/download', { params, responseType: 'blob' });

  }

  public createAndUploadFile(pathVariables: Map<string, string>, resource: Contact, file: File): Observable<string> {
    const formData = new FormData();
    formData.append('newContact', new Blob([JSON.stringify(resource)], { type: 'application/json' }));
    if (file) {
      formData.append('contactFile', file, file?.name);
    }

    return this.httpClient
      .post<string>(environment.backendURL + 'api/contact/', formData, {
        responseType: 'text' as 'json',
      })
      .pipe(map(res => {
        return res;
      }));
  }

}
