import { Company } from "./company";

export class Profile {
    public constructor(init?: Partial<Profile>) { Object.assign(this, init); }
    createdDate: Date = new Date();
    firstName: string = null;
    id: string = null;
    lastModifiedBy: string = null;
    lastModifiedDate: Date = new Date();
    lastName: string = null;
    owner: string = null;
    username: string = null;
    email: string = null;
    roles: string[] = null;
    enabled: boolean = null;
    accountLocked: boolean = null;
    startupName: string = null;
    employeesNumber: number = null;
    headQuarter: string = null;
    vatNumber: string = null;
    foundationDate: string = null;
    webSite: string = null;
    telephone: string = null;
    password:string=null;
    company:Company=null;
}
