import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { mainConfigs } from 'src/app/configs/main-configs';
import { AuthService } from 'src/app/services/auth.service';
import { NVAuthService } from '@nv/auth';
import { ProfileService } from 'src/app/services/crud/profile.service';
import { ResetPasswordConfirm } from 'src/app/models/reset-password-confirm';

@Component({
  selector: 'app-reset-password-confirm',
  templateUrl: './reset-password-confirm.component.html',
  styleUrls: ['./reset-password-confirm.component.scss']
})
export class ResetPasswordConfirmComponent implements OnInit {
  firstChangePassword: boolean = false;
  resetPasswordConfirmForm: FormGroup;
  isHandsetLandscape: boolean;
  isHandset: boolean;
  private resetPasswordConfirm: ResetPasswordConfirm = new ResetPasswordConfirm();

  submitted = false;

  constructor(
    private formBuilder: FormBuilder,
    private authService: NVAuthService,
    private profileService: ProfileService,
    private router: Router,
    private route: ActivatedRoute,
    public breakpointObserver: BreakpointObserver
  ) {
    breakpointObserver.observe([
      Breakpoints.HandsetLandscape,
    ]).subscribe(result => {
      this.isHandsetLandscape = result.matches;
    });
    breakpointObserver.observe([
      Breakpoints.Handset,
    ]).subscribe(result => {
      this.isHandset = result.matches;
    });
  }

  ngOnInit() {
    this.resetPasswordConfirmForm = this.formBuilder.group({
      newPassword: ['', Validators.required],
      newPasswordConfirm: ['', Validators.required],
    });

    if (this.authService.getToken()) {
      this.authService.loadContext().subscribe(() => {
        this.router.navigateByUrl(mainConfigs.loginSuccessURL);
        console.log('redirect to:', mainConfigs.loginSuccessURL);
      });
    }

    this.route.paramMap.subscribe(params => {
        this.resetPasswordConfirm.passwordResetRequestID = params.get("resetCode");
        this.resetPasswordConfirm.username = params.get("username");
    })

    this.route.data.subscribe(data => {
      this.firstChangePassword = data.firstChangePassword;
    });
  }


  onSubmit() {
    this.submitted = true;
    if(this.f.newPassword.value !== this.f.newPasswordConfirm.value){
      this.f.newPasswordConfirm.setErrors({
        'passwordmismatch':true
      });
      return;
    }
    if (this.resetPasswordConfirmForm.invalid) {
      return;
    }
    this.resetPasswordConfirm.newPassword = this.f.newPassword.value;
    if (this.firstChangePassword) {
      this.authService
      .firstChangePasswordPerform(this.resetPasswordConfirm.username, this.resetPasswordConfirm.passwordResetRequestID,this.resetPasswordConfirm.newPassword)
      .subscribe((response) => {
        this.router.navigateByUrl(mainConfigs.loginSuccessURL);
      });
    } else {
      this.authService
        .resetPasswordPerform(this.resetPasswordConfirm.username, this.resetPasswordConfirm.passwordResetRequestID,this.resetPasswordConfirm.newPassword)
        .subscribe((response) => {
          this.router.navigateByUrl(mainConfigs.loginSuccessURL);
        });
    }

  }
  // convenience getter for easy access to form fields
  get f() { return this.resetPasswordConfirmForm.controls; }
}
