<nav class="navbar navbar-expand-lg navbar-light mb-1 px-3">
  <div *ngIf="authService.isLogged() && authService.hasRole('ROLE_ADMIN')" style="cursor: pointer;"
    [routerLink]="['../admin/home']" class="desfa-logo-grid md"></div>
  <div *ngIf="authService.isLogged() && authService.hasRole('ROLE_USER') && !authService.hasRole('ROLE_ADMIN')"
    style="cursor: pointer;" [routerLink]="['../home']" class="desfa-logo-grid md"></div>
  <!-- <button class="navbar-toggler hidden-sm-up" type="button" (click)="isNavbarCollapsed = !isNavbarCollapsed"
        data-target="#navbarsDefault" aria-controls="navbarsDefault" aria-expanded="false"
        aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
    </button> -->
  <button class="navbar-toggler" type="button" (click)="isNavbarCollapsed = !isNavbarCollapsed" data-toggle="collapse"
    data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
    aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>
  <div [ngbCollapse]="isNavbarCollapsed" class="collapse navbar-collapse" id="navbarsDefault">

    <span class="ms-auto">
    </span>
    <ul class="navbar-nav mr-auto">
      <!-- admin navbar -->
      <li class="nav-item active">
        <a [routerLink]="['submission/']" *ngIf="authService.isLogged() && authService.hasRole('ROLE_ADMIN')"
          [ngClass]="isHandset? 'menu-option-mobile' : 'menu-option right-border'">
          Submissions
        </a>
      </li>
      <li class="nav-item active">
        <a [routerLink]="['profile/']" *ngIf="authService.isLogged() && authService.hasRole('ROLE_ADMIN')"
        [ngClass]="isHandset? 'menu-option-mobile' : 'menu-option right-border'">
          Registry
        </a>
      </li>
      <li class="nav-item active">
        <a [routerLink]="['call/']" *ngIf="authService.isLogged() && authService.hasRole('ROLE_ADMIN')"
        [ngClass]="isHandset? 'menu-option-mobile' : 'menu-option right-border'">
          Initiatives
        </a>
      </li>
      <li class="nav-item active">
        <a [routerLink]="['contacts/']" *ngIf="authService.isLogged() && authService.hasRole('ROLE_ADMIN')"
        [ngClass]="isHandset? 'menu-option-mobile' : 'menu-option right-border'">
          Contacts
        </a>
      </li>

      <!-- user navbar -->
      <li class="nav-item active">
        <a [routerLink]="['/secure/mysubmission/list/']"
          *ngIf="authService.isLogged() &&  !authService.hasRole('ROLE_ADMIN')"
          [ngClass]="isHandset? 'menu-option-mobile' : 'menu-option right-border'">
          <span *ngIf="isHandset" class="appkiticon a-btn-icon icon-survey-outline"></span> My Submissions</a>
      </li>
      <li class="nav-item active">
        <a (click)="logout();"
        [ngClass]="isHandset? 'menu-option-mobile' : 'menu-option'">
        <span *ngIf="isHandset" class="appkiticon a-btn-icon icon-log-out-outline"></span> Log out</a>
      </li>
      <!--  <ul class="navbar-nav">
            <li class="nav-item dropdown" ngbDropdown>
                <a class="nav-link dropdown-toggle pointer" ngbDropdownToggle [translate]="currentLanguage()"></a>
                <div class="dropdown-menu dropdown-menu-end" ngbDropdownMenu>
                    <a *ngFor="let locale of availableLanguages()" class="dropdown-item pointer" [translate]="locale"
                        (click)="changeLanguage(locale)"></a>
                </div>
            </li>
        </ul>-->

      <!-- user navbar -->
      <li class="nav-item active">
        <a [routerLink]="['/secure/contact']" *ngIf="authService.isLogged() && authService.hasRole('ROLE_USER') && !authService.hasRole('ROLE_ADMIN')"
        [ngClass]="isHandset? 'menu-option-mobile' : 'btn_contact'">
        <span *ngIf="isHandset" class="appkiticon a-btn-icon icon-help-question-outline"></span> Contact Us</a>
      </li>

      <li *ngIf="!isHandset" class="nav-item active">
        <ul class="navbar-nav" *ngIf="authService.isLogged()">
          <li class="nav-item" ngbDropdown>
            <a class="nav-link"
              style="cursor:pointer; font-size: 14px;
                padding-left: 6px;
                padding-top: 3px;margin-left:5px;width:30px;height:30px;border-radius: 15px;background-color: #2BABE2;color:white"
              ngbDropdownToggle>
              {{authService.getAuthContext().user.username.substring(0,2).toUpperCase()}}
            </a>
            <div class="dropdown-menu dropdown-menu-right text-right" ngbDropdownMenu style="left:-125px">
              <a class="dropdown-item" [routerLink]="['../profile/my-profile']">
                <span class="appkiticon a-btn-icon icon-person-outline"></span> Profile</a>
            </div>
          </li>
        </ul>
      </li>
      <li *ngIf="isHandset">
        <a [routerLink]="['../profile/my-profile']"
        *ngIf="authService.isLogged()"
        class="menu-option-mobile">
        <span class="appkiticon a-btn-icon icon-person-outline"></span> Profile</a></li>
    </ul>
  </div>
</nav>
