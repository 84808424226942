<nav class="navbar navbar-expand-lg navbar-light mb-1 px-3">
  <div style="cursor: pointer;" [routerLink]="['../home']" class="desfa-logo-grid md"></div>
  <span class="divider mx-3"></span>
  <span class="divider ms-3"></span>
  <button class="navbar-toggler hidden-sm-up" type="button" (click)="isNavbarCollapsed = !isNavbarCollapsed"
    data-target="#navbarsDefault" aria-controls="navbarsDefault" aria-expanded="false" aria-label="Toggle navigation">
    <span class="navbar-toggler-icon"></span>
  </button>
  <div [ngbCollapse]="isNavbarCollapsed" class="collapse navbar-collapse" id="navbarsDefault">
    <span class="ms-auto">
    </span>

    <ul class="navbar-nav" *ngIf="!authService.isLogged()">

      <a [ngClass]="isHandset? 'menu-option-mobile' : 'login-option pointer'" (click)="login()">
        <span *ngIf="isHandset" class="appkiticon a-btn-icon icon-login-outline"></span> Log in</a>
      <a (click)="register()" [ngClass]="isHandset? 'menu-option-mobile' : 'btn_contact'">
        <span *ngIf="isHandset" class="appkiticon a-btn-icon icon-login-outline"></span> Sign up</a>
    </ul>
    <ul class="navbar-nav" *ngIf="authService.isLogged()" style="font-size:14px">
      <a (click)="mysubmission()" [ngClass]="isHandset? 'menu-option-mobile' : 'menu-option right-border'">
        <span *ngIf="isHandset" class="appkiticon a-btn-icon icon-survey-outline"></span> My Submissions</a>
      <a (click)="logout()" [ngClass]="isHandset? 'menu-option-mobile' : 'menu-option'">
        <span *ngIf="isHandset" class="appkiticon a-btn-icon icon-log-out-outline"></span> Log out</a>
      <a [routerLink]="['../profile/my-profile']" *ngIf="isHandset" class="menu-option-mobile">
        <span class="appkiticon a-btn-icon icon-person-outline"></span> Profile</a>
    </ul>

    <ul class="navbar-nav" *ngIf="!isHandset && authService.isLogged()">
      <li class="nav-item" ngbDropdown>
        <a class="nav-link"
          style="cursor:pointer; font-size: 14px;
                padding-left: 6px;
                padding-top: 3px;margin-left:5px;width:30px;height:30px;border-radius: 15px;background-color: #2BABE2;color:white"
          ngbDropdownToggle>
          {{authService.getAuthContext().user.username.substring(0,2).toUpperCase()}}
        </a>
        <div class="dropdown-menu dropdown-menu-right text-right" ngbDropdownMenu style="left:-125px">
          <a class="dropdown-item" [routerLink]="['../profile/my-profile']">
            <span class="appkiticon a-btn-icon icon-person-outline"></span> Profile</a>
        </div>
      </li>
    </ul>

  </div>
</nav>

<div class="row">
  <div *ngIf="isHandset" class="col-1"></div>
  <div [ngClass]="isHandset? 'col-10' : 'col-12'">
    <div class="row" style="margin-top:50px">
      <div class="col-1"></div>
      <div class="col-10">
        <div class="goback" onclick="history.back()" translate><img
            src="../../../../../assets/images/left-chevron.png">&nbsp;go back</div>
        <form [formGroup]="resourceForm" (ngSubmit)="onSubmit()">
          <div class="row">
            <div [ngClass]="isHandset ? 'col-12 mb-4' : 'col-3'">
              <div [ngClass]="isHandset? 'row' : 'row margin'">
                <div class="col-12" style="font-size: 40px;">
                  Contact Us
                </div>
              </div>
              <div class="row">
                <div class="col-12">
                  If you haven't found the right challenge for you, request information or propose your idea by filling
                  in the form. <br>
                  Our team of experts will get back to you as soon as possible.
                </div>
              </div>
            </div>
            <div *ngIf="!isHandset" class="col-1"></div>
            <div [ngClass]="isHandset? 'col-12 mb-3' : 'col-8 mb-3'">

              <div class="row">
                <div class="col-12" style="border:1px solid #203570 ;border-radius: 5px;padding:30px">
                  <div class="row mt-2">
                    <div class="col-12" style="font-size: 16px;font-weight: 500;">
                      Enter your details
                    </div>
                  </div>
                  <div class="row mt-1">
                    <div [ngClass]="isHandset? 'col-12' : 'col-6'">
                      <div class="form-floating mb-2">
                        <input type="text" formControlName="fullname" [(ngModel)]="fullname" class="form-control"
                          id="floatingInput" placeholder="name@example.com">
                        <label for="floatingInput">Name and surname</label>
                      </div>
                    </div>
                    <div [ngClass]="isHandset? 'col-12' : 'col-6'">
                      <div class="form-floating">
                        <input type="text" formControlName="email" [(ngModel)]="email" class="form-control"
                          id="floatingInput" placeholder="name@example.com">
                        <label for="floatingInput">Email</label>
                      </div>
                    </div>
                  </div>
                  <div class="row mt-2">
                    <div [ngClass]="isHandset? 'col-12' : 'col-6'">
                      <div class="form-floating mb-2">
                        <input type="text" formControlName="environment" [(ngModel)]="environment" class="form-control"
                          id="floatingInput" placeholder="name@example.com">
                        <label for="floatingInput">Company's name</label>
                      </div>
                    </div>
                    <div [ngClass]="isHandset? 'col-12' : 'col-6'">
                      <div class="form-floating mb-2">
                        <input type="text" formControlName="role" [(ngModel)]="role" class="form-control"
                          id="floatingInput" placeholder="name@example.com">
                        <label for="floatingInput">Role</label>
                      </div>
                    </div>
                  </div>
                  <div class="row mt-3">
                    <div class="col-12" style="font-size: 16px;font-weight: 500;">
                      Write you message
                      <p>
                        * Please provide your input in English (max 255 characters)
                      </p>
                    </div>
                  </div>
                  <div class="row mt-1">
                    <div class="col-12">
                      <div class="form-floating mb-2">
                        <input type="email" formControlName="message" class="form-control" [(ngModel)]="message"
                          id="floatingInput" placeholder="name@example.com">
                        <label for="floatingInput">Write your message</label>
                      </div>
                    </div>
                  </div>
                  <div class="row mt-3">
                    <div class="col-12" style="font-size: 16px;font-weight: 500;">
                      Attach a file
                    </div>
                  </div>
                  <div class="row mt-1 mb-3">

                    <div class="col-12">
                      <div style="border:1px dashed #203570;padding:10px">
                        <input required type="file" id="good" (change)="onContactpload($event)" class="hidden">
                        <label for="good"
                          style="cursor:pointer;border:1px solid #203570;padding-top:5px;padding-bottom:5px;padding-right: 10px;padding-left:10px;font-size: 14px;border-radius: 5px;"><img
                            src="../../../../../../assets/images/upload.png">&nbsp;Add file</label>
                        <span *ngIf="contactFile">&nbsp;{{contactFile.name}}</span>
                      </div>
                    </div>

                  </div>
                  <div class="row mb-2 mt-5">
                    <div class="col-12">
                      I have read the <a href="../../privacy" target="_blank">privacy policy</a>
                    </div>
                  </div>
                  <div class="row" style="margin-left:-10px;margin-top:10px">
                    <div class="col-md-12">
                      <input type="radio" [(ngModel)]="disclaimerPrivacy" name="disclaimerPrivacy" value="si"
                        [ngModelOptions]="{standalone: true}"> <b> YES</b>
                      <input type="radio" [(ngModel)]="disclaimerPrivacy" name="disclaimerPrivacy" value="no"
                        style="margin-left: 20px;" [ngModelOptions]="{standalone: true}"><b>
                        NO</b>
                    </div>
                  </div>
                  <div class="row mb-2 mt-2">

                    <div class="col-12" style="text-align: right;">
                      <button style="margin-left:10px" class="buttonInnoHub" type="submit"
                        [disabled]="!resourceForm.valid || !resourceForm.dirty || disclaimerPrivacy!='si' ">Contact
                        Us</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div class="col-1"></div>
    </div>
  </div>
  <div *ngIf="isHandset" class="col-1"></div>
</div>
