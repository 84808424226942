
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { mainConfigs } from 'src/app/configs/main-configs';
import { AuthService } from 'src/app/services/auth.service';
import { NVAuthService } from '@nv/auth';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { InternationalizationService } from '@nv/internationalization';
import { NVListController, NVPreferencesService } from '@nv/core';
import { CallService } from 'src/app/services/crud/call.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ModalComponent } from 'src/app/components/modal/modal.component';
import { HomeComponent } from 'src/app/components/homepage/home.component';


@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class SecureHomeComponent  {
 
  
}
