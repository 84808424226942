// *** class name is PrivacyService
// *** entity name is Privacy
// *** TODO: please check that everything is correct

import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { NVCrudService } from '@nv/core';
import { EventsService } from '../global/events.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
        providedIn: 'root'
})
export class PrivacyService{
    
    constructor(
        private httpClient: HttpClient,
        private eventService: EventsService
    ) {
       
    }
    
    privacyUploadAction(uploadedImage: File): Observable<any> {
        const privacyFormData = new FormData();
        privacyFormData.append('privacy', uploadedImage, uploadedImage.name);


        return this.httpClient.post(environment.backendURL + 'api/privacy/', privacyFormData, { observe: 'response' })
            .pipe(map(res => {
                return res.body;
            }));
    }

    updatePrivacyForCall(uploadedImage: File,callid:string): Observable<any> {
        const imageFormData = new FormData();
        imageFormData.append('privacy', uploadedImage, uploadedImage.name);

        return this.httpClient.post(environment.backendURL + 'api/privacy/call/'+callid, imageFormData, { observe: 'response' })
            .pipe(map(res => {
                return res.body;
            }));
    }

    updatePrivacyForEvent(uploadedImage: File,eventid:string): Observable<any> {
        const imageFormData = new FormData();
        imageFormData.append('privacy', uploadedImage, uploadedImage.name);

        return this.httpClient.post(environment.backendURL + 'api/privacy/event/'+eventid, imageFormData, { observe: 'response' })
            .pipe(map(res => {
                return res.body;
            }));
    }

    public download(privacyid: string): Observable<any> {
        let params: HttpParams = new HttpParams();  
            return this.httpClient.get(environment.backendURL + 'api/privacy/'+privacyid+'/download', { params, responseType: 'blob' });
     
    }
    
}