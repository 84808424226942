import { Component, OnInit } from '@angular/core';
import { ActionLinkType } from 'src/app/enums/action-link-type.enum';
import { ActionHeader } from 'src/app/interfaces/action';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'src/app/services/auth.service';
import { Company } from 'src/app/models/company';
import { NVFormController, NVNotificationService } from '@nv/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CompanyService } from 'src/app/services/crud/company.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DatePipe } from '@angular/common';
import { ProfileService } from 'src/app/services/crud/profile.service';
import { Profile } from 'src/app/models/profile';
import { mainConfigs } from 'src/app/configs/main-configs';
import { GoodStatusService } from 'src/app/services/crud/goodStatus.service';

@Component({
  selector: 'app-my-profile',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactPublicComponent implements OnInit {




  constructor(


  ) {

  }

  ngOnInit() {

  }
}