import { Injectable, OnDestroy } from '@angular/core';
import { Router, RoutesRecognized } from '@angular/router';
import { untilDestroyed } from '@orchestrator/ngx-until-destroyed';
import 'rxjs/add/operator/filter';
import 'rxjs/add/operator/pairwise';

@Injectable({
  providedIn: 'root',
})
export class RoutingStateService implements OnDestroy {
  private history: string[] = [];

  constructor(private router: Router) { }

  public loadRouting(): void {
    this.router.events
      .filter(e => e instanceof RoutesRecognized)
      .pairwise()
      .pipe(untilDestroyed(this))
      .subscribe((event: any[]) => {
        this.history.push(event[0].urlAfterRedirects);
      });
  }

  public getHistory(): string[] {
    return this.history;
  }

  public getPreviousUrl(): string {
    return this.history.pop() || '/main';
  }

  public goBack() {
    this.router.navigate([this.history.pop()]);
  }

  ngOnDestroy() { }
}
